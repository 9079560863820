import { Component,Input, ViewChild, ElementRef } from '@angular/core';
// import * as BpmnJS from 'bpmn-js/dist/bpmn-viewer.production.min.js';
import * as BpmnJS from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';


@Component({
  selector: 'dot-bpmn-viewer',
  templateUrl: './dot-bpmn-viewer.component.html',
  styleUrls: ['./dot-bpmn-viewer.component.scss']
})
export class DotBpmnViewerComponent {

  // BPMN CONTAINER
  @ViewChild('ref', { static: true }) private el: ElementRef;

  // INPUTS
  @Input() bpmnFile: any // BPMN File [XML]
  @Input() failNodes: [] // BPMN FAIL NODES IDs
  @Input() successNodes: [] // BPMN SUCCESS NODES IDs

  // BPMN INSTANCE
  private bpmnJS: BpmnJS = new BpmnJS();


  displayBPMNfile(){

    /**
     * DISPLAY BPMN FILE AND SET THE COLOR TO LAYERS
     */

    // IMPORT LOADED BPMN [XML] FILE
    this.bpmnJS.importXML(this.bpmnFile);

    // CHECK IF THE IMPORT IS DONE WITH NO ERRORS
    this.bpmnJS.on('import.done', ({ error }) => {
      if (!error) {

        // CANVAS VIEW
        const canvas = this.bpmnJS.get('canvas')

        // FIT VIEW
        canvas.zoom('fit-viewport');

        // COLORING SUCCESS NODES
        if(this.successNodes){
          for (let success of this.successNodes){
            canvas.addMarker(success, 'node_success');
          }
        }

        // COLORING FAIL NODES
        if(this.failNodes){
          for (let fail of this.failNodes){
            canvas.addMarker(fail, 'node_fail');
          }
        }

      }
    });
  }


  ngOnInit() {
    // Display the BPMN FILE
    this.displayBPMNfile()
  }


  ngAfterContentInit(): void {
    // ADD BPMN FILE TO CONTAINER
    this.bpmnJS.attachTo(this.el.nativeElement);
  }


  ngOnDestroy(): void {
    // DESTROY BPMN INSTANCE
    this.bpmnJS.destroy();
  }

}
