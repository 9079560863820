
<div
    *ngIf="menuItem"
    (click)="onLinkClicked()"
    class="overlay h-full w-full"
  >

  <!-- START OF SIDE BAR -->
  <div (click)="onScroll()" class="side_bar__item_menu active" id="side_bar__menu">

    <!-- START OF SIDE BAR HEADER -->
    <div class="side_bar__item_menu__header text-[#004AAD]">

      <!-- START OF HEADER ICON AND TITLE -->
      <div class="side_bar__item_menu__header__title">

        <!-- ICON -->
        <span class="header-icon"  [setSVGAttributes]="{'width': '50','height': '50', fill:menuItem?.label === 'billing'?'rgb(0 74 173 / var(--tw-text-opacity))':'none'}" [inlineSVG]="'./assets/icons/menu/'+menuItem?.icon" >
        </span>

        <!-- MENU TITLE -->
        <span>{{'menu.'+ menuItem?.label | translate }}</span>

      </div>
      <!-- END OF HEADER ICON AND TITLE -->

      <!-- START OF HEADER SEARCH CONTROL -->
      <div class="side_bar__item_menu__header__search_form">

        <div class="search_form">
          <div class="search_form__search_bar">
            <div class="search_bar__container">
              <span
                [class]="
                  menuItemsFilter?.length
                    ? 'search_bar__container__icon'
                    : 'search_bar__container__icon disabled'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-search"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </span>
              <input
                [disabled]="!menuItemsFilter?.length"
                [class]="
                  menuItemsFilter?.length
                    ? 'search_bar__container__input'
                    : 'search_bar__container__input disabled'
                "
                [formControl]="menuItemsFilterControl"
                placeholder="Search..."
                #input
              />
            </div>
          </div>
        </div>

      </div>
      <!-- END OF HEADER SEARCH CONTROL -->

    </div>
    <!-- END OF SIDE BAR HEADER -->

    <!-- START OF OF SIDE BAR MENU -->
    <div class="side_bar__item_menu__menu_container">

      <ng-scrollbar
        #scrollable
        track="all"
        pointerEventsMethod="scrollbar"
        [autoHeightDisabled]="false"
      >

        <!-- START OF MENU CONTENT -->
        <ul  class="side_bar__item_menu__list">


          <!-- START OF MENU ELEMENT -->
          <ng-container *ngFor="let item of menuItemsFilter">


            <!-- START OF MENU LEAF LINK  -->
            <ng-container *ngIf="!item.subItems">
              <li class="side_bar__item_menu__list_item">
                <a
                  [routerLink]="[item.url]"
                  routerLinkActive="text-[#374151] prevent-click"
                  [routerLinkActiveOptions]="{ exact: false }"
                  class="h-16 w-full "
                  (click)="onLinkClicked()"
                >
                  <div
                    class="side_bar__item_menu__list_item_header"
                  >
                    <div class="side_bar__item_menu__list_item_header__data">
                      <span class="side_bar__item_menu__list_item_header__data__icon">
                        <mat-icon>{{item.icon}}</mat-icon>
                      </span>
                      <span class="side_bar__item_menu__list_item_header__data__title">
                        {{item.label | translate}}
                      </span>
                    </div>
                  </div>
                </a>
              </li>
            </ng-container>
            <!-- END OF MENU LEAF LINK  -->

            <!-- START OF MENU SUB MENU  -->
            <ng-container *ngIf="item.subItems">

              <li class="side_bar__item_menu__list_item">

                <div (click)="toggleMenuItem($event)" class="side_bar__item_menu__list_item_header">
                  <div class="side_bar__item_menu__list_item_header__data">
                    <span class="side_bar__item_menu__list_item_header__data__icon">
                      <mat-icon>{{item.icon}}</mat-icon>
                    </span>
                    <span class="side_bar__item_menu__list_item_header__data__title">
                      {{item.label | translate}}
                    </span>
                  </div>
                  <div class="side_bar__item_menu__list_item_header__controals">
                    <span class="side_bar__item_menu__list_item_header__controals__icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                    </span>
                  </div>
                </div>

                <!-- START OF SUB MENU -->
                <ul class="side_bar__item_menu__list_item__list">

                  <li *ngFor="let subItem of item.subItems" class="side_bar__item_menu__list_item">

                    <a
                      [routerLink]="[subItem.url]"
                      routerLinkActive="text-[#374151] prevent-click"
                      [routerLinkActiveOptions]="{ exact: false }"
                      class="h-16 w-full "
                      (click)="onLinkClicked()"
                    >
                      <div
                        class="side_bar__item_menu__list_item_header"
                      >
                        <div class="side_bar__item_menu__list_item_header__data">
                          <span class="side_bar__item_menu__list_item_header__data__icon">
                            <mat-icon>{{subItem.icon}}</mat-icon>
                          </span>
                          <span class="side_bar__item_menu__list_item_header__data__title">
                            {{subItem.label | translate}}
                          </span>
                        </div>
                      </div>
                    </a>

                  </li>

                </ul>
                <!-- END OF SUB MENU -->
              </li>

            </ng-container>
            <!-- START OF MENU SUB MENU  -->


          </ng-container>
          <!-- END OF OF MENU ELEMENT -->

        </ul>
        <!-- END OF MENU CONTENT -->

      </ng-scrollbar>
    </div>
    <!-- END OF OF SIDE BAR MENU -->
  </div>
  <!-- END OF SIDE BAR -->
</div>

