import { Component, Input, OnDestroy, inject } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@core/services/language.service';
import { ToasterService } from '@core/services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { REGEX_FORMATS } from '@shared/utilities/defines/regex-formats';
import * as XLSX from 'xlsx';
import moment from 'moment';
import {
  GLOBAL_TABLE_TIME_FORMATE,
  REQUEST_DATE_FORMAT,
  UPDATE_FORM_DATE_FORMATE,
  UPDATE_FORM_DATE_FORMATE_ORIGINAL,
} from '@shared/utilities';
import { DatePipe } from '@angular/common';
import { CustomValidatorsService } from '@core/services/custom-validators/custom-validators.service';
import { HttpService } from '@core/services/http/http.service';
import { SuccessMessage } from '@shared/interfaces/success-message-interface';
import { cryptoKey } from '@shared/utilities/security';
import { AES, enc } from 'crypto-js';

@Component({
  template: '',
})
export abstract class AppBaseComponent implements OnDestroy {
  isLoading: boolean = false;
  isLoadingTable: boolean = false;
  dateValidation: boolean = false;
  comparisonValue: boolean = false;

  REGEX_FORMATS = REGEX_FORMATS;

  private unsubscribeAll: Subject<boolean>;

  languageService = inject(LanguageService);
  notificationService = inject(ToasterService);
  dialogService = inject(MatDialog);
  translateService = inject(TranslateService);
  customValidator = inject(CustomValidatorsService);
  datePipe = inject(DatePipe);
  httpService = inject(HttpService);

  //for forms
  isSubmit: boolean = false;
  form: FormGroup;
  formBuilder = inject(FormBuilder);

  // for lookups data
  lookupsData: any = {};
  @Input() lookupsDataInput: any = {};

  constructor() {}

  // load form controls
  loadForm(controls): void {
    this.form = this.formBuilder.group(controls);
  }

  /**
   * Encrypt the object
   * @param data
   * @returns
   */
  encryptObject(data, key?) {
    const jsonString = JSON.stringify(data);
    return AES.encrypt(jsonString, key? key : cryptoKey).toString();
  }

  /**
   * Decrypt the object
   * @param data
   * @returns
   */
  decryptObject(data, key?) {
    const decryptedBytes = AES.decrypt(data, key? key : cryptoKey);
    const decryptedText = decryptedBytes.toString(enc.Utf8);
    return JSON.parse(decryptedText);
  }

  // #######################################

  /**
   * Transforms a date string using the Moment.js library to a specified date format.
   *
   * @param {string} date - The date string to be transformed.
   * @returns {string | null} The transformed date string in the specified format, or null if the input is falsy.
   */
  transformDateWithUsingMoment(date: string): string | null {
    return date ? moment(date).format(REQUEST_DATE_FORMAT) : null;
  }
  // #######################################

  /**
   * Returns the current date and time as an ISO 8601 formatted string
   * with milliseconds removed using the Moment.js library.
   *
   * @returns {string} The current date and time in ISO 8601 format (milliseconds removed).
   */
  getCurrentDateTime(): string {
    return moment().toDate().toISOString().split('.')[0];
  }
  // #######################################

  /**
   * Calculates a future date and time by adding 120 seconds to the current moment
   * and returns it in ISO 8601 format with milliseconds removed.
   *
   * @returns {string} The future date and time in ISO 8601 format (milliseconds removed).
   */

  getFutureDateTimeInISO(): string {
    return moment().add(30, 'second').toDate().toISOString().split('.')[0];
  }

  getFutureDateTimeInISOFuture(time): string {
    return moment().add(time, 'second').toDate().toISOString().split('.')[0];
  }
  // #######################################

  /**
   * Calculates a future date and time by adding 120 seconds to the current moment
   * and returns it in ISO 8601 format with milliseconds removed.
   *
   * @returns {string} The future date and time in ISO 8601 format (milliseconds removed).
   */

  getFutureDaysDateTimeInISO(day: number): string {
    return moment().add(day, 'day').toDate().toISOString().split('.')[0];
  }

  /**
   * Calculates a future date and time by adding 120 seconds to the current moment
   * and returns it in ISO 8601 format with milliseconds removed.
   *
   * @returns {Date} The future date and time in ISO 8601 format (milliseconds removed).
   */

  getFutureDateTime(day: number): Date {
    return moment().add(day, 'day').toDate();
  }

  // #######################################
  /**
   * Transforms a date string into a formatted date and time string using the Angular DatePipe.
   *
   * @param {string} element - The date string to be transformed.
   * @returns {string} The transformed date and time string.
   */
  transformTableDate(element: string): string {
    return this.datePipe.transform(element, GLOBAL_TABLE_TIME_FORMATE);
  }

  // #######################################

  /**
   * Handles the date change event by performing date validation and
   *  displaying an error message if needed.
   *
   * @param {string} expiryDateValue - The expiry date value.
   * @param {string} firstDateValue - The first date value.
   * @param {string} [errorMsg='Expiry date must be greater than effective date'] -
   *  The error message to display if date validation fails.
   */

  onDateChange(
    expiryDateValue,
    firstDateValue,
    errorMsg: string = 'billing.applyFixedTax.dateValidation',
  ) {
    this.dateValidation = this.customValidator.dateRangeValidator(
      this.transformDateWithUsingMoment(firstDateValue),
      this.transformDateWithUsingMoment(expiryDateValue),
    );
    if (this.dateValidation) {
      this.notificationService.displayErrorToastr(
        this.languageService.getTransValue(errorMsg),
      );
    }
  }

  twoValuesComper(theBiggerValue: number, theSmallervalue: number) {
    if (theSmallervalue === null) return;
    this.comparisonValue = this.customValidator.comparBetweenTwoValues(
      +theBiggerValue,
      +theSmallervalue,
    );
    if (this.comparisonValue) {
      this.notificationService.displayErrorToastr(
        this.languageService.getTransValue(
          'billing.taxInformation.toAmountError',
        ),
      );
    }

    if (+theBiggerValue < 0 || +theSmallervalue < 0) {
      this.notificationService.displayErrorToastr(
        this.languageService.getTransValue(
          'billing.taxInformation.negativeValueError',
        ),
      );
    }
  }

  transformDateInUpdateForm(date: string) {
    return date
      ? moment(date, UPDATE_FORM_DATE_FORMATE_ORIGINAL).format(
          UPDATE_FORM_DATE_FORMATE,
        )
      : null;
  }

  // get form control
  getFormControl(
    controlName: string,
    form: FormGroup = this.form,
  ): AbstractControl {
    return form.get(controlName);
  }

  // remove form control
  removeFormControl(controlName: string, form: FormGroup = this.form) {
    form.removeControl(controlName);
  }

  // get form group
  getFormGroup(groupName: string, form: FormGroup = this.form): FormGroup {
    return form.get(groupName) as FormGroup;
  }

  //on submit form
  onSubmit(): void {}

  /**
   * Creates or updates an element by sending an HTTP request to the specified endpoint.
   *
   * @template T - The type of data being created or updated.
   * @param {{ isUpdateForm: boolean; id: number; body: {} }} elementInfo -
   *  Information about the element, including whether it's an update or
   * creation, its ID, and request body.
   * @param {string} endPoint - The API endpoint for creating or updating
   *  the element.
   * @param {MatDialogRef<any>} dialogRef - The reference to the MatDialog
   * dialog for closing after success.
   *  * @param {string} [errorMSg='messages.failed'] - The error message to
   * display on failure.
   */
  createAndUpdate<T>(
    elementInfo: { isUpdateForm: boolean; id: number; body: {} },
    endPoint: string,
    dialogRef: MatDialogRef<any>,
    errorMSg: string = 'messages.failed',
  ) {
    this.isSubmit = true;
    if (elementInfo.isUpdateForm) {
      this.httpService
        .putData(endPoint, elementInfo.id, elementInfo.body)
        .pipe(finalize(() => (this.isSubmit = false)))
        .pipe(this.takeUntilDestroy())
        .subscribe({
          next: (data: SuccessMessage) => {
            dialogRef.close(data);
            this.notificationService.displaySuccessMessage(
              this.languageService.getTransValue('messages.updateSuccessfully'),
            );
          },
          error: () => {
            // this.notificationService.displayErrorToastr(
            //   this.languageService.getTransValue(`${errorMSg}`),
            // );
          },
        });
    } else {
      this.httpService
        .postData<T>(endPoint, elementInfo.body)
        .pipe(finalize(() => (this.isSubmit = false)))
        .pipe(this.takeUntilDestroy())
        .subscribe({
          next: (data: T) => {
            dialogRef.close(data);
            this.notificationService.displaySuccessMessage(
              this.languageService.getTransValue(
                'messages.createdSuccessfully',
              ),
            );
          },
          error: () => {
            // this.notificationService.displayErrorToastr(
            //   this.languageService.getTransValue(errorMSg),
            // );
          },
        });
    }
  }
  // ******************************************

  deleteTableElement<T>(
    endPoint: string,
    id: number,
    dialogRef: MatDialogRef<any>,
    errorMSg: string = 'messages.failed',
  ) {
    this.isSubmit = true
    this.httpService.deleteData<T>(endPoint, id)
    .pipe(finalize(() => (this.isSubmit = false)))
    .subscribe({
      next: (res: T) => {
        dialogRef.close(res);
        this.notificationService.displaySuccessMessage(
          this.languageService.getTransValue('messages.deletedSuccessfully'),
        );
      },
      error: () => {
        this.notificationService.displayErrorToastr(
          this.languageService.getTransValue(errorMSg),
        );
      },
    });
  }

  // CREATE AND Download Data as XLSX
  createAndDownloadExcelFile(data, sheet_name, file_name) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheet_name);
    XLSX.writeFile(workbook, file_name);
  }

  //  Download XLSX file Method
  downloadExcelFile<T>(
    dataList: T[],
    returnedObject: {},
    sheetName: string,
    fileName: string,
  ) {
    // Return the Error Message
    if (!dataList.length) {
      this.notificationService.displayErrorToastr(
        this.languageService.getTransValue('excel.emptyTable'),
      );
      return;
    }

    this.createAndDownloadExcelFile(returnedObject, sheetName, `${fileName}.xlsx`);
  }

  protected takeUntilDestroy = () => {
    if (!this.unsubscribeAll) this.unsubscribeAll = new Subject<boolean>();
    return takeUntil(this.unsubscribeAll);
  };

  ngOnDestroy(): void {
    if (this.unsubscribeAll) {
      this.unsubscribeAll.next(true);
      this.unsubscribeAll.complete();
    }
  }
}
