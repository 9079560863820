<dot-modal
  [title]="parentTransName + 'title' | translate"
  (modalClosed)="closeDialog()"
  >

  <!-- START OF MODAL BODY -->
  <ng-container class="modal-body">

    <section class="px-4 mb-4">
      <form class="md:col-span-3" [formGroup]="form">
        <div class="lg:col-span-2">
          <div class="grid gap-x-4 text-sm grid-cols-1 md:grid-cols-4">

            <!-- [name] -->
            <div class="md:col-span-4 mb-1">
              <dot-textbox
                [id]="'name'"
                [name]="'name'"
                [placeholder]="parentTransName + 'name' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.ALFA_NUM"
                formControlName="name"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- Description -->
            <div class="md:col-span-4 mb-1">
              <label class="block">{{parentTransName + 'description' | translate}}</label>
              <textarea
                rows="5" cols="30"
                class="w-full mt-1"
                [required]="false"
                [placeholder]="parentTransName + 'description' | translate"
                pInputTextarea
                formControlName="description"
                ngDefaultControl
                >
              </textarea>
            </div>

            <!-- Unit Of Measure -->
            <div *ngIf="data.isBase" class="md:col-span-2 mb-3">
              <label class="mb-sm-1 block">{{ parentTransName + "unitOfMeasure" | translate }}</label>
              <label class="mt-sm-1 text-base font-semibold">{{ form.value?.unitOfMeasure }}</label>
            </div>

            <!-- [price] -->
            <div *ngIf="data.isBase" class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'price'"
                [name]="'price'"
                [placeholder]="parentTransName + 'price' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.NUMBER"
                formControlName="price"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [billCode] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'billCode'"
                [name]="'billCode'"
                [placeholder]="parentTransName + 'billCode' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.NUMBER"
                formControlName="billCode"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [rounding] -->
            <div *ngIf="!data.isBase" class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'rounding'"
                [name]="'rounding'"
                [placeholder]="parentTransName + 'rounding' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.NUMBER"
                formControlName="rounding"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- priority -->
            <div *ngIf="!data.isBase" class="md:col-span-2 mb-3">
              <label class="mb-sm-1">{{ parentTransName + "priority" | translate }}</label>
              <p-dropdown
                [styleClass]="'w-full'"
                [required]="false"
                [appendTo]="'body'"
                [placeholder]="parentTransName + 'priority' | translate"
                [options]="priorities"
                optionLabel="value"
                formControlName="priority"
                [filter]="true"
                filterBy="value"
              ></p-dropdown>
            </div>

            <!-- isRefundable -->
            <div *ngIf="data.isBase" class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'isRefundable' | translate}}</label>
              <p-inputSwitch formControlName="isRefundable"></p-inputSwitch>
            </div>

            <!-- isBackward -->
            <div *ngIf="data.isBase" class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'isBackward' | translate}}</label>
              <p-inputSwitch formControlName="isBackward"></p-inputSwitch>
            </div>

            <!-- isProrated -->
            <div *ngIf="data.isBase" class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'isProrated' | translate}}</label>
              <p-inputSwitch formControlName="isProrated"></p-inputSwitch>
            </div>

            <!-- Period -->
            <div *ngIf="data?.price?.data?.period" class="md:col-span-2 mb-3">
              <label class="mb-sm-1">{{ parentTransName + "period" | translate }}</label>
              <p-dropdown
                [styleClass]="'w-full'"
                [required]="false"
                [appendTo]="'body'"
                [placeholder]="parentTransName + 'period' | translate"
                [options]="periods"
                optionLabel="name"
                formControlName="period"
                [filter]="true"
                filterBy="name"
              ></p-dropdown>
            </div>

            <!-- Application Duration [LIST] -->
            <div *ngIf="data?.price?.data?.applicationDuration && data.isBase" class="md:col-span-2 mb-3">
              <label class="mb-sm-1">{{ parentTransName + "applicationDuration" | translate }}</label>
              <p-dropdown
                [styleClass]="'w-full'"
                [required]="false"
                [appendTo]="'body'"
                [placeholder]="parentTransName + 'applicationDuration' | translate"
                [options]="periods"
                optionLabel="name"
                formControlName="applicationDuration"
                [filter]="true"
                filterBy="name"
              ></p-dropdown>
            </div>

            <!-- Application Duration [NUMBER] -->
            <div *ngIf="data?.price?.data?.applicationDuration && !data?.isBase" class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'applicationDuration'"
                [name]="'applicationDuration'"
                [placeholder]="parentTransName + 'applicationDuration' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.NUMBER"
                formControlName="applicationDuration"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- Tax Definition IS RATE -->
            <div *ngIf="data?.price?.data?.taxDefinition && !data.isBase" class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'isRte' | translate}}</label>
              <p-inputSwitch (onChange)="getTaxDefinitions()" formControlName="taxDefinitionIsRate"></p-inputSwitch>
            </div>

            <!-- Tax Definition -->
            <div *ngIf="data?.price?.data?.taxDefinition && !data.isBase" class="md:col-span-2 mb-3">
              <label class="mb-sm-1">{{ parentTransName + "taxDefinition" | translate }}</label>
              <p-dropdown
                [styleClass]="'w-full'"
                [required]="false"
                [appendTo]="'body'"
                [placeholder]="parentTransName + 'taxDefinition' | translate"
                [options]="taxDefinitions"
                optionLabel="name"
                formControlName="taxDefinition"
                [filter]="true"
                filterBy="name"
              ></p-dropdown>
            </div>

            <!-- [Tax Definition Amount / Rate] -->
            <div *ngIf="data?.price?.data?.taxDefinition && form.value?.taxDefinition?.id" class="md:col-span-2 mb-1">
              <label class="mb-sm-1 block">{{parentTransName + ((form.value?.taxDefinitionIsRate)? 'taxDefinitionRate' : 'taxDefinitionAmount') | translate}}</label>
              <label class="mt-sm-1 text-base font-semibold">{{ (form.value?.taxDefinitionIsRate)? form.value?.taxDefinition?.rate: form.value?.taxDefinition?.amount }}</label>
            </div>


            <!-- Tariff Profile -->
            <div *ngIf="data?.price?.data?.tariffProfile && !data.isBase" class="md:col-span-2 mb-3">
              <label class="mb-sm-1">{{ parentTransName + "tariffProfile" | translate }}</label>
              <p-dropdown
                [styleClass]="'w-full'"
                [required]="false"
                [appendTo]="'body'"
                [placeholder]="parentTransName + 'tariffProfile' | translate"
                [options]="tariffProfiles"
                optionLabel="profile"
                formControlName="tariffProfile"
                [filter]="true"
                filterBy="profile"
              ></p-dropdown>
            </div>

            <!-- Tariff Profile IsRate -->
            <div *ngIf="form.value?.tariffProfile?.id" class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'isRte' | translate}}</label>
              <label class="mt-sm-1 text-base font-semibold">{{ (form.value?.tariffProfile?.isRate)? 'Yes': 'No' }}</label>
            </div>

            <!-- [Tariff Profile Rate] -->
            <div *ngIf="form.value?.tariffProfile?.id && form.value?.tariffProfile?.isRate" class="md:col-span-2 mb-1">
              <label class="mb-sm-1 block">{{ parentTransName + "tariffProfileRate" | translate }}</label>
              <label class="mt-sm-1 text-base font-semibold">{{ form.value?.tariffProfile?.rate }}</label>
            </div>

            <!-- [Tariff Profile Amount] -->
            <div *ngIf="form.value?.tariffProfile?.id && !form.value?.tariffProfile?.isRate" class="md:col-span-2 mb-1">
              <label class="mb-sm-1 block">{{ parentTransName + "tariffProfileAmount" | translate }}</label>
              <label class="mt-sm-1 text-base font-semibold">{{ form.value?.tariffProfile?.amount }}</label>
            </div>

            <!-- startDateTime -->
            <div class="md:col-span-2 mb-2 flex flex-col gap-1">
              <label>{{parentTransName + 'startDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                [appendTo]="'body'"
                formControlName="startDateTime"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'startDateTime' | translate"
                ></p-calendar>
            </div>

            <!-- endDateTime -->
            <div class="md:col-span-2 mb-2 flex flex-col gap-1">
              <label>{{parentTransName + 'endDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                [appendTo]="'body'"
                formControlName="endDateTime"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'endDateTime' | translate"
                ></p-calendar>
            </div>

          </div>
        </div>
      </form>
    </section>

  </ng-container>
  <!-- END OF MODAL BODY -->

  <!-- START OF FOOTER -->
  <ng-container class="modal-footer">

    <!-- START OF CREATE BTN -->
    <button
          type="button"
          (click)="onSubmit()"
          class="dot_btn dot_btn_primary"
          [disabled]="form.invalid"
        >
        {{'btn.update' | translate}}
    </button>
    <!-- END OF CREATE BTN -->

  </ng-container>
  <!-- END OF FOOTER -->

</dot-modal>
