import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LOCAL_STORAGE_PREFIX, USER, USER_TYPES } from '@shared/utilities/defines';

@Injectable({ providedIn: 'root' })
export class StorageService {
  user: any;

  //  holds current logged in user status
  public status: { isLoggedIn: boolean };

  // initiating the default values
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.status = { isLoggedIn: !!this.getStorage(USER) };
    this.user = this.getStorage(USER) || null;
  }

  /*
   * read certain item from the session storage or from the cachedSession and
   * parse the item to json if the item is a stringified object.
   * @param  {key} The key of the property to be detected
   * @returns {Object} the returned object holds the value for the detected property
   * */
  public getStorage = (key: string) => {
    if (isPlatformBrowser(this.platformId)) {
      try {
        return JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_PREFIX}.${key}`) as string);
      } catch (error) {
        return localStorage.getItem(`${LOCAL_STORAGE_PREFIX}.${key}`);
      }
    }
    return '';
  };

  /*
   * add/set item to browser localstorage
   * @param {key} the identifier for the local storage item
   * @param {value} the value of localstorage item
   * */
  public setStorage = (key: string, value: any) => {
    if (isPlatformBrowser(this.platformId)) {
      const newKey = `${LOCAL_STORAGE_PREFIX}.${key}`;
      // don't add incase there is no value
      if (!value) return;
      // Add to localstorage
      if (typeof value === 'object') {
        localStorage.setItem(newKey, JSON.stringify(value));
      } else {
        localStorage.setItem(newKey, String(value));
      }
    }
  };

  public setUser(user: any) {
    this.user = user;
  }

  public getUser() {
    return this.user;
  }

  public setUserTypes(userTypes: any) {
    this.setStorage(USER_TYPES, userTypes);
  }

  public getUserTypes() {
    return this.getStorage(USER_TYPES);
  }

  /*
   * clear all the localStorage items and the cachedSession items
   */
  public empty = () => {
    localStorage.clear();
  };
}
