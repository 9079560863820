import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  FormControl,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import validator from 'validator';

@Injectable({
  providedIn: 'root',
})
export class CustomValidatorsService {
  constructor() {}

  /**
   * Validates whether the input control value is a valid email address using the
   *  validator.js library.
   *
   * @param control The input control to validate.
   * @returns A validation error object { notEmail: true } if the input value is not a valid email
   * address, or null if validation passes.
   */
  // Check If Input Contains Valid Email
  static isEmail(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim() as string;
    if (value && !validator.isEmail(value)) {
      return { notEmail: true };
    }
    return null;
  }

  /**
   * Creates a validator function that checks whether the input control value matches the
   * specified regular expression using the validator.js library.
   *
   * @param regex The regular expression to match against.
   * @returns A validator function that checks whether the input control value matches the
   *  specified regular expression.
   */
  // Check If Input Match Regex
  static isMatchRegex(regex: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const value = control.value as string;
      if (value && !validator.matches(value, regex)) {
        return { notMatchRegex: true };
      }
      return null;
    };
  }

  /**
   * Validates whether the input control value contains only numeric characters using the
   *  validator.js library.
   *
   * @param control The input control to validate.
   * @returns A validation error object{ notNumber: true } if the input value contains non-numeric characters,
   * or null if validation passes.
   */
  // Check If Input Contains Numbers Only
  static isNumber(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim().toString();
    if (value && !validator.isNumeric(value, { no_symbols: true })) {
      return { notNumber: true };
    }
    return null;
  }

  /**
   * Validates whether the input control value contains only English characters using the
   * validator.js library.
   *
   * @param control The input control to validate.
   * @returns A validation error object  { notEnglish: true } if the input value contains non-English characters,
   *  or null if validation passes.
   */
  // Check If Input Contains English Characters Only

  static isEnglish(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim() as string;
    const isValid = value
      .split('')
      .every(
        (word) =>
          validator.isAlpha(word, 'en-US') ||
          validator.isNumeric(word, { no_symbols: false }) ||
          word === ' ',
      );
    if (value && !isValid) {
      return { notEnglish: true };
    }
    return null;
  }

  /**
   * Validates whether the input control value contains only Arabic characters using the
   *  validator.js library.
   *
   * @param control The input control to validate.
   * @returns A validation error object  { notArabic: true } if the input value contains non-Arabic
   *  characters, or null if validation passes.
   */

  // Check If Input Contains Arabic Characters Only
  static isArabic(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim() as string;
    const isValid = value
      .split('')
      .every(
        (word) =>
          validator.isAlpha(word, 'ar-EG') ||
          validator.isNumeric(word, { no_symbols: false }) ||
          word === ' ',
      );
    if (value && !isValid) {
      return { notArabic: true };
    }
    return null;
  }

  /**
   * Validates whether the input control value contains only Arabic or English characters and
   * numbers using the validator.js library.
   *
   * @param control The input control to validate.
   * @returns A validation error object { notAlphabet: true } if the input value contains non-Arabic and non-English
   * characters, or null if validation passes.
   */

  // Check If Input Contains Arabic OR English Characters Only
  static isAlphabet(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim() as string;
    const isValidAlphabetEn = value
      .split(' ')
      .every((word) => validator.isAlpha(word, 'en-US'));
    const isValidAlphabetAr = value
      .split(' ')
      .every((word) => validator.isAlpha(word, 'ar-EG'));
    if (value && !isValidAlphabetEn && !isValidAlphabetAr) {
      return { notAlphabet: true };
    }
    return null;
  }
  /**
   * Validates whether the input control value contains only Arabic or English characters and
   * numbers using the validator.js library.
   *
   * @param control The input control to validate.
   * @returns A validation error object{ notAlphaNum: true } if the input value contains
   *  non-Arabic and non-English
   *  characters, or null if validation passes.
   */

  // Check If Input Contains Arabic OR English Characters And Numbers Only
  static isAlphanumeric(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim() as string;
    const isValidAlphanumericEn = value
      .split(' ')
      .every((word) => validator.isAlphanumeric(word, 'en-US'));
    const isValidAlphanumericAr = value
      .split(' ')
      .every((word) => validator.isAlphanumeric(word, 'ar-EG'));
    if (value && !isValidAlphanumericEn && !isValidAlphanumericAr) {
      return { notAlphaNum: true };
    }
    return null;
  }

  /**
   * Validates whether the input control value contains only Arabic characters and numbers using
   * the validator.js library.
   *
   * @param control The input control to validate.
   * @returns A validation error object { notArAlphaNum: true } if the input value contains
   *  non-Arabic characters or non-numeric characters, or null if validation passes.
   */

  // Check If Input Contains Arabic Characters And Numbers Only
  static isArabicAlphanumeric(
    control: AbstractControl,
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim() as string;
    const isValidAlphanumericAr = value
      .split(' ')
      .every((word) => validator.isAlphanumeric(word, 'ar-EG'));
    if (value && !isValidAlphanumericAr) {
      return { notArAlphaNum: true };
    }
    return null;
  }

  /**
   * Validates whether the input control value contains only English characters and numbers
   *  using the validator.js library.
   *
   * @param control The input control to validate.
   * @returns A validation error object { notEnAlphaNum: true } if the input value contains
   *  non-English characters or
   *  non-numeric characters, or null if validation passes.
   */

  // Check If Input Contains English Characters And Numbers Only
  static isEnglishAlphanumeric(
    control: AbstractControl,
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim() as string;
    const isValidAlphanumericEn = value
      .split(' ')
      .every((word) => validator.isAlphanumeric(word, 'en-US'));
    if (value && !isValidAlphanumericEn) {
      return { notEnAlphaNum: true };
    }
    return null;
  }

  /**
   * Validates whether the input control value is a valid mobile phone number in the format
   *  +20xxxxxxxxxx using the validator.js library.
   *
   * @param control The input control to validate.
   * @returns A validation error object { notPhone: true } if the input value is not a valid mobile phone number,
   *  or null if validation passes.
   */

  // Check If Input Contains Valid Mobile Number
  static isPhone(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim() as string;
    if (value && !validator.isMobilePhone(value, 'ar-EG')) {
      return { notPhone: true };
    }
    return null;
  }

  /**
   * Validates the date range by comparing two date values.
   *
   * @param {string} firstDate - The first date value.
   * @param {string} expiryDate - The expiry date value.
   * @returns {boolean} - Returns true if the expiry date is before the first date, otherwise false.
   */
  dateRangeValidator(firstDate: string, expiryDate: string) {
    const firstDateValue = moment(firstDate);
    const expiryDateValue = moment(expiryDate);
    if (expiryDateValue.isBefore(firstDateValue)) {
      return true;
    }
    return false;
  }

  comparBetweenTwoValues(
    theBiggerValue: number,
    theSmallervalue: number,
  ): boolean {
    if (theBiggerValue > theSmallervalue) return true;
    return false;
  }

  removeValidOnControl(control: AbstractControl): void {
    control.clearValidators();
    control.updateValueAndValidity();
  }
  addValidOnControl(control: AbstractControl) {
    control.setValidators([Validators.required]);
    control.updateValueAndValidity();
  }
}
