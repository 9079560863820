
<table class="tree-container">
  <tbody>
    <tr>

      <!-- START ROOT POINT DESIGN -->
      <td *ngIf="item.type === 'root'" [sortablejs]="item.children" [sortablejsOptions]="rootPointOptions" class="tree-parent" [ngClass]="{'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">
        <div class="tree_root">
          <span class="tree_root__icon" [inlineSVG]="'./assets/images/sidebar_logo.svg'"></span>
        </div>
      </td>
      <!-- END ROOT POINT DESIGN -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'offer'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="productOffersOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF OFFER CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF COLLAPSING CONTROLLER  -->
            <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

              <!-- START OF ICON -->
              <span>
                <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
              </span>
              <!-- END OF ICON -->

            </div>
            <!-- END OF COLLAPSING CONTROLLER  -->

            <!-- START OF OFFER DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF OFFER ICON -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF OFFER ICON -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editOfferItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT OFFER -->

                <!-- START OF DELETE OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE OFFER -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">offer name</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.name}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">offer description</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.description}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF STATUS -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Status </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.status}}</span>
                </div>
                <!-- END OF STATUS -->

                <!-- START OF TYPE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Type </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.isPrimary? 'Primary' : 'Supplementary'}}</span>
                </div>
                <!-- END OF TYPE -->

                <!-- START OF SELLABLE STAND ALONE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">sellable alone </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.isSellableStandAlone? 'Yes' : 'No'}}</span>
                </div>
                <!-- END OF SELLABLE STAND ALONE -->
              </div>
              <!-- END OF DETAILs -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF IS BUNDLE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Is Bundle</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.isBundle? 'Yes': 'No'}}</span>
                </div>
                <!-- END OF IS BUNDLE -->

                <!-- START OF SMS -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">SMS</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.sms? 'Yes': 'No'}}</span>
                </div>
                <!-- END OF SMS -->

                <!-- START OF VERSION -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">version</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.version}}</span>
                </div>
                <!-- END OF VERSION -->
              </div>
              <!-- END OF DETAILs -->

              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF OFFER DETAILS -->

          </div>
        </ng-container>
        <!-- END OF OFFER CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'specification'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="productSpecsOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF COLLAPSING CONTROLLER  -->
        <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

          <!-- START OF ICON -->
          <span>
            <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
          </span>
          <!-- END OF ICON -->

        </div>
        <!-- END OF COLLAPSING CONTROLLER  -->

        <!-- START OF OFFER CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF OFFER DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF OFFER ID -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF OFFER ID -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editSpecsItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT OFFER -->

                <!-- START OF DELETE OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE OFFER -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">Specification name</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.name}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">Specification description</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.description}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF STATUS -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Status</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.status}}</span>
                </div>
                <!-- END OF STATUS -->

                <!-- START OF TYPE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Brand</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.brand}}</span>
                </div>
                <!-- END OF TYPE -->

                <!-- START OF SELLABLE STAND ALONE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Shared</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.isShared? 'Yes' : 'No'}}</span>
                </div>
                <!-- END OF SELLABLE STAND ALONE -->
              </div>
              <!-- END OF DETAILs -->

              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF OFFER DETAILS -->

          </div>
        </ng-container>
        <!-- END OF OFFER CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'offerRelationship'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="productRelationshipOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF COLLAPSING CONTROLLER  -->
        <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

          <!-- START OF ICON -->
          <span>
            <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
          </span>
          <!-- END OF ICON -->

        </div>
        <!-- END OF COLLAPSING CONTROLLER  -->

        <!-- START OF OFFER CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF OFFER DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF OFFER ID -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF OFFER ID -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editRelationshipItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT OFFER -->

                <!-- START OF DELETE OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE OFFER -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">Relationship Type</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.type}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">Related Offer</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.productOffering?.id}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF OFFER DETAILS -->

          </div>
        </ng-container>
        <!-- END OF OFFER CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'productPrice'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="productPriceOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF COLLAPSING CONTROLLER  -->
        <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

          <!-- START OF ICON -->
          <span>
            <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
          </span>
          <!-- END OF ICON -->

        </div>
        <!-- END OF COLLAPSING CONTROLLER  -->

        <!-- START OF OFFER CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF OFFER DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF OFFER ID -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF OFFER ID -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editPriceItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT OFFER -->

                <!-- START OF DELETE OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE OFFER -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">Price Name</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.name}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">Price description</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.description}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF PRICE TYPE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">price type</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.priceType}}</span>
                </div>
                <!-- END OF PRICE TYPE -->

                <!-- START OF MEASURE UNIT -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Measure Unit</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.unitOfMeasure}}</span>
                </div>
                <!-- END OF MEASURE UNIT -->

                <!-- START OF PRICE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">price</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.price}}</span>
                </div>
                <!-- END OF PRICE -->
              </div>
              <!-- END OF DETAILs -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF Bill Code -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Bill Code</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.billCode}}</span>
                </div>
                <!-- END OF Bill Code -->

                <!-- START OF Is Refundable -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Is Refundable</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.isRefundable? 'Yes' : 'No'}}</span>
                </div>
                <!-- END OF Is Refundable -->

              </div>
              <!-- END OF DETAILs -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">

                <!-- START OF Is Backward -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Is Backward</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.isBackward? 'Yes' : 'No'}}</span>
                </div>
                <!-- END OF Is Backward -->

                <!-- START OF Is Prorated -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Is Prorated</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.isProrated? 'Yes' : 'No'}}</span>
                </div>
                <!-- END OF Is Prorated -->
              </div>
              <!-- END OF DETAILs -->

              <!-- START OF PERIOD -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF PRICE TYPE -->
                <div *ngIf="item?.data?.period">
                  <span class="font-semibold uppercase text-xs block text-slate-400">period</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.period}}</span>
                </div>
                <!-- END OF PRICE TYPE -->

                <!-- START OF MEASURE UNIT -->
                <div *ngIf="item?.data?.applicationDuration">
                  <span class="font-semibold uppercase text-xs block text-slate-400">Application Duration</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.applicationDuration}}</span>
                </div>
                <!-- END OF MEASURE UNIT -->

              </div>
              <!-- END OF PERIOD -->

              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF OFFER DETAILS -->

          </div>
        </ng-container>
        <!-- END OF OFFER CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'productPriceAlteration'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="productPriceAlterationsOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF COLLAPSING CONTROLLER  -->
        <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

          <!-- START OF ICON -->
          <span>
            <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
          </span>
          <!-- END OF ICON -->

        </div>
        <!-- END OF COLLAPSING CONTROLLER  -->

        <!-- START OF OFFER CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF OFFER DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF OFFER ID -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF OFFER ID -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editPriceAlterationItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT OFFER -->

                <!-- START OF DELETE OFFER -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE OFFER -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">Price Name</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.name}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">Price description</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.description}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF PRICE TYPE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">price type</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.priceType}}</span>
                </div>
                <!-- END OF PRICE TYPE -->

                <!-- START OF MEASURE UNIT -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Measure Unit</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.unitOfMeasure}}</span>
                </div>
                <!-- END OF MEASURE UNIT -->

                <!-- START OF PRICE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">price</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.price}}</span>
                </div>
                <!-- END OF PRICE -->
              </div>
              <!-- END OF DETAILs -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF Bill Code -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Bill Code</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.billCode}}</span>
                </div>
                <!-- END OF Bill Code -->

                <!-- START OF Rounding -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Rounding</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.rounding}}</span>
                </div>
                <!-- END OF Rounding -->

                <!-- START OF Priority -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Priority</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.priority}}</span>
                </div>
                <!-- END OF Priority -->
              </div>
              <!-- END OF DETAILs -->


              <!-- START OF PERIOD -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF PRICE TYPE -->
                <div *ngIf="item?.data?.period">
                  <span class="font-semibold uppercase text-xs block text-slate-400">period</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.period}}</span>
                </div>
                <!-- END OF PRICE TYPE -->

                <!-- START OF MEASURE UNIT -->
                <div *ngIf="item?.data?.applicationDuration">
                  <span class="font-semibold uppercase text-xs block text-slate-400">Application Duration</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.applicationDuration}}</span>
                </div>
                <!-- END OF MEASURE UNIT -->

              </div>
              <!-- END OF PERIOD -->

              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF OFFER DETAILS -->

          </div>
        </ng-container>
        <!-- END OF OFFER CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE CHILDs -->
      <td *ngIf="item.children" class="node-container">
        <div class="tree-child" *ngFor="let child of item.children">
          <product-catalog-tree (removeNode)="removeItem($event)" *ngIf="child?.children" [catalogTree]="catalogTree" [item]="child" [productOffers]="productOffers"></product-catalog-tree>
        </div>
      </td>
      <!-- END OF TREE-NODE CHILDs -->

    </tr>
  </tbody>
</table>
