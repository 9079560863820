import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticationService } from '@core/authentication';
import { IsActiveMatchOptions, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public showSideMenu = false;

  public routerLinkActiveOptions: IsActiveMatchOptions = {
    fragment: 'exact',
    matrixParams: 'ignored',
    paths: 'subset',
    queryParams: 'ignored',
  };

  @Output() toggleSideMenu = new EventEmitter();



  constructor(
    public authenticationService: AuthenticationService,
     private router: Router,
   
     ) {}


  logout() {
    this.authenticationService.logout();
  }

  get isAuthenticated() {
    return this.authenticationService.isAuthenticated;
  }

  toggle() {
    this.showSideMenu = !this.showSideMenu;
    const body = document && document.querySelector('body');
    if (body) {
      body.style.overflow = this.showSideMenu ? 'hidden' : '';
    }
    this.toggleSideMenu.emit(this.showSideMenu);
  }
}
