import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private subject = new Subject<any>();
  constructor() {
    this.notificationUpdateCount(0); //please remove this befor using the service and implemet this function on part watching chnages from socket or push notification
  }

  public notificationUpdateCount(count: any) {
    this.subject.next(count)
  }

  public notificationCountChange(): Observable<any> {
    return this.subject.asObservable();
  }
}
