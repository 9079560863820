<!-- START OF USER SETTINGS -->
<div class="user_settings">

  <!-- START OF USER PROFILE DATA -->
  <div (click)="userSettingsMenu.toggle($event)" class="flex flex-row items-center gap-2 user_settings__profile">

    <!-- START OF PROFILE AVATAR -->
    <div class="user_settings__profile__avatar">
      <p-avatar image="https://via.placeholder.com/150" size="large" shape="circle"></p-avatar>
    </div>
    <!-- END OF PROFILE AVATAR -->

    <!-- START OF PROFILE DETAILS -->
    <div class="user_settings__profile__details flex flex-col items-start mx-2">
      <!-- START OF USER NAME -->
      <span class="text-sm font-semibold">{{ userDetails?.name || "Mostafa" }}</span>
      <!-- END OF USER NAME -->

      <!-- START OF USER ROLE -->
      <span class="text-xs">Admin</span>
      <!-- END OF USER ROLE -->
    </div>
    <!-- END OF PROFILE DETAILS -->

    <!-- START OF SETTINGS MENU ICON -->
    <div class="user_settings__profile__menu_icon ml-3">
      <i class="pi" [ngClass]="{'pi-chevron-circle-up': userSettingsMenu.visible, 'pi-chevron-circle-down': !userSettingsMenu.visible}" style="font-size: 1.125rem"></i>
    </div>
    <!-- END OF SETTINGS MENU ICON -->

  </div>
  <!-- END OF USER PROFILE DATA -->

  <!-- START OF SETTINGS MENU -->
  <div class="user_settings__settings_menu">
    <p-tieredMenu appendTo="body" styleClass="user_settings_menu" isRtl #userSettingsMenu [model]="items" [popup]="true"></p-tieredMenu>
  </div>
  <!-- END OF SETTINGS MENU -->

</div>
<!-- END OF USER SETTINGS -->
