import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';

@Component({
  selector: 'app-update-service-spec-char-use-data',
  templateUrl: './update-service-spec-char-use-data.component.html',
  styleUrls: ['./update-service-spec-char-use-data.component.scss']
})
export class UpdateServiceSpecCharUseDataComponent extends AppBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'provision.serviceCatalogDesigner.';

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1)

  constructor(
    private dialogRef: MatDialogRef<UpdateServiceSpecCharUseDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      name: [null],
      description: [null],
      unique: [null],
      isPackage: [null],
      extensible: [null],
      canBeOverridden: [null],
      minCardinality: [null],
      maxCardinality: [null],
      startDateTime: [null],
      endDateTime: [null],
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(){
    // Return with name
    this.closeDialog(this.form.value)
  }

  ngOnInit(): void {
    if(this.data.isUpdateForm){
      // Load Data
      this.form.patchValue({
        name: this.data?.item?.data?.name,
        description: this.data?.item?.data?.description,
        unique: this.data?.item?.data?.unique,
        isPackage: this.data?.item?.data?.isPackage,
        canBeOverridden: this.data?.item?.data?.canBeOverridden,
        minCardinality: this.data?.item?.data?.minCardinality,
        maxCardinality: this.data?.item?.data?.maxCardinality,
        extensible: this.data?.item?.data?.extensible,
        startDateTime: this.data?.item?.data?.validFor?.startDateTime,
        endDateTime: this.data?.item?.data?.validFor?.endDateTime,
      });
    }
  }

}
