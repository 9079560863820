import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dot-image-uploader',
  templateUrl: './dot-image-uploader.component.html',
  styleUrls: ['./dot-image-uploader.component.scss']
})
export class DotImageUploaderComponent {

  // INPUTES
  @Input() src: any = "https://via.placeholder.com/150";

  // OUTPUTS
  @Output() imageHandler: EventEmitter<any> = new EventEmitter<any>();

  handleImageUpload(event){
    this.imageHandler.emit(event)
  }

  openImageUploadDialog(imageUploadInput){
    imageUploadInput.click();
  }
}
