<div class="custom-picker">
  <mat-form-field
    [ngClass]="{ 'not-read-only': !isReadOnly, 'dot-readonly': isReadOnly }"
    appearance="outline"
  >

    <mat-datepicker-toggle
      matPrefix
      [for]="dp"
      *ngIf="!isReadOnly"
    ></mat-datepicker-toggle>
    <input
      matInput
      [matDatepicker]="dp"
      id="{{ id }}"
      name="{{ name }}"
      #variable="ngModel"
      [min]="minDate"
      [max]="maxDate"
      [(ngModel)]="dpValue"
      (focus)="!isReadOnly && !canEditInline ? dp.open() : null"
      [required]="required"
      [disabled]="isDisabled"
      [readonly]="isReadOnly"
      placeholder="DD/ MM/ YYYY"
      (dateChange)="dateChange($event)"
    />
    <mat-datepicker #dp></mat-datepicker>
    <mat-icon matSuffix *ngIf="isEditMode()" (click)="submitEdit(dpValue)"
      >done</mat-icon
    >
    <mat-icon matSuffix *ngIf="isEditMode()" (click)="closeEdit()"
      >close</mat-icon
    >
    <mat-icon
      matSuffix
      (click)="toggleReadOnly(dp)"
      *ngIf="isReadOnly && canEditInline"
      >mode_edit</mat-icon
    >

    <mat-error *ngIf="variable.invalid && requiredErrorMessage">
      {{ requiredErrorMessage | translate}}
    </mat-error>
    <mat-error *ngIf="additionalErrorMessage">
      {{ additionalErrorMessage }}
    </mat-error>
  </mat-form-field>
</div>
