import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { ToasterService } from '../toaster/toaster.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private notificationService: ToasterService,
  ) {}

  getData<T>(url: string, params = {}): Observable<T> {
    return this.http.get<T>(url, { params }).pipe(
      catchError((error) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
    );
  }

  getDataWithHeaders<T>(url: string, headersObject = {}): Observable<T> {

    // CREATE HEADER INSTANCE
    let headers = new HttpHeaders()

    // LOOP OVER COMING HEADERS
    for (let header in headersObject){
      headers = headers.set(header.toString(), headersObject[header].toString())
    }

    return this.http.get<T>(url, { headers }).pipe(
      catchError((error) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
    );
  }

  postData<T>(url: string, body: {}): Observable<T> {
    return this.http.post<T>(url, body).pipe(
      catchError((error) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
    );
  }

  putData<T>(url: string, id: number, body: {}): Observable<T> {
    return this.http.put<T>(`${url}/${id}`, body).pipe(
      catchError((error) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
    );
  }

  putDataI<T>(url: string, body: {}): Observable<T> {
    return this.http.put<T>(`${url}`, body).pipe(
      catchError((error) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
    );
  }

  patchData<T>(endPoint: string, body: {}): Observable<T> {
    return this.http.patch<T>(`${endPoint}`, body).pipe(
      catchError((error) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
    );
  }

  deleteData<T>(url: string, id: number): Observable<T> {
    return this.http.delete<T>(`${url}/${id}`).pipe(
      catchError((error) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
    );
  }

  deleteDataI<T>(url: string): Observable<T> {
    return this.http.delete<T>(`${url}`).pipe(
      catchError((error) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
    );
  }

  deleteWithBody<T>(url: string, body): Observable<T> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers, body: body };

    return this.http.delete<T>(`${url}`, options).pipe(
      catchError((error) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
    );
  }

  // Handle Http Errors
  handleHttpError(error: any): void {

    // DISPLAY THE ERROR MESSAGE
    this.notificationService.displayErrorToastr(error?.error?.message || error?.error?.text || error?.error?.error)
  }
}
