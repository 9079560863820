import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppBaseComponent } from '@shared/components/base/app-base-component';
import { Options } from 'sortablejs'
import { IsMatchDimensionsDirective } from '@shared/directives/is-match-dimensions.directive';
import { SERVICE_CATALOG_CRYPTO_KEY } from '@shared/utilities/security';
import { ServiceCatalogModelerSettingsComponent } from './service-catalog-modeler-settings/service-catalog-modeler-settings.component';
import { finalize } from 'rxjs';


// INTERFACES
import { serviceCatalogInterface } from './interfaces';
import { atomicServiceDTO, compositeServiceDTO, entitySpecCharUseDTO, entitySpecCharValueUseDTO, rfsDTO, characteristicSpecificationDTO, characteristicSpecValueDTO } from './interfaces/serviceCatalogDTO';
import { PROVISION_API_URLS } from '@shared/utilities/service-config/provision-management-service-config';
import moment from 'moment';
import { executionStartTime } from '@shared/utilities';

@Component({
  selector: 'dot-service-catalog-modeler',
  templateUrl: './dot-service-catalog-modeler.component.html',
  styleUrls: ['./dot-service-catalog-modeler.component.scss'],
  providers: [IsMatchDimensionsDirective]
})
export class DotServiceCatalogModelerComponent extends AppBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'provision.serviceCatalogDesigner.';

  // FILE INPUT REFERENCE
  @ViewChild('serviceCatalogFileInput', { static: false }) serviceCatalogFileInput: ElementRef;

  // EVENTS
  @Output() changeTabName: EventEmitter<any> = new EventEmitter<any>()

  /**
   * CONSTRUCTOR
   * @param isMatchDimensionsDirective
   */
  constructor(
    private isMatchDimensionsDirective: IsMatchDimensionsDirective,
  )
  {
    super()
  }

  // CONTROLS
  hideOverlay = false
  serviceCatalogName: string = 'New Service Catalog'

  /**
   * =============== START OF BASE SETUP ==================
   */

  // SERVICE CATALOG TREE
  nestedItems: serviceCatalogInterface[] = [
    {
      name: 'ROOT',
      type: 'root',
      children: [],
    },
  ];

  /**
   * RESET THE CATALOG TREE
   */
  clearServiceCatalog(){

    // RESET THE SERVICE CATALOG
    this.nestedItems = [
      {
        name: 'ROOT',
        type: 'root',
        children: [],
      },
    ];

  }

  /**
   * CREATE / UPDATE THE CURRENT CATALOG
   * DETAILS (ex: Catalog name)
   */
  createServiceCatalogDiagram(data?){
    const settingsDialog = this.dialogService.open(ServiceCatalogModelerSettingsComponent, {
      width: '600px',
      disableClose: true,
      data: data? {name: data, isUpdateForm: true} : {isUpdateForm: false}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: string)=>{
      if(res){

        // Change BPMN Name
        this.serviceCatalogName = res

        // HIDE THE SPLASH SCREEN ON CREATE
        if(!data) this.hideOverlay = true;

        // Change Tab Name
        this.changeTabName.emit(res)

      }
    })
  }

  /**
   * LOAD THE UPLOADED CATALOG FILE
   * @param event
   */
  handleFileInput(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {


      try{
        // DECRYPT THE TREE
        const serviceCatalogFile = this.decryptObject(e.target.result, SERVICE_CATALOG_CRYPTO_KEY);

        // SET THE LOADED TREE
        this.nestedItems = serviceCatalogFile

        // CLEAR THE INPUT FIELD AFTER SUCCESSFULLY READING THE FILE
        this.serviceCatalogFileInput.nativeElement.value = '';

      }catch (error){
        // ERROR NOTIFICATION
        this.notificationService.displayErrorToastr(`Error parsing Catalog file: ${error}`)
        return false
      }


      // SET THE FILE NAME
      this.serviceCatalogName = file.name.split('.')[0]

      // HIDE THE SPLASH SCREEN ON CREATE
      this.hideOverlay = true

      // Change Tab Name
      this.changeTabName.emit(this.serviceCatalogName)

    };
    reader.readAsText(file);
  }

  // CHOOSE SERVICE CATALOG FILE
  openServiceCatalogDialog(openServiceCatalogFile: HTMLInputElement): void {
    openServiceCatalogFile.click();
  }

  /**
   * DOWNLOAD THE SERVICE CATALOG TREE
   * AS JSON FILE WITH (.serviceCatalog) EXTENSION
   */
  downloadServiceCatalog(){

    // ENCRYPT THE TREE
    const json = this.encryptObject(this.nestedItems, SERVICE_CATALOG_CRYPTO_KEY)

    // CREATE THE BLOB OF TYPE JSON
    const blob = new Blob([json], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);

    // SET THE LINK & DOWNLOAD
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.serviceCatalogName}.serviceCatalog`;
    a.click();

    // RELEASE THE DATA (Clear url from memory)
    window.URL.revokeObjectURL(url);
  }

  /**
   * CHECK IF THE TREE IS MATCH THE CONTAINER
   * NOTE: FOR DESIGN PURPOSE
   * @param element1
   * @param element2
   * @returns
   */
  checkIfContainersMatched(element1: HTMLElement,element2: HTMLElement){
    return this.isMatchDimensionsDirective?.checkMatching(element1,element2)
  }

  /**
   * =============== END OF BASE SETUP ==================
   */

  /**
   * === START OF UNIQUE IDENTIFIERS HOLDERS [Ex. Discriminators] ===
   */

  servicesSpecsDiscriminators: string[] = ['CustomerFacingServiceSpecAtomic', 'CustomerFacingServiceSpecComposite']
  RFSsDiscriminators: string[] = ['PhysicalRFS', 'LogicalRFS']
  entitySpecCharUseDiscriminators: string[] = ['ServiceSpecCharUse']
  entitySpecCharValueUseDiscriminators: string[] = ['ServiceSpecCharValueUse']
  characteristicSpecificationDiscriminators: string[] = ['ServiceSpecCharacteristic']
  characteristicSpecificationValueDiscriminators: string[] = ['ServiceSpecCharacteristicValue']

  /**
   * === END OF UNIQUE IDENTIFIERS HOLDERS [Ex. Discriminators] ===
   */


  /**
   * === START OF COMPONENTS MENU SETUP ===
   */

  servicesSpecsList: serviceCatalogInterface[] = [
    {
      name: 'Atomic Service',
      data: {
        Discriminator: "CustomerFacingServiceSpecAtomic",
        name: "ATOMIC_SERVICE",
        description: "Atomic Service template",
        validFor: {
            startDateTime: moment()
            .add(executionStartTime, 'second')
            .toDate()
            .toISOString()
            .split('.')[0],
            endDateTime: "2050-02-01T13:19:35"
        },
        status: "ACTIVE",
      },
      type: 'atomicServiceSpecs',
      icon: 'atomicServices.svg',
      children: []
    },
    {
      name: 'Composite Service',
      data: {
        Discriminator: "CustomerFacingServiceSpecComposite",
        name: "COMPOSITE_SERVICE",
        description: "Composite of Voice, SMS and Data",
        validFor: {
            startDateTime: moment()
            .add(executionStartTime, 'second')
            .toDate()
            .toISOString()
            .split('.')[0],
            endDateTime: "2050-02-01T13:19:35"
        },
        status: "ACTIVE",
        customerFacingServiceSpec: [],
      },
      type: 'compositeServiceSpecs',
      icon: 'customerFacingServiceSpecComposite.svg',
      children: []
    },
  ]
  RFSsList: serviceCatalogInterface[] = [
    {
      name: 'Physical RFS',
      data: {
        Discriminator: "PhysicalRFS",
        name: "PhysicalRFS for Mobile Line",
        description: "PhysicalRFS for Mobile Line",
        validFor: {
            startDateTime: "2024-09-01T13:19:35",
            endDateTime: "2050-02-01T13:19:35"
        },
        status: "ACTIVE",
        resourceSpecification: [] // Screen Of Resource Specs
      },
      type: 'rfs',
      icon: 'physicalRFS.svg',
      children: []
    },
    {
      name: 'Logical RFS',
      data: {
        Discriminator: "LogicalRFS",
        name: "LogicalRFS for Mobile Line",
        description: "LogicalRFS for Mobile Line",
        validFor: {
            startDateTime: "2024-09-01T13:19:35",
            endDateTime: "2050-02-01T13:19:35"
        },
        status: "ACTIVE",
        resourceSpecification: [] // Screen Of Resource Specs
      },
      type: 'rfs',
      icon: 'logicalRFS.svg',
      children: []
    },
  ]
  characteristicSpecification: serviceCatalogInterface = {
    name: 'Service Spec. Characteristic',
    data: {
      Discriminator: "ServiceSpecCharacteristic",
      name: "Service Spec. Characteristic",
      description : "Service Spec. Characteristic description",
      unique : true,
      valueType : "STRING",
      minCardinality: 1,
      maxCardinality: 1,
      extensible : true,
      derivationFormula : "(2**10)/125",
      validFor: {
        startDateTime: "2024-01-18T11:00:26",
        endDateTime: "2040-01-18T11:00:26"
      },
    },
    type: 'serviceSpecCharacteristic',
    icon: 'serviceSpecCharacteristic.svg',
    children: []
  }
  characteristicSpecValueList: serviceCatalogInterface[] = [
    {
      name: 'Service Spec. Characteristic Value',
      data: {
        Discriminator: "ServiceSpecCharacteristicValue",
        valueType: "numeric",
        isDefault: true,
        value: "150",
        unitOfMeasure: "MBps",
        valueFrom: "100",
        valueTo: "1000",
        rangeInterval: "50",
        rangeStep: 5,
        validFor: {
            startDateTime: "2024-01-18T11:00:26",
            endDateTime: "2040-01-18T11:00:26"
        }
      },
      type: 'characteristicSpecValue',
      icon: 'characteristicSpecValue.svg',
      children: []
    }
  ]
  entitySpecCharUseList: serviceCatalogInterface[] = [
    {
      name: 'Service SpecChar Use',
      data: {
        Discriminator: "ServiceSpecCharUse",
        name: "CreateServiceParam",
        description: "Service Parameters",
        unique: true,
        isPackage: false,
        canBeOverridden: false,
        minCardinality: 1,
        maxCardinality: 1,
        extensible: false,
        characteristicSpecification: {},
        validFor: {
            startDateTime: "2024-01-18T11:00:26",
            endDateTime: "2040-01-18T11:00:26"
        },
      },
      type: 'entitySpecCharUse',
      icon: 'serviceSpecCharUse.svg',
      children: [this.characteristicSpecification]
    },
  ]
  entitySpecCharValueUseList: serviceCatalogInterface[] = [
    {
      name: 'Service Spec. Char Value Use',
      data: {
        Discriminator: "ServiceSpecCharValueUse",
        isDefault: true,
        serviceSpecCharacteristicValue: {},
        validFor: {
            startDateTime: "2024-01-18T11:00:26",
            endDateTime: "2040-01-18T11:00:26"
        },
      },
      type: 'entitySpecCharValueUse',
      icon: 'serviceSpecCharValueUse.svg',
      children: []
    },
  ]

  /**
   * === END OF COMPONENTS MENU SETUP ===
   */

  /**
   * ============= START OF COMMON METHODS =============
   */

  /**
   * TO CREATE NEW REFERENCE FROM THE CLONED OBJECT
   * @param obj
   * @returns JSON
   */
  private deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  // CLONING FUNCTION [Used when need to clone element from the components list]
  cloneCustomization = (item) => {
    return this.deepClone(item)
  }

  /**
   * REMOVE NODE TRIGGER
   * @param itemToRemove
   */
  removeNode(itemToRemove: serviceCatalogInterface) {
    this.nestedItems = this.removeNestedItem(this.nestedItems, itemToRemove);
  }

  /**
   * REMOVE NODE EXECUTER
   * @param items
   * @param itemToRemove
   * @returns
   */
  removeNestedItem(items: serviceCatalogInterface[], itemToRemove: serviceCatalogInterface): serviceCatalogInterface[] {
    return items.filter((item) => {
      if (item === itemToRemove) {
        return false;
      } else if (item.children) {
        item.children = this.removeNestedItem(item.children, itemToRemove);
        return true;
      }
      return true;
    });
  }

  /**
   * SUBMIT TREE AND CONVERT IT TO BE A VALID OFFER
   */
  onSubmit(){

    // GET THE CURRENT TREE OFFERs
    const services = this.convertTree()

    for(let service of services[0]){
      // SEND CREATION REQUEST
      this.createService(service)
    }
  }

  createService(service){

    // Create the endpoint
    const endpoint = (service?.Discriminator === 'CustomerFacingServiceSpecComposite')? PROVISION_API_URLS.COMPOSITE_SERVICES : PROVISION_API_URLS.ATOMIC_SERVICES

    // SEND REQUEST
    this.httpService
    .postData(endpoint, service)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {
        this.notificationService.displaySuccessMessage("Service Created Successfullyy")
      }
    })

  }

  /**
   * ============= END OF COMMON METHODS =============
   */


  /**
   * ============= START OF CLONING OPTIONS =============
   */

  servicesSpecsListCloneOptions: Options = {
    group: {
      name: 'services-specs-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  rfsCloneOptions: Options = {
    group: {
      name: 'rfs-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  entitySpecCharUseCloneOptions: Options = {
    group: {
      name: 'entity-spec-char-use-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  entitySpecCharValueUseCloneOptions: Options = {
    group: {
      name: 'entity-spec-char-value-use-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  characteristicSpecificationCloneOptions: Options = {
    group: {
      name: 'characteristic-specification-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  characteristicSpecValueCloneOptions: Options = {
    group: {
      name: 'characteristic-specification-value-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  /**
   * ============= END OF CLONING OPTIONS =============
   */

  /**
   * ====== START OF TRANSFORMATION METHODS ===========
   */

  /* TRANSFORM CATALOG TREE NODEs TO BE A VALID
   * REQUEST OBJECT
   * @param node
   * @returns
   */
  transformTree(node: any): (atomicServiceDTO | compositeServiceDTO | rfsDTO | entitySpecCharUseDTO | entitySpecCharValueUseDTO | characteristicSpecificationDTO) {

    switch (node.type){
      case "atomicServiceSpecs":{

        // SET THE OFFER ATTRIBUTES
        const transformedNode: atomicServiceDTO = {
          Discriminator : node.data?.Discriminator,
          name : node.data?.name,
          description : node.data?.description,
          status : node.data?.status,
          validFor : {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
        };

        // GET THE SPECS CHAR USE NODE
        const entitySpecCharUse = node.children
        ?.filter((child: any) => child.type === 'entitySpecCharUse')
        .map((child: any) => this.transformTree(child));

        // GET THE RFSs
        const rfs = node.children
        ?.filter((child: any) => child.type === 'rfs')
        .map((child: any) => this.transformTree(child));


        // SET THE SPECS CHAR USE
        if (entitySpecCharUse && entitySpecCharUse.length > 0) {
          transformedNode.entitySpecCharUse = entitySpecCharUse;
        }

        // SET THE RFSs
        if (rfs && rfs.length > 0) {
          transformedNode.resourceFacingServiceSpec = rfs;
        }

        // RETURN THE OFFER
        return transformedNode;
      }

      case "compositeServiceSpecs":{

        // SET THE SPEC ATTRIBUTES
        const transformedNode: compositeServiceDTO = {
          Discriminator: node.data?.Discriminator,
          name: node.data?.name,
          description: node.data?.description,
          status: node.data?.status,
          customerFacingServiceSpec: node.data?.customerFacingServiceSpec,
          validFor: {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
        };

        // RETURN THE SPEC
        return transformedNode;
      }

      case "rfs": {

        // SET THE PRICE ATTRIBUTES
        const transformedNode: rfsDTO = {
          Discriminator: node.data?.Discriminator,
          name: node.data?.name,
          description: node.data?.description,
          resourceSpecification: node.data?.resourceSpecification,
          validFor: {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
        };

        // RETURN THE PRICE
        return transformedNode;
      }

      case "entitySpecCharUse": {

        // SET THE PRICE ATTRIBUTES
        const transformedNode: entitySpecCharUseDTO = {
          Discriminator: node.data?.Discriminator,
          name: node.data?.name,
          description: node.data?.description,
          unique: node.data?.unique,
          isPackage: node.data?.isPackage,
          canBeOverridden: node.data?.canBeOverridden,
          minCardinality: node.data?.minCardinality,
          maxCardinality: node.data?.maxCardinality,
          extensible: node.data?.extensible,
          validFor: {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
        };

        // GET THE SPECS CHAR USE NODE
        const entitySpecCharValueUse = node.children
        ?.filter((child: any) => child.type === 'entitySpecCharValueUse')
        .map((child: any) => this.transformTree(child));

        // GET THE Characteristic Specification NODE
        const characteristicSpecification = node.children
        ?.filter((child: any) => child.type === 'serviceSpecCharacteristic')
        .map((child: any) => this.transformTree(child));

        // SET THE SPECS CHAR USE
        if (entitySpecCharValueUse && entitySpecCharValueUse.length > 0) {
          transformedNode.entitySpecCharValueUse = entitySpecCharValueUse;
        }

        // SET THE Characteristic Specification
        if (characteristicSpecification && characteristicSpecification.length > 0) {
          transformedNode.characteristicSpecification = characteristicSpecification[0];
        }

        // RETURN THE PRICE
        return transformedNode;
      }

      case "entitySpecCharValueUse": {

        // SET THE PRICE ATTRIBUTES
        const transformedNode: entitySpecCharValueUseDTO = {
          Discriminator: node.data?.Discriminator,
          isDefault: node.data?.name,
          serviceSpecCharacteristicValue: node.data?.serviceSpecCharacteristicValue,
          validFor: {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
        };

        // RETURN THE PRICE
        return transformedNode;
      }

      case "serviceSpecCharacteristic": {

        // SET THE PRICE ATTRIBUTES
        const transformedNode: characteristicSpecificationDTO = {
          Discriminator: node.data?.Discriminator,
          name: node.data?.name,
          description: node.data?.description,
          unique: node.data?.unique,
          valueType: node.data?.valueType,
          minCardinality: node.data?.minCardinality,
          maxCardinality: node.data?.maxCardinality,
          extensible: node.data?.extensible,
          derivationFormula: node.data?.derivationFormula,
          validFor: {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
        };

        // GET THE SPECS CHAR USE NODE
        const characteristicSpecValue = node.children
        ?.filter((child: any) => child.type === 'characteristicSpecValue')
        .map((child: any) => this.transformTree(child));

        // SET THE SPECS CHAR USE
        if (characteristicSpecValue && characteristicSpecValue.length > 0) {
          transformedNode.characteristicSpecValue = characteristicSpecValue;
        }

        // RETURN THE PRICE
        return transformedNode;
      }

      case "characteristicSpecValue": {

        // SET THE PRICE ATTRIBUTES
        const transformedNode: characteristicSpecValueDTO = {
          Discriminator: node.data?.Discriminator,
          isDefault: node.data?.isDefault,
          valueType: node.data?.valueType,
          value: node.data?.value,
          unitOfMeasure: node.data?.unitOfMeasure,
          valueFrom: node.data?.valueFrom,
          valueTo: node.data?.valueTo,
          rangeInterval: node.data?.rangeInterval,
          rangeStep: node.data?.rangeStep,
          validFor: {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
        };

        // RETURN THE PRICE
        return transformedNode;
      }
    }

  }

  /**
   * CONVERT THE TREE TO BE A VALID REQUEST OBJECT
   */
  convertTree(): any {

    const transformedObject = this.nestedItems.map((rootNode) => {
      if (rootNode.type === 'root' && rootNode.children) {
        return rootNode.children.map((offerNode) => this.transformTree(offerNode));
      } else {
        return this.transformTree(rootNode);
      }
    });

    return transformedObject
  }


  /**
   * ====== END OF TRANSFORMATION METHODS ===========
   */


}
