<dot-modal
  [title]="parentTransName + 'entitySpecCharUseUpdateForm.title' | translate"
  (modalClosed)="closeDialog()"
  >

  <!-- START OF MODAL BODY -->
  <ng-container class="modal-body">

    <section class="px-4 mb-4">
      <form class="md:col-span-3" [formGroup]="form">
        <div class="lg:col-span-2">
          <div class="grid gap-x-4 text-sm grid-cols-1 md:grid-cols-4">

            <!-- [name] -->
            <div class="md:col-span-4 mb-1">
              <dot-textbox
                [id]="'name'"
                [name]="'name'"
                [placeholder]="parentTransName + 'common.name' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.ALFA_NUM"
                formControlName="name"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- Description -->
            <div class="md:col-span-4 mb-1">
              <label class="block">{{parentTransName + 'common.description' | translate}}</label>
              <textarea
                rows="5" cols="30"
                class="w-full mt-1"
                [required]="false"
                [placeholder]="parentTransName + 'common.description' | translate"
                pInputTextarea
                formControlName="description"
                ngDefaultControl
                >
              </textarea>
            </div>

            <!-- unique -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'entitySpecCharUseUpdateForm.unique' | translate}}</label>
              <p-inputSwitch formControlName="unique"></p-inputSwitch>
            </div>

            <!-- isPackage -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'entitySpecCharUseUpdateForm.isPackage' | translate}}</label>
              <p-inputSwitch formControlName="isPackage"></p-inputSwitch>
            </div>

            <!-- extensible -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'entitySpecCharUseUpdateForm.extensible' | translate}}</label>
              <p-inputSwitch formControlName="extensible"></p-inputSwitch>
            </div>

            <!-- canBeOverridden -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'entitySpecCharUseUpdateForm.canBeOverridden' | translate}}</label>
              <p-inputSwitch formControlName="canBeOverridden"></p-inputSwitch>
            </div>

            <!-- [minCardinality] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'minCardinality'"
                [name]="'minCardinality'"
                [placeholder]="parentTransName + 'entitySpecCharUseUpdateForm.minCardinality' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.NUMBER"
                formControlName="minCardinality"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [maxCardinality] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'maxCardinality'"
                [name]="'maxCardinality'"
                [placeholder]="parentTransName + 'entitySpecCharUseUpdateForm.maxCardinality' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.NUMBER"
                formControlName="maxCardinality"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- startDateTime -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'common.startDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                formControlName="startDateTime"
                [appendTo]="'body'"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'common.startDateTime' | translate"
                ></p-calendar>
            </div>

            <!-- endDateTime -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'common.endDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                formControlName="endDateTime"
                [appendTo]="'body'"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'common.endDateTime' | translate"
                ></p-calendar>
            </div>

          </div>
        </div>
      </form>
    </section>

  </ng-container>
  <!-- END OF MODAL BODY -->

  <!-- START OF FOOTER -->
  <ng-container class="modal-footer">

    <!-- START OF CREATE BTN -->
    <button
          type="button"
          (click)="onSubmit()"
          class="dot_btn dot_btn_primary"
          [disabled]="form.invalid"
        >
        {{'btn.update' | translate}}
    </button>
    <!-- END OF CREATE BTN -->

  </ng-container>
  <!-- END OF FOOTER -->

</dot-modal>

