<dot-modal
  [title]="parentTransName + 'entitySpecCharValueUseUpdateForm.title' | translate"
  (modalClosed)="closeDialog()"
  >

  <!-- START OF MODAL BODY -->
  <ng-container class="modal-body">

    <section class="px-4 mb-4">
      <form class="md:col-span-3" [formGroup]="form">
        <div class="lg:col-span-2">
          <div class="grid gap-x-4 text-sm grid-cols-1 md:grid-cols-4">

            <!-- isDefault -->
            <div class="md:col-span-4 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'entitySpecCharValueUseUpdateForm.isDefault' | translate}}</label>
              <p-inputSwitch formControlName="isDefault"></p-inputSwitch>
            </div>

            <!-- startDateTime -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'common.startDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                formControlName="startDateTime"
                [appendTo]="'body'"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'common.startDateTime' | translate"
                ></p-calendar>
            </div>

            <!-- endDateTime -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'common.endDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                formControlName="endDateTime"
                [appendTo]="'body'"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'common.endDateTime' | translate"
                ></p-calendar>
            </div>

          </div>
        </div>
      </form>
    </section>

  </ng-container>
  <!-- END OF MODAL BODY -->

  <!-- START OF FOOTER -->
  <ng-container class="modal-footer">

    <!-- START OF CREATE BTN -->
    <button
          type="button"
          (click)="onSubmit()"
          class="dot_btn dot_btn_primary"
          [disabled]="form.invalid"
        >
        {{'btn.update' | translate}}
    </button>
    <!-- END OF CREATE BTN -->

  </ng-container>
  <!-- END OF FOOTER -->

</dot-modal>

