<section  class="modal-wrapper">
  <div class="modal-header">
    <div class="header" >
      <h1 mat-dialog-title class="header-text">{{ title | translate}}</h1>
      <mat-icon (click)="closeModal()"  class="clear-icon"
        >clear</mat-icon
      >
    </div>
  </div>

  <div mat-dialog-content class="modal-content">
    <ng-content></ng-content>
    
    
  </div>
</section>
