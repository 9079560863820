<!-- START OF PERMISSION CARD -->
<a class="vertical__card"  [routerLink]="elements.url">

    <!-- START OF PERMISSION CARD ICON [SVG] -->
    <div class="vertical__card_icon" #iconContainer >
        <!-- SVG ICON -->
    </div>
    <!-- END OF PERMISSION CARD ICON -->

    <!-- START OF PERMISSION CARD TITLE -->
    <div class="vertical__card_title">
        <span>{{elements.title}}</span>
    </div>
    <!-- END OF PERMISSION CARD TITLE -->

</a>
<!-- END OF PERMISSION CARD -->