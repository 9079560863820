import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { config } from '@shared/utilities';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { AccessPermissionsGuard } from '@core/guards/access-permissions.guard';

// GUARD SWITCHER
const enableGuards = true
import { ROUTERS_PARENTS } from 'src/config/permissions/routes/parents_routes';
import { ErrorsLayoutComponent } from './errors/errors-layout/errors-layout.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: config.home.name,
  //   pathMatch: 'full',
  // },

  {
    path: config.home.name,
    canActivate: enableGuards? [AccessPermissionsGuard]: [() => true],
    data: { route: config.home.route, requiredPermissions: ROUTERS_PARENTS.HOME },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: config.systemManagement.name,
    canActivate: enableGuards? [AccessPermissionsGuard]: [() => true],
    data: { route: config.systemManagement.name, requiredPermissions: ROUTERS_PARENTS.SYSTEM_MANAGEMENT_ROUTE_PERMISSIONS },
    component: MainLayoutComponent,
    loadChildren:()=> import('@components/system-management/system-management.module').then((m) => m.SystemManagementModule)
  },
  {
    path: 'errors',
    component: ErrorsLayoutComponent,
    loadChildren:()=> import('./errors/errors.module').then((m) => m.ErrorsModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
