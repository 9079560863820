export const buildFilterQuery = (parametersValues: any): string => {
  if (!parametersValues) {
    return '';
  }
  return Object.keys(parametersValues).reduce((acc, key) => {
    const param = parametersValues[key] ? `&${key}=${parametersValues[key]}` : '';
    // eslint-disable-next-line no-param-reassign
    acc += param;
    return acc;
  }, '');
};
