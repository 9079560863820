import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';

@Component({
  selector: 'app-service-catalog-modeler-settings',
  templateUrl: './service-catalog-modeler-settings.component.html',
  styleUrls: ['./service-catalog-modeler-settings.component.scss']
})
export class ServiceCatalogModelerSettingsComponent extends AppBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'provision.serviceCatalogDesigner.';

  constructor(
    private dialogRef: MatDialogRef<ServiceCatalogModelerSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      name: [null],
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(){
    // Return with name
    this.closeDialog(this.form.value.name)
  }

  ngOnInit(): void {
    if(this.data.isUpdateForm){
      // Load Data
      this.form.patchValue({
        name: this.data.name,
      });
    }
  }

}
