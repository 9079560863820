
<!-- START OF TREE CONTAINER-->
<div class="tree">
  <ng-scrollbar
    #scrollable
    track="all"
    pointerEventsMethod="scrollbar"
    [autoHeightDisabled]="false"
  >

    <!-- START OF TREE -->
    <mat-tree [dataSource]="dataSourceTree" [treeControl]="treeControl">

      <!-- START OF LEAF NODES -->
      <mat-tree-node class="mat-leaf-tree-node" *matTreeNodeDef="let node" matTreeNodePadding>

        <!-- NODE BTN [ICON + ACTION] -->
        <span class="tree__leaf">
          <mat-icon>{{leafIcon}}</mat-icon>
          <span (click)="leafNodeClickEvent(node)">{{node.name}}</span>
        </span>

      </mat-tree-node>
      <!-- END OF LEAF NODES -->

      <!-- START OF EXTENDED NODES -->
      <mat-tree-node class="mat-extended-tree-node" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>

        <!-- NODE BTN [ICON + ACTION] -->
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? parentCloseIcon : parentOpenIcon}}
          </mat-icon>
        </button>

        <!-- NODE DATA -->
        <span class="tree__extended" (click)="parentNodeClickEvent(node)">{{node.name}}</span>


      </mat-tree-node>
      <!-- END OF EXTENDED NODES -->

    </mat-tree>
    <!-- END OF TREE -->
  </ng-scrollbar>
</div>
<!-- END OF TREE CONTAINER -->
