<dot-modal
  [title]="parentTransName + 'characteristicSpecificationValue.title' | translate"
  (modalClosed)="closeDialog()"
  >

  <!-- START OF MODAL BODY -->
  <ng-container class="modal-body">

    <section class="px-4 mb-4">
      <form class="md:col-span-3" [formGroup]="form">
        <div class="lg:col-span-2">
          <div class="grid gap-x-4 text-sm grid-cols-1 md:grid-cols-4">

            <!-- isDefault -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'characteristicSpecificationValue.isDefault' | translate}}</label>
              <p-inputSwitch formControlName="isDefault"></p-inputSwitch>
            </div>

            <!-- [valueType] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'valueType'"
                [name]="'valueType'"
                [placeholder]="parentTransName + 'characteristicSpecificationValue.valueType' | translate"
                [required]="false"
                formControlName="valueType"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [value] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'value'"
                [name]="'value'"
                [placeholder]="parentTransName + 'characteristicSpecificationValue.value' | translate"
                [required]="false"
                formControlName="value"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [unitOfMeasure] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'unitOfMeasure'"
                [name]="'unitOfMeasure'"
                [placeholder]="parentTransName + 'characteristicSpecificationValue.unitOfMeasure' | translate"
                [required]="false"
                formControlName="unitOfMeasure"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [valueFrom] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'valueFrom'"
                [name]="'valueFrom'"
                [placeholder]="parentTransName + 'characteristicSpecificationValue.valueFrom' | translate"
                [required]="false"
                formControlName="valueFrom"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [valueTo] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'valueTo'"
                [name]="'valueTo'"
                [placeholder]="parentTransName + 'characteristicSpecificationValue.valueTo' | translate"
                [required]="false"
                formControlName="valueTo"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [rangeInterval] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'rangeInterval'"
                [name]="'rangeInterval'"
                [placeholder]="parentTransName + 'characteristicSpecificationValue.rangeInterval' | translate"
                [required]="false"
                formControlName="rangeInterval"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [rangeStep] -->
            <div class="md:col-span-2 mb-1">
              <dot-textbox
                [id]="'rangeStep'"
                [name]="'rangeStep'"
                [placeholder]="parentTransName + 'characteristicSpecificationValue.rangeStep' | translate"
                [required]="false"
                formControlName="rangeStep"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- startDateTime -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'common.startDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                formControlName="startDateTime"
                [appendTo]="'body'"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'common.startDateTime' | translate"
                ></p-calendar>
            </div>

            <!-- endDateTime -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'common.endDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                formControlName="endDateTime"
                [appendTo]="'body'"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'common.endDateTime' | translate"
                ></p-calendar>
            </div>

          </div>
        </div>
      </form>
    </section>

  </ng-container>
  <!-- END OF MODAL BODY -->

  <!-- START OF FOOTER -->
  <ng-container class="modal-footer">

    <!-- START OF CREATE BTN -->
    <button
          type="button"
          (click)="onSubmit()"
          class="dot_btn dot_btn_primary"
          [disabled]="form.invalid"
        >
        {{'btn.update' | translate}}
    </button>
    <!-- END OF CREATE BTN -->

  </ng-container>
  <!-- END OF FOOTER -->

</dot-modal>

