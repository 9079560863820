<div class="bpmn-container relative">

  <!-- START OF MODELER CANVAS -->
  <div class="bpmn-canvas-container w-full h-full" #modeler></div>
  <div class="properties-panel-parent" #propPenal cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <!-- START OF DRAGGING HANDLE -->
    <div class="drag_handle" cdkDragHandle>
      <i class="pi pi-arrows-alt" style="font-size: 1rem"></i>
    </div>
    <!-- END OF DRAGGING HANDLE -->
  </div>
  <!-- END OF MODELER CANVAS -->

  <!-- START OF CONTROLS -->
  <div class="bpmb-controls flex flex-row gap-6">

    <!-- START OF CHOOSE [NEW/OPEN] -->
    <div class="flex flex-row gap-3">

      <!-- NEW -->
      <p-button (click)="loadNewBPMN()" icon="pi pi-trash" styleClass="p-button-rounded dot_btn_primary"></p-button>

      <!-- BPMN Settings -->
      <p-button (click)="createBPMN(bpmnName)" icon="pi pi-cog" styleClass="p-button-rounded dot_btn_primary"></p-button>

      <!-- OPEN -->
      <p-button (click)="openBPMNDialog(chooseBPMNFile)" icon="pi pi-folder-open" styleClass="p-button-rounded dot_btn_primary"></p-button>

      <!-- START OF FILE INPUT [Hidden element] -->
      <div>
        <input hidden #chooseBPMNFile type="file" (change)="handleFileInput($event)" accept=".bpmn">
      </div>
      <!-- END OF FILE INPUT -->

    </div>
    <!-- END OF CHOOSE [NEW/OPEN] -->

    <!-- START OF DOWNLOADS -->
    <div class="bpmb-controls-download flex flex-row gap-3">

      <!-- DOWNLOAD AS SVG -->
      <button (click)="saveAsSVG()" class="dot_btn dot_btn_primary">
        <span><i class="pi pi-image"></i></span>
        <span>SVG</span>
      </button>

      <button (click)="saveAsBPMN()" class="dot_btn dot_btn_primary">
        <span><i class="pi pi-download"></i></span>
        <span>BPMN</span>
      </button>

    </div>
    <!-- END OF DOWNLOADS -->

    <!-- START OF ZOOM -->
    <div class="bpmb-controls-zoom flex flex-col gap-2">

      <!-- ZOOM IN -->
      <p-button (click)="zoomIn()" icon="pi pi-search-plus" styleClass="p-button-rounded dot_btn_primary"></p-button>
      <p-button (click)="zoomOut()" icon="pi pi-search-minus" styleClass="p-button-rounded dot_btn_primary"></p-button>

    </div>
    <!-- END OF ZOOM -->

  </div>
  <!-- END OF CONTROLS -->

  <!-- START OF OVERLAY -->
  <div *ngIf="!hideOverlay" class="bpmn-container-overlay">

    <!-- START OF CONTAINER -->
    <div class="bpmn-container-overlay-container">

      <!-- START OF LOGO -->
      <div class="bpmn-container-overlay-container-logo">
        <img class="w-full" src="../../../assets/images/logo.png" >
      </div>
      <!-- END OF LOGO -->

      <!-- START OF CONTENT -->
      <div class="text-center">
        <span class="text-slate-950 text-2xl font-semibold">BPMN Modeler</span>
        <p class="text-slate-800 text-lg font-medium">Create or Open a BPMN Diagram</p>
      </div>
      <!-- END OF CONTENT -->

      <!-- START OF CHOOSE -->
      <div class="flex flex-row gap-3">
        <!-- NEW -->
        <p-button (click)="createBPMN()" icon="pi pi-plus" styleClass="p-button-rounded p-button-lg dot_btn_primary"></p-button>

        <!-- OPEN -->
        <p-button (click)="openBPMNDialog(chooseBPMNFile)" icon="pi pi-folder-open" styleClass="p-button-rounded p-button-lg dot_btn_primary"></p-button>

      </div>
      <!-- END OF CHOOSE -->

    </div>
    <!-- END OF CONTAINER -->

  </div>
  <!-- END OF OVERLAY -->
</div>


