<!-- START OF CONTAINER -->
<dialog cdkDrag cdkDragRootElement=".cdk-overlay-pane" open [ngClass]="{'modal_page': true, 'delete': isDeleteModal}">

  <!-- START OF HEADER -->
  <div class="modal_page__header">

    <!-- START OF TITLE -->
    <div class="modal_page__header__title">
      {{title | translate}}
    </div>
    <!-- END OF TITLE -->

    <!-- START OF CONTROLS -->
    <div class="modal_page__header__controls">

      <!-- EXTERNAL CONTENT -->
      <ng-content select=".modal-header-controls"></ng-content>

      <!-- START OF DRAGGING HANDLE -->
      <div class="modal_page__header__controls__drag_handle" cdkDragHandle>
        <i class="pi pi-arrows-alt"></i>
      </div>
      <!-- END OF DRAGGING HANDLE -->

      <!-- START OF CLOSE BTN -->
      <button mat-dialog-close class="modal_page__header__controls__close_btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
      </button>
      <!-- END OF CLOSE BTN -->

    </div>
    <!-- END OF CONTROLS -->

  </div>
  <!-- END OF HEADER -->

  <!-- START OF BODY -->
  <div mat-dialog-content class="modal_page__body">

    <ng-scrollbar
      #scrollable
      track="all"
      pointerEventsMethod="scrollbar"
      [autoHeightDisabled]="false"
    >

      <!-- EXTERNAL CONTENT -->
      <ng-content select=".modal-body"></ng-content>

    </ng-scrollbar>

  </div>
  <!-- END OF BODY -->

  <!-- START OF FOOTER -->
  <div class="modal_page__footer" *ngIf="footer">

    <!-- EXTERNAL CONTENT -->
    <ng-content select=".modal-footer"></ng-content>

    <!-- START OF DELETE BTN -->
    <button
        *ngIf="isDeleteModal && !disableConfirmDeleteBtn"
          type="button"
          class="dot_btn dot_btn_danger dot_btn_sm"
          (click)="confirmDelete()"
        >
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
        </span>
        <span>{{confirmDeleteBtnLabel}}</span>
    </button>
    <!-- END OF DELETE BTN -->

  </div>
  <!-- END OF FOOTER -->

</dialog>
<!-- END OF CONTAINER -->
