import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthenticationService } from '@core/authentication';
import { ToasterService } from '@core/services/toaster/toaster.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: ToasterService,
    private authenticationService: AuthenticationService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (this.authenticationService.hasToken()) {
            this.authenticationService.logout();
            setTimeout(() => {
              this.authenticationService.login();
            }, 0);
          } else {
            this.authenticationService.login();
          }
        }
        if (error.error?.errors && error.error?.errors.length) {
          // this.notificationService?.displayErrorToastr(error.error.errors[0].defaultMessage);
        } else {
          // this.notificationService?.displayErrorToastr(error.error || error.message);
        }
        return throwError(() => error);
      }),
    );
  }
}
