import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[redStar]'
})
export class RedStarDirective implements OnInit {
  @Input() shouldApply: boolean;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const star = document.createElement('span');
    star.innerHTML = '*';
    star.style.color = 'rgb(192, 57, 43)';
    star.className= 'mx-1';
    if(this.shouldApply){
      this.el.nativeElement.appendChild(star);
    }

  }
}
