import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessPermissionsService } from '@core/services/access-permissions.service';
import { map } from 'rxjs';

@Directive({
  selector: '[dotPermissions]'
})
export class DotPermissionsDirective {

  @Input() set dotPermissions (permission){

    // CHECK IF THE PERMISSIONS CACHED
    const isPermissionsCached = this.accessPermissionsService.isPermissionsCachedInLocalStorage()

    if (isPermissionsCached){

      // CHECK THE REQUIRED PERMISSION
      const access = this.accessPermissionsService.checkPermission(permission)

      // RENDER COMPONENT
      this.renderComponent(access)

    }else{

      this.accessPermissionsService.getUsersPermissions().pipe(
        map((data: any) => {

          // SAVE PERMISSIONS TO LOCAL STORAGE
          this.accessPermissionsService.savePermissionsToLocalStorage(data)

          // CHECK THE REQUIRED PERMISSION
          const access = this.accessPermissionsService.checkPermission(permission)

          // RENDER COMPONENT
          this.renderComponent(access)

        })
      )

    }

  }

  constructor(private templateRef: TemplateRef<any>,
              private vcRef: ViewContainerRef,
              private accessPermissionsService: AccessPermissionsService) {}

  renderComponent(access){
    if (access){
      this.vcRef.createEmbeddedView(this.templateRef)
    }else{
      this.vcRef.clear()
    }
  }

}
