import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { DmnModelerSettingsComponent } from './dmn-modeler-settings/dmn-modeler-settings.component';
import { PagedLastBaseComponent } from '@shared/components/base/Paged-list.base.component';
// import * as DmnJs from 'dmn-js/dist/dmn-modeler.development.js'
import * as DmnJs from './package/dmn-js/dist/dmn-modeler.development.js'

@Component({
  selector: 'dot-dmn-modeler',
  templateUrl: './dot-dmn-modeler.component.html',
  styleUrls: ['./dot-dmn-modeler.component.scss']
})
export class DotDmnModelerComponent extends PagedLastBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'pocs.dmn.';

  // DMN CONTAINER
  @ViewChild('modeler', { static: true }) private modeler: ElementRef;

  // EVENTS
  @Output() changeTabName: EventEmitter<any> = new EventEmitter<any>()

  // DMN INSTANCEs
  private dmnJS: DmnJs;
  private canvas: any
  private activeView: any
  private activeEditor: any

  // CONTROLS
  hideOverlay = false
  hideControls = false
  dmnName: string = 'New DMN'

  displayDMNFile(dmnFile) {

    // IMPORT LOADED DMN [XML] FILE
    this.dmnJS.importXML(dmnFile)
      .then(({ warnings }) => {
        if (warnings.length) {
          console.log(warnings);
        }

        // fetch currently active view
        this.activeView = this.dmnJS.getActiveView();

        // apply initial logic in DRD view
        if (this.activeView.type === 'drd') {
          // Select Active Editor
          this.activeEditor = this.dmnJS.getActiveViewer();

          // access active editor components
          this.canvas = this.activeEditor.get('canvas');

          // zoom to fit full viewport
          this.canvas.zoom('fit-viewport');

          // ChangeTab name
          this.changeTabName.emit(this.dmnName);

          // Hide Overlay
          this.hideOverlay = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  createDmn(data?){
    const settingsDialog = this.dialogService.open(DmnModelerSettingsComponent, {
      width: '600px',
      disableClose: true,
      data: data? {name: data, isUpdateForm: true} : {isUpdateForm: false}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: string)=>{
      if(res){

        // Change DMN Name
        this.dmnName = res

        // Update the tab name / Loading the empty diagram
        data? this.changeTabName.emit(res): this.loadNewDMN()

      }
    })
  }

  loadNewDMN(){
    const dmnFilePath = './assets/docs/newDiagram.dmn';
    const xhr = new XMLHttpRequest();
    xhr.open('GET', dmnFilePath, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const dmnXML = xhr.responseText;
        this.displayDMNFile(dmnXML);
      }
    };
    xhr.send();
  }

  // CHOOSE DMN FILE
  openDMNDialog(openDMN: HTMLInputElement): void {
    openDMN.click();
  }

  handleFileInput(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const dmnXML = e.target.result;
      this.dmnName = file.name.split('.')[0]
      this.displayDMNFile(dmnXML);
    };
    reader.readAsText(file);
  }


  zoomIn() {
    this.activeEditor.get("zoomScroll").stepZoom(1)
  }

  zoomOut() {
    this.activeEditor.get("zoomScroll").stepZoom(-1)
  }

  saveAsSVG(){
    this.activeEditor.saveSVG((err, svg) => {
      if (!err) {
        const link = document.createElement('a');
        link.href = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
        link.download = `${this.dmnName}.svg`;
        link.target = '_blank';
        link.click();
      }
    });
  }
  saveAsDMN(){
    this.dmnJS.saveXML((err, xml) => {
      if (!err) {
        const link = document.createElement('a');
        link.href = 'data:application/dmn20-xml;charset=utf-8,' + encodeURIComponent(xml);
        link.download = `${this.dmnName}.dmn`;
        link.target = '_blank';
        link.click();
      }
    });
  }

  ngAfterContentInit(): void {
    // ADD DMN FILE TO CONTAINER
    this.dmnJS = new DmnJs({
      container: this.modeler.nativeElement,
      keyboard: {
        bindTo: window
      }
    });

    this.dmnJS.on('views.changed', (event) => {

      // Load Views
      const activeView = event.activeView;

      // TOGGLE CONTROLS VIEW
      this.hideControls = (activeView.type === 'decisionTable' || activeView.type === 'literalExpression');
    })

    this.dmnJS.on('element.click', (event) => {
      console.log(event)
    })

  }

  ngOnDestroy(): void {
    // DESTROY DMN INSTANCE
    this.dmnJS.destroy();
  }

}
