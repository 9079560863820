import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/authentication';
import { User } from '@shared/interfaces';
import { LayoutService } from '../../layout.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss'],
})
export class UserSettingComponent implements OnInit {

  public userDetails: User = {} as User;
  items: MenuItem[] | undefined;


  constructor(
          private authenticationService: AuthenticationService,
          private layoutService: LayoutService
          ) {}

  getUserDetails() {
    this.userDetails.email = this.authenticationService.getUser()?.email;
    this.userDetails.name = this.authenticationService.getUser()?.name;
  }

  logOut() {
    this.authenticationService.logout();
  }

  openSetting() {
    this.authenticationService.openSettingModal();
  }

  ngOnInit(): void {

    // LOAD USER DATA
    this.getUserDetails();

    // SET SETTINGs MENU ITEMs
    this.items = [
      {
          label: 'Profile',
          icon: 'pi pi-fw pi-user',
      },
      {
          label: 'Help',
          icon: 'pi pi-fw pi-key',
      },
      {
          separator: true
      },
      {
          label: 'Logout',
          icon: 'pi pi-fw pi-sign-out',
          command: () => this.logOut()
      }
    ];

  }

  getHelpLink(){
    this.layoutService.gitFile('helpLinkFile.txt').subscribe({
      next: (data: any) => {
        window.open(data.link, '_blank');
      },
      error: (error) => {
        console.log(error)
      },
    });
  }

}
