import { Component, Input } from '@angular/core';

@Component({
  selector: 'dot-image',
  templateUrl: './dot-image.component.html',
  styleUrls: ['./dot-image.component.scss']
})
export class DotImageComponent {

  // INPUTS
  @Input() src: any = null
  @Input() styles: {
                    width: string,
                    height: string,
                    padding: string,
                    margin: string,
                    borderRadius: string
                  }

}
