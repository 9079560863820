import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { StorageService } from '@core/services/storage';
import { OAuthService } from 'angular-oauth2-oidc';
import { User } from '@shared/interfaces';

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
  /** Behavior Subject for authentication status */
  public authentication = new BehaviorSubject<boolean>(false);

  /** Subject for token status */
  public tokenExpire = new Subject<boolean>();
  public openSetting = new Subject<boolean>();

  public tokenExpired$ = this.tokenExpire.asObservable();
  public openSetting$ = this.openSetting.asObservable();


  /**
   * Check if the user already have tokens and userProfile
   * @return Observable<boolean> indicated if the user Authenticated or not
   */
  get isAuthenticated(): boolean {
    return !!this.oauthService.getIdentityClaims();
  }

  constructor(private storageService: StorageService, private oauthService: OAuthService) {}

  public unAuthenticateUser() {
    this.authentication.next(false);
    this.storageService.setUser(null);
    this.storageService.setUserTypes(null);
    this.storageService.empty();
  }

  login() {
    this.oauthService.initLoginFlow();
  }

  logout() {
    this.unAuthenticateUser();
    this.oauthService.logOut();
  }

  getUser(): User {
    return this.oauthService.getIdentityClaims() as User;
  }

  hasToken(): boolean {
    return this.oauthService.hasValidIdToken();
  }
  openSettingModal(){
    this.openSetting.next(true);
  }

  ngOnDestroy() {
    this.authentication.complete();
  }
}
