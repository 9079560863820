<div class="image_form__upload">
  <div class="image_form__upload__input">
    <input type="file" hidden accept="image/*" (change)="handleImageUpload($event)" #imageUploadInput />
  </div>
  <div class="image_form__upload__preview">
    <img class="image_form__upload__preview__image" [src]="src" #previewImage />
  </div>
  <div class="image_form__upload__icon" (click)="openImageUploadDialog(imageUploadInput)">
    <span [inlineSVG]="'./assets/icons/camera.svg'"></span>
  </div>
</div>
