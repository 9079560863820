<dot-modal
  [title]="('btn.delete' | translate) + ' ' + (this.data.title | translate)"
  (modalClosed)="closeDialog()"
  [isDeleteModal]="true"
  (confirmDeleteFn)="onSubmit()"
  [confirmDeleteBtnLabel]="'btn.delete' | translate"
>
  <!-- START OF MODAL BODY -->
  <ng-container class="modal-body">
    <p>
      {{ "messages.deleteMessage" | translate }}   {{ this.data.title | translate }} 
      {{ this.data.name | translate }}  ?
    </p>
  </ng-container>
  <!-- END OF MODAL BODY -->
</dot-modal>
