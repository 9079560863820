import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as BpmnJS from 'bpmn-js/dist/bpmn-modeler.production.min.js';
import { BpmnPropertiesPanelModule, BpmnPropertiesProviderModule, CamundaPlatformPropertiesProviderModule } from 'bpmn-js-properties-panel';
import { BpmnModelerSettingsComponent } from './bpmn-modeler-settings/bpmn-modeler-settings.component';
import { PagedLastBaseComponent } from '@shared/components/base/Paged-list.base.component';

// Properties Panel Extensions
import { camunda_descriptors } from './properties-panel-extension/descriptors/camunda_descriptors';

@Component({
  selector: 'dot-bpmn-modeler',
  templateUrl: './dot-bpmn-modeler.component.html',
  styleUrls: ['./dot-bpmn-modeler.component.scss']
})
export class DotBpmnModelerComponent extends PagedLastBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'pocs.bpmn.';

  // BPMN CONTAINER
  @ViewChild('modeler', { static: true }) private modeler: ElementRef;
  @ViewChild('propPenal', { static: true }) private propPenal: ElementRef;

  // EVENTS
  @Output() changeTabName: EventEmitter<any> = new EventEmitter<any>()


  // BPMN INSTANCE
  private bpmnJS: BpmnJS;
  private canvas: any

  // CONTROLS
  hideOverlay = false
  bpmnName: string = 'New BPMN'

  displayBPMNfile(bpmnFile){

    // IMPORT LOADED BPMN [XML] FILE
    this.bpmnJS.importXML(bpmnFile);

    // CHECK IF THE IMPORT IS DONE WITH NO ERRORS
    this.bpmnJS.on('import.done', ({ error }) => {
      if (!error) {

        // CANVAS VIEW
        this.canvas = this.bpmnJS.get('canvas')

        // FIT VIEW
        this.canvas.zoom('fit-viewport');

        // ChangeTab name
        this.changeTabName.emit(this.bpmnName)

        // Hide Overlay
        this.hideOverlay = true
      }
    });
  }

  createBPMN(data?){
    const settingsDialog = this.dialogService.open(BpmnModelerSettingsComponent, {
      width: '600px',
      disableClose: true,
      data: data? {name: data, isUpdateForm: true} : {isUpdateForm: false}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: string)=>{
      if(res){

        // Change BPMN Name
        this.bpmnName = res

        // Update the tab name / Loading the empty diagram
        data? this.changeTabName.emit(res): this.loadNewBPMN()

      }
    })
  }

  saveAsSVG() {
    this.bpmnJS.saveSVG((err, svg) => {
      if (!err) {
        const link = document.createElement('a');
        link.href = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
        link.download = `${this.bpmnName}.svg`;
        link.target = '_blank';
        link.click();
      }
    });
  }

  saveAsBPMN() {
    this.bpmnJS.saveXML((err, xml) => {
      if (!err) {
        const link = document.createElement('a');
        link.href = 'data:application/bpmn20-xml;charset=utf-8,' + encodeURIComponent(xml);
        link.download = `${this.bpmnName}.bpmn`;
        link.target = '_blank';
        link.click();
      }
    });
  }

  handleFileInput(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const bpmnXML = e.target.result;
      this.bpmnName = file.name.split('.')[0]
      this.displayBPMNfile(bpmnXML);
    };
    reader.readAsText(file);
  }

  loadNewBPMN() {
    const bpmnFilePath = './assets/docs/newDiagram.bpmn';
    const xhr = new XMLHttpRequest();
    xhr.open('GET', bpmnFilePath, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const bpmnXML = xhr.responseText;
        this.displayBPMNfile(bpmnXML);
      }
    };
    xhr.send();
  }

  // CHOOSE BPMN FILE
  openBPMNDialog(openBPMN: HTMLInputElement): void {
    openBPMN.click();
  }

  zoomIn() {
    this.bpmnJS.get("zoomScroll").stepZoom(1)
  }

  zoomOut() {
    this.bpmnJS.get("zoomScroll").stepZoom(-1)
  }

  ngAfterContentInit(): void {
    this.bpmnJS = new BpmnJS({
      container: this.modeler.nativeElement,
      propertiesPanel: {
        parent: this.propPenal.nativeElement
      },
      additionalModules: [
        BpmnPropertiesPanelModule,
        BpmnPropertiesProviderModule,
        CamundaPlatformPropertiesProviderModule
      ],
      moddleExtensions: {
        camunda: camunda_descriptors
      }
    });
  }

  ngOnDestroy(): void {
    // DESTROY BPMN INSTANCE
    this.bpmnJS.destroy();
  }

}
