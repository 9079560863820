import { environment } from '../../../../environments/environment';

const domain = `${environment.APIUrl}`;
const serviceCatalog = `${domain}/serviceCatalog`;
const configDataManager = `${domain}/configDataManager`;

export const PROVISION_API_URLS = {
  ATOMIC_SERVICES: `${serviceCatalog}/serviceSpecification/`,
  COMPOSITE_SERVICES: `${serviceCatalog}/customerFacingServiceSpecComposite/`,
  SOURCE_SYSTEMS: `${configDataManager}/prvSourceSystem`,
  SOURCE_SYSTEMS_UPDATE: (id: number) =>
    `${configDataManager}/prvSourceSystem/${id}`,
  ACTION_MANAGEMENT: `${configDataManager}/prvOrderAction/`,
  NETWORK_ELEMENTS: `${configDataManager}/prvNe/`,
  PARAMETERS_MANGAGMENT: `${configDataManager}/prvParam/`,
  PRV_SERVICES: `${configDataManager}/prvAtomicService/`,
  GET_SOURCE_SYSTEMS_BY_NAME: (name: string) =>
    `${configDataManager}/prvSourceSystem/SourceSystemName/${name}`,
  BUSINESS_RULE: `${configDataManager}/businessRule/`,
  SERVICE_CATALOG_PARAMETERS: `${configDataManager}/prvParam/findServiceCatalogParameters`,
  PRV_ATOMIC_SERVICES: `${configDataManager}/prvAtomicService/atomics`,
  CREATE_RANKED_PRV_SERVICES: `${configDataManager}/prvAtomicService/createAtomicServiceWithRankGeneration/`,
  UPDATE_RANKED_PRV_SERVICES: `${configDataManager}/prvAtomicService/updateAtomicServiceWithRankGeneration/`,
  PRV_PARAMS: `${configDataManager}/prvParam/`,
  PENDING_FAILED_ORDERS: (status) => `${domain}/PRV-SERVICE-ORCHESTRATOR/prvOrderExecution/pendingFailedOrders?status=${status}`,
  JUMP_OR_RE_EXECUTE_ORDERS: `${domain}/prvServiceOrchestrator/api/reExecuteOrJump`,
};
