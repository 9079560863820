<!-- START OF SERVICE CATALOG DESIGNER-->
<section class="flex flex-row gap-3 w-full justify-between service_catalog_designer relative">

  <!-- START OF DRAWING AREA -->
  <div  #treeHolderContainer class="service_catalog_designer__design_area w-full h-full">

    <!-- START OF TREE ROOT -->
    <div class="flex flex-row gap-5" [ngClass]="{'h-full items-center justify-center': !checkIfContainersMatched(treeHolderContainer,teeHolder)}">
      <div #teeHolder>
        <div *ngFor="let item of nestedItems">
          <service-catalog-tree (removeNode)="removeNode($event)" [catalogTree]="nestedItems" [item]="item"></service-catalog-tree>
        </div>
      </div>
    </div>
    <!-- END OF TREE ROOT -->

  </div>
  <!-- END OF DRAWING AREA -->

  <!-- START OF COMPONENT PANEL -->
  <aside class="service_catalog_designer__components_panel absolute top-2 right-0 h-auto" cdkDrag cdkDragRootElement=".cdk-overlay-pane">

    <!-- START OF COMPONENT PANEL HEADER -->
    <div class="service_catalog_designer__components_panel__header flex flex-row items-center justify-between absolute top-0 left-0 w-full">
      <!-- START OF PANEL TITLE -->
      <div class="service_catalog_designer__components_panel__header__title font-semibold flex flex-row gap-2">
        <span><i class="pi pi-sitemap"></i></span>
        <span>Components</span>
      </div>
      <!-- END OF PANEL TITLE -->

      <!-- START OF PANEL CONTROLs -->
      <div class="service_catalog_designer__components_panel__header__controls">

        <!-- START OF DRAGGING HANDLE -->
        <div class="service_catalog_designer__components_panel__header__controls__drag_handle" cdkDragHandle>
          <i class="pi pi-arrows-alt"></i>
        </div>
        <!-- END OF DRAGGING HANDLE -->

      </div>
      <!-- END OF PANEL CONTROLs -->

    </div>
    <!-- END OF COMPONENT PANEL HEADER -->

    <!-- START OF PANEL CONTAINER -->
    <p-accordion class="w-full" [multiple]="true" [activeIndex]="[0]">

      <!-- START OF ATOMIC SERVICES PANEL [servicesSpecsList] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Services</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF SPECIFICATIONS HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-2 items-start" [sortablejs]="servicesSpecsList" [sortablejsOptions]="servicesSpecsListCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF ATOMIC SERVICES ITEM -->
              <div *ngFor="let item of servicesSpecsList" class="service_spec_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF SPECIFICATIONS ICON -->
                <div class="service_spec_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                </div>
                <!-- END OF SPECIFICATIONS ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF ATOMIC SERVICES ITEM -->

            </div>
            <!-- END OF SPECIFICATIONS HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF ATOMIC SERVICES PANEL [servicesSpecsList] -->

      <!-- START OF ATOMIC SERVICES PANEL [RFSsList] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">RFSs</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-2 items-start" [sortablejs]="RFSsList" [sortablejsOptions]="rfsCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF RFS ITEM -->
              <div *ngFor="let item of RFSsList" class="service_spec_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF ICON -->
                <div class="service_spec_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                </div>
                <!-- END OF ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF RFS ITEM -->

            </div>
            <!-- END OF HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF ATOMIC SERVICES PANEL [RFSsList] -->

      <!-- START OF ATOMIC SERVICES PANEL [entitySpecCharUseList] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Service Spec. Char Use</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-2 items-start" [sortablejs]="entitySpecCharUseList" [sortablejsOptions]="entitySpecCharUseCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF RFS ITEM -->
              <div *ngFor="let item of entitySpecCharUseList" class="service_spec_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF ICON -->
                <div class="service_spec_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                </div>
                <!-- END OF ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF RFS ITEM -->

            </div>
            <!-- END OF HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF ATOMIC SERVICES PANEL [entitySpecCharUseList] -->

      <!-- START OF ATOMIC SERVICES PANEL [entitySpecCharValueUseList] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Service Spec. Values</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3 items-start" [sortablejs]="entitySpecCharValueUseList" [sortablejsOptions]="entitySpecCharValueUseCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF RFS ITEM -->
              <div *ngFor="let item of entitySpecCharValueUseList" class="service_spec_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF ICON -->
                <div class="service_spec_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                </div>
                <!-- END OF ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF RFS ITEM -->

            </div>
            <!-- END OF HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF ATOMIC SERVICES PANEL [entitySpecCharValueUseList] -->

      <!-- START OF ATOMIC SERVICES PANEL [characteristicSpecValueList] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Characteristic Spec. Value</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3 items-start" [sortablejs]="characteristicSpecValueList" [sortablejsOptions]="characteristicSpecValueCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF RFS ITEM -->
              <div *ngFor="let item of characteristicSpecValueList" class="service_spec_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF ICON -->
                <div class="service_spec_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                </div>
                <!-- END OF ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF RFS ITEM -->

            </div>
            <!-- END OF HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF ATOMIC SERVICES PANEL [characteristicSpecValueList] -->

    </p-accordion>
    <!-- END OF PANEL CONTAINER -->


  </aside>
  <!-- END OF COMPONENT PANEL -->


  <!-- START OF CONTROLS -->
  <div class="service_catalog_designer__controls flex flex-row gap-6">

    <!-- START OF CHOOSE [NEW/OPEN] -->
    <div class="flex flex-row gap-3">

      <!-- NEW -->
      <p-button (click)="clearServiceCatalog()" icon="pi pi-trash" styleClass="p-button-rounded dot_btn_primary"></p-button>

      <!-- SERVICE CATALOG SETTINGs -->
      <p-button (click)="createServiceCatalogDiagram(serviceCatalogName)" icon="pi pi-cog" styleClass="p-button-rounded dot_btn_primary"></p-button>

      <!-- OPEN -->
      <p-button (click)="openServiceCatalogDialog(serviceCatalogFileInput)" icon="pi pi-folder-open" styleClass="p-button-rounded dot_btn_primary"></p-button>

      <!-- START OF FILE INPUT [Hidden element] -->
      <div>
        <input hidden #serviceCatalogFileInput type="file" (change)="handleFileInput($event)" accept=".serviceCatalog">
      </div>
      <!-- END OF FILE INPUT -->

    </div>
    <!-- END OF CHOOSE [NEW/OPEN] -->

    <!-- START OF DOWNLOADS -->
    <div class="service_catalog_designer__controls__download flex flex-row gap-3">

      <!-- DOWNLOAD AS JSON -->
      <button (click)="downloadServiceCatalog()" class="dot_btn dot_btn_primary">
        <span><i class="pi pi-download"></i></span>
        <span>{{'btn.download' | translate}}</span>
      </button>

      <!-- SUBMIT TREE -->
      <button (click)="onSubmit()" class="dot_btn dot_btn_primary">
        <span><i class="pi pi-send"></i></span>
        <span>{{'btn.submit' | translate}}</span>
      </button>

    </div>
    <!-- END OF DOWNLOADS -->

  </div>
  <!-- END OF CONTROLS -->

  <!-- START OF OVERLAY -->
  <div *ngIf="!hideOverlay" class="service_catalog_designer__overlay">

    <!-- START OF CONTAINER -->
    <div class="service_catalog_designer__overlay__container">

      <!-- START OF LOGO -->
      <div class="service_catalog_designer__overlay__container__logo">
        <span [inlineSVG]="'./assets/images/navbar_logo.svg'"></span>
      </div>
      <!-- END OF LOGO -->

      <!-- START OF CONTENT -->
      <div class="text-center">
        <span class="text-slate-950 text-2xl font-semibold">{{parentTransName + 'title' | translate}}</span>
        <p class="text-slate-800 text-lg font-medium">{{parentTransName + 'subTitle' | translate}}</p>
      </div>
      <!-- END OF CONTENT -->

      <!-- START OF CHOOSE -->
      <div class="flex flex-row gap-3">
        <!-- NEW -->
        <p-button (click)="createServiceCatalogDiagram()" icon="pi pi-plus" styleClass="p-button-rounded p-button-lg dot_btn_primary"></p-button>

        <!-- OPEN -->
        <p-button (click)="openServiceCatalogDialog(serviceCatalogFileInput)" icon="pi pi-folder-open" styleClass="p-button-rounded p-button-lg dot_btn_primary"></p-button>

      </div>
      <!-- END OF CHOOSE -->

    </div>
    <!-- END OF CONTAINER -->

  </div>
  <!-- END OF OVERLAY -->

</section>
<!-- END OF SERVICE CATALOG DESIGNER -->

