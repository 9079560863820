<div class="h-full sidebar flex">
  <!-- container -->

  <aside class="flex flex-col items-center text-gray-700 shadow h-full">
    <!-- Side Nav Bar-->

    <!-- START OF LOGO -->
    <div class="sidebar__logo mx-auto mt-3 mb-4">
      <a [routerLink]="'/home'" class="mx-auto">
        <span [inlineSVG]="'./assets/images/sidebar_logo.svg'"></span>
      </a>
    </div>
    <!-- END OF LOGO -->

    <ul>
      <!-- Items Section -->
      <ng-container *ngFor="let item of mainItems">
        <li
          class="hover:bg-[#1c212c]/10 w-full group-active:scale-95 self-stretch flex items-center space-x-2 transition-all duration-200 text-sm"
        >
          <a
            (click)="onDisplaySideBar(item)"
            [ngClass]="{
              'bg-[#1c212c]/10 text-[#004aad] active': title === item?.label
            }"
            class="h-16 px-6 flex justify-center items-center w-full"
          >
            <!-- <mat-icon>group</mat-icon> -->
            <span class="sidebar-icon"
              [inlineSVG]="'./assets/icons/menu/' + item?.icon"
            ></span>
            <div class="menu-title">
              {{ "menu." + item?.label | translate }}
            </div>
          </a>
        </li>
      </ng-container>

    </ul>
  </aside>

  <!-- START OF SIDE MENU -->
  <app-sidebar-menu
    [menuItem]="menuItem"
    (deSeclectMenu)="deSelectMenu()"
  ></app-sidebar-menu>
  <!-- END OF SIDE MENU -->
</div>
