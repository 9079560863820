import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';
import { Inventory_Management_API_URLS } from '@shared/utilities/service-config/inventory-management-service-config';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-update-rfs-data',
  templateUrl: './update-rfs-data.component.html',
  styleUrls: ['./update-rfs-data.component.scss']
})
export class UpdateRfsDataComponent extends AppBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'provision.serviceCatalogDesigner.';

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1)

  // HOLDERs
  resourceSpecifications = []
  status = [
    {name: 'ACTIVE'},
    {name: 'INACTIVE'},
  ]

  constructor(
    private dialogRef: MatDialogRef<UpdateRfsDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      name: [null],
      description: [null],
      status: [null],
      resourceSpecification: [null],
      startDateTime: [null],
      endDateTime: [null],
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(){
    // Return with name
    this.closeDialog(this.form.value)
  }

  getResourcesSpecs(){

    // ACTIVATE LOADING ANIMATION
    this.isLoading = true

    this.httpService
    .getData(Inventory_Management_API_URLS.RESOURCES_SPECS)
    .pipe(finalize(() => this.isLoading = false))
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {

        // FILL RESOURCES LIST
        this.resourceSpecifications = data;

        // PATCH FORM
        if(this.data.isUpdateForm) this.patchForm()
      }
    });
  }

  patchForm(){
    this.form.patchValue({
      name: this.data?.item?.data?.name,
      description: this.data?.item?.data?.description,
      status: {name: this.data?.item?.data?.status},
      resourceSpecification: this.resourceSpecifications?.find((el) => el?.id === this.data?.item?.data?.resourceSpecification[0]?.id),
      startDateTime: this.data?.item?.data?.validFor?.startDateTime,
      endDateTime: this.data?.item?.data?.validFor?.endDateTime,
    });
  }

  ngOnInit(): void {

    // LOAD RESOURCE SPECS
    this.getResourcesSpecs()

  }

}
