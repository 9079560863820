import { Component } from '@angular/core';
import { LanguageService } from '@core/services/language.service';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from 'src/config/auth-config';
import { Router } from '@angular/router';
import { AccessPermissionsService } from '@core/services/access-permissions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  authConfig: AuthConfig = authConfig;
  isLoading = true

  constructor(
    private oauthService: OAuthService,
    private languageService:LanguageService,
    private router: Router,
    private accessPermissionsService: AccessPermissionsService
  ) {
    this.configure();
    this.languageService.initLang()

    const subscription = this.oauthService.events.subscribe((res: OAuthEvent) => {

      if (this.oauthService.hasValidIdToken()) {

        // LOAD PERMISSIONS
        this.accessPermissionsService.getUsersPermissions().subscribe({
          next: (permissions) => {
            // SAVE PERMISSIONS TO LOCAL STORAGE
            this.accessPermissionsService.savePermissionsToLocalStorage(permissions);

            // Hide loading Page
            this.isLoading = false;

            // REDIRECT
            (location.pathname === '/')? this.router.navigate(['/home']) :this.router.navigate([location.pathname]);

          },
          error: (error) => {

            if (error.status === 502){
              // Hide loading Page
              this.isLoading = false

              // PERMISSIONS NOT LOADED [BAD GATEWAY]
              this.router.navigate(['/errors/bad-gateway'])
            }else if (error.status === 403){
              // Hide loading Page
              this.isLoading = false

              // PERMISSIONS NOT LOADED [FORBIDDEN]
              this.router.navigate(['/errors/forbidden'])
            }else if (error.status === 500) {
              // Hide loading Page
              this.isLoading = false

              // PERMISSIONS NOT LOADED [INTERNAL SERVER ERROR]
              this.router.navigate(['/errors/internal-error'])
            }else{

              // Hide loading Page
              this.isLoading = false
              
              // Display loading Page
              this.router.navigate(['/errors/internal-error'])
            }

          }
        });

        // Unsubscribe from the event after the first occurrence
        subscription.unsubscribe();
      }else if (res.type === "discovery_document_load_error"){

        // Hide loading Page
        this.isLoading = false

        // CERTIFICATION NOT ACCEPTED
        this.router.navigate(['/errors/certification-error'])

      }else{

        // Display loading Page
        this.isLoading = true

      }

    });
  }

  public login() {
    this.oauthService.initLoginFlow();
  }

  public logoff() {
    this.oauthService.logOut();
  }

  private configure() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // Load Discovery Document and then try to login the user
    // this.oauthService.loadDiscoveryDocumentAndTryLogin();
    // this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.oauthService.loadDiscoveryDocumentAndLogin({
        onTokenReceived : () => {
            this.oauthService.setupAutomaticSilentRefresh();
        }
    });
    // this.oauthService.loadDiscoveryDocument().then(() => {
    //   // This method just tries to parse the token(s) within the url when
    //   // the auth-server redirects the user back to the web-app
    //   // It dosn't send the user the the login page
    // });
  }

}
