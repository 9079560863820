import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessPermissionsService } from '@core/services/access-permissions.service';

@Injectable({
  providedIn: 'root'
})
export class AccessPermissionsGuard implements CanActivate {

  constructor(private accessPermissionsService: AccessPermissionsService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {

      // Retrieve the required permissions for the route from the route data
      const requiredPermissions = route.data.requiredPermissions;

      // CHECK IF THE PERMISSIONS CACHED
      const isPermissionsCached = this.accessPermissionsService.isPermissionsCachedInLocalStorage()

      if(isPermissionsCached){

        // CHECK THE REQUIRED PERMISSION
        const access = this.accessPermissionsService.checkPermission(requiredPermissions)

        // REDIRECT USER
        return access? true: this.router.navigate(['/errors/not-authorized'])

      }else{

        // PERMISSIONS NOT LOADED [INTERNAL SERVER ERROR]
        this.router.navigate(['/errors/internal-error'])

      }

    }

}
