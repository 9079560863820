import { Component, Input, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-vertical-card-item',
  templateUrl: './vertical-card-item.component.html',
  styleUrls: ['./vertical-card-item.component.scss']
})
export class VerticalCardItemComponent {

    // Fetch elements from Parrent
    @Input() elements: {id: number, title: string, icon: string, url: string}
    @ViewChild('iconContainer', {static: false}) iconContainer: ElementRef;

    
    
    // Create the SVG Icon
    createSvgElement(svgString: string): SVGElement {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
      return svgDoc.documentElement as unknown as SVGElement;
    }

    // Add Icon to DOM
    ngAfterViewInit() {
      const svgElement = this.createSvgElement(this.elements.icon);
      svgElement.setAttribute('style', 'width: 50px; height: 50px');
      this.iconContainer.nativeElement.appendChild(svgElement);
    }
    
}
