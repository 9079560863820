/**
 * holds all application routes that could be replaced in future with out cracking
 * the app
 */
export const config = {
  home: {
    name: 'home',
    route: '/home',
  },
  individual: {
    name: 'create-customer',
    route: '/create-customer',
  },
  employees: {
    name: 'create-employee',
    route: '/create-employee',
  },
  customerDetails: {
    name: 'customer-details',
    route: '/customer-details',
    editCustomerDetails: {
      name: 'edit',
      route: '/customer-details/edit',
    },
  },
  billing: {
    name: 'billing',
    route: '/billing',
    billCycle: {
      name: 'bill-cycle',
      route: '/billing/bill-cycle',
    },
    billCycleConfiguration: {
      name: 'bill-configuration',
      route: '/billing/bill-configuration',
    },
    configureMarketingMessages: {
      name: 'configure-marketing-messages',
      route: '/billing/configure-marketing-messages',
    },
    taxRoundingMethod: {
      name: 'tax-rounding-method',
      route: '/billing/tax-rounding-method',
    },
    configureBillMedium: {
      name: 'configure-bill-medium',
      route: '/billing/configure-bill-medium',
    },
    configureBillingTax: {
      name: 'configure-billing-tax',
      route: '/billing/configure-billing-tax',
    },
    configureAggregatorAttributes: {
      name: 'configure-aggregator-attributes',
      route: '/billing/configure-aggregator-attributes',
    },
    billingDiscountAttributes: {
      name: 'bill-discount-attributes',
      route: '/billing/bill-discount-attributes',
    },
    billingSystemConfiguration: {
      name: 'billing-system-configuration',
      route: '/billing/billing-system-configuration',
    },
    billingDiscountSchema: {
      name: 'bill-discount-schema',
      route: '/billing/bill-discount-schema',
    },
    configureBillRunAccountSegments: {
      name: 'configure-bill-run-account-segments',
      route: '/billing/configure-bill-run-account-segments',
    },
    taxPlan: {
      name: 'tax-plan',
      route: '/billing/tax-plan',
    },
    configueBillingProcessAutomation: {
      name: 'configue-billing-process-automation',
      route: '/billing/configue-billing-process-automation',
    },
    applyFixedTaxOnInvoiceLevel: {
      name: 'apply-fixed-tax-on-invoice-level',
      route: '/billing/apply-fixed-tax-on-invoice-level',
    },
    configureExchangeRateBasedBillCurrency: {
      name: 'configure-exchange-rate-based-on-currency',
      route: '/billing/configure-exchange-rate-based-on-currency',
    },
    configureRoundingForDifferentCurrencies: {
      name: 'configure-rounding-for-different-currencies',
      route: '/billing/configure-rounding-for-different-currencies',
    },
    queryAndDownloadCustomerBill: {
      name: 'query-and-download-bills',
      route: '/billing/query-and-download-bills',
    },
    executeRegularBill: {
      name: 'execute-regular-bill',
      route: '/billing/execute-regular-bill',
    },
    enumeration: {
      name: 'enumeration',
      route: '/billing/enumeration',
    },
    enumerationValues: {
      name: 'enumeration-value',
      route: '/billing/enumeration-value',
    },
  },
  runBilling: {
    name: 'run-billing',
    route: '/run-billing',
  },
  customerManagement: {
    name: 'customer-management',
    route: '/customer-management',
    batchSuspensionResume: {
      name: 'batch-suspension-resume',
      route: '/customer-management/batch-suspension-resume',
    },
    creeateCorporate: {
      name: 'create-corporate',
      route: '/customer-management/create-corporate',
    },
  },
  productCatalogue: {
    name: 'product-catalogue',
    route: '/product-catalogue',

    createOffer: {
      name: 'create-offer',
      route: '/product-catalogue/create-offer',
    },
    productCatalogueI: {
      name: 'product-catalogue-i',
      route: '/product-catalogue/product-catalogue-i',
    },
  },
  provision: {
    name: 'provision',
    route: '/provision',

    serviceCatalogDesigner: {
      name: 'service-catalog-designer',
      route: '/provision/service-catalog-designer',
    },
    pendingFailedOrders: {
      name: 'pending-failed-orders',
      route: '/provision/pending-failed-orders',
    },
    parametersManagement: {
      name: 'parameters-management',
      route: '/provision/parameters-management',
    },
    sourceSystmes: {
      name: 'source-systmes',
      route: '/provision/source-systmes',
    },
    actionManagement: {
      name: 'action-management',
      route: '/provision/action-management',
    },
    servicesManagement: {
      name: 'services-management',
      route: '/provision/services-management',
    },
    networkElementsManagement: {
      name: 'network-elements-management',
      route: '/provision/network-elements-management',
    }
  },
  inventoryManagement: {
    name: 'inventory-management',
    route: '/inventory-management',

    warehouse: {
      name: 'warehouse',
      route: '/inventory-management/warehouse',
    },
    stockItems: {
      name: 'stock-items',
      route: '/inventory-management/stock-items',
    },
    stockLevels: {
      name: 'stock-levels',
      route: '/inventory-management/stock-levels',
    },
    resources: {
      name: 'resources',
      route: '/inventory-management/resources',
    },
    resourceSpecifications: {
      name: 'resource-specifications',
      route: '/inventory-management/resource-specifications',
    },
    bathcOperation: {
      name: 'batch-operation',
      route: '/inventory-management/batch-operation',
    },
    itemTransfer: {
      name: 'item-transfer',
      route: '/inventory-management/item-transfer',
    },
    itemReceive: {
      name: 'item-receive',
      route: '/inventory-management/item-receive',
    },
    unreserve: {
      name: 'unreserve',
      route: '/inventory-management/unreserve',
    },
    reserve: {
      name: 'reserve',
      route: '/inventory-management/reserve',
    },
    warehouseTransferEligibility: {
      name: 'warehouse-transfer-eligibility',
      route: '/inventory-management/warehouse-transfer-eligibility',
    },
    warehouseCategoryTransferEligibility: {
      name: 'warehouse-category-transfer-eligibility',
      route: '/inventory-management/warehouse-category-transfer-eligibility',
    },
    changeResourceStatus: {
      name: 'change-resource-status',
      route: '/inventory-management/change-resource-status',
    },
  },
  orderManagement: {
    name: 'order-management',
    route: '/order-management',

    queryOrder: {
      name: 'query-order',
      route: '/order-management/query-order',
    },
  },
  systemManagement: {
    name: 'system-management',
    route: '/system-management',

    roles: {
      name: 'roles',
      route: '/system-management/roles',
    },
    businessRoles: {
      name: 'business-roles',
      route: '/system-management/business-roles',
    },
    attributes: {
      name: 'attributes',
      route: '/system-management/attributes',
    },
    users: {
      name: 'users',
      route: '/system-management/users',
    },
    departments: {
      name: 'departments',
      route: '/system-management/departments',
    },
    organizationUnits: {
      name: 'organization-units',
      route: '/system-management/organization-units',
    },
    systemLogs: {
      name: 'system-logs',
      route: '/system-management/system-logs',
    },
    userProfile: {
      name: 'user-profile',
      route: '/system-management/user-profile',
    },
    usersSessions: {
      name: 'users-sessions',
      route: '/system-management/users-sessions',
    },
    frontendControls: {
      name: 'frontend-controls',
      route: '/system-management/frontend-controls',

      // INTERNAL PAGES
      microservices: {
        name: 'microservices',
        route: '/system-management/frontend-controls/microservices',
      },
      pages: {
        name: 'pages',
        route: '/system-management/frontend-controls/pages',
      },
      subPages: {
        name: 'sub-pages',
        route: '/system-management/frontend-controls/sub-pages',
      },
      testTable: {
        name: 'test-table',
        route: '/system-management/frontend-controls/test-table',
      },
    },
  },
  pocs: {
    name: 'pocs',
    route: '/pocs',

    bpmnPoc: {
      name: 'bpmn-poc',
      route: '/pocs/bpmn-poc',
    },
    bpmnPocModeler: {
      name: 'bpmn-poc-modeler',
      route: '/pocs/bpmn-poc-modeler',
    },
    dmnPocModeler: {
      name: 'dmn-poc-modeler',
      route: '/pocs/dmn-poc-modeler',
    },
    drools: {
      name: 'drools',
      route: '/pocs/drools',
    },
  },
  reports: {
    name: 'reports',
    route: '/reports',

    inventorySummaryReport: {
      name: 'inventory-summary-report',
      route: '/reports/inventory-summary-report',
    },
    querySimReport: {
      name: 'query-sim-report',
      route: '/reports/query-sim-report',
    },
  },
  dunningManagement: {
    name: 'dunning-management',
    route: '/dunning-management',

    dunningEnumeration: {
      name: 'dunning-enumeration',
      route: '/dunning-management/dunning-enumeration',
    },
    dunningEnumerationValues: {
      name: 'dunning-enumeration-value',
      route: '/dunning-management/dunning-enumeration-value',
    },
    viewAllRules: {
      name: 'view-all-rules',
      route: '/dunning-management/view-all-rules',
    }
  },
  organizations: {
    name: 'organizations',
    route: '/organizations',

    organizationDetails: {
      name: 'organization-details',
      route: '/organizations/organization-details',
    },
  },
  paymentManagement: {
    name: 'payment-management',
    route: '/payment-management',

    createCashbox: {
      name: 'create-cashbox',
      route: '/payment-management/create-cashbox',
    },
    openCloseCashbox: {
      name: 'open-close-cashbox',
      route: '/payment-management/open-close-cashbox',
    },
    adjustCashbox: {
      name: 'adjust-cashbox',
      route: '/payment-management/adjust-cashbox',
    },
    archiveCashbox: {
      name: 'archive-cashbox',
      route: '/payment-management/archive-cashbox',
    },
    queryCashbox: {
      name: 'query-cashbox',
      route: '/payment-management/query-cashbox',
    },
    customerLevelPayment: {
      name: 'customer-level-payment',
      route: '/payment-management/customer-level-payment',
    },
    reverseCustomerPayment: {
      name: 'reverse-customer-payment',
      route: '/payment-management/reverse-customer-payment',
    },
    issueManualInvoice: {
      name: 'issue-manual-invoice',
      route: '/payment-management/issue-manual-invoice',
    },
    issueDisputeRequest: {
      name: 'issue-dispute-request',
      route: '/payment-management/issue-dispute-request',
    },
    balanceTransfer: {
      name: 'balance-transfer',
      route: '/payment-management/balance-transfer',
    },
  },
  suspendedCDRs: {
    name: '',
    route: '',
    aggregatorSuspendedCDRs :
    {
      name: 'aggregator-suspended-cdrs',
      route: '/aggregator-suspended-cdrs'
    },
    discountSuspendedCDRs :
    {
      name: 'discount-suspended-cdrs',
      route: '/discount-suspended-cdrs'
    },
    taxationSuspendedCDRs :
    {
      name: 'taxation-suspended-cdrs',
      route: '/taxation-suspended-cdrs'
    }
  },
  billingMonitoring: {
    name: '',
    route: '',
    billAnalytics :
    {
      name: 'bill-analytics',
      route: '/bill-analytics'
    },
    billExecutor :
    {
      name: 'bill-executor',
      route: '/bill-executor'
    }
  }
};
