import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { UserSettingComponent } from './header/user-setting/user-setting.component';
import { MaterialModule } from '@shared/material/material.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { HeaderSearchBarComponent } from './header/header-search-bar/header-search-bar.component';
import { SidebarMenuComponent } from './sidebar/sidebar-menu/sidebar-menu.component';
import { AppLangComponent } from './header/app-lang/app-lang.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';


@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    MainLayoutComponent,
    UserSettingComponent,
    HeaderSearchBarComponent,
    SidebarMenuComponent,
    AppLangComponent,
    BlankLayoutComponent
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    MainLayoutComponent,
    UserSettingComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
  ],
})
export class LayoutModule { }
