<!-- START OF PRODUCT CATALOG DESIGNER-->
<section class="flex flex-row gap-3 w-full justify-between product_catalog_designer relative">

  <!-- START OF DRAWING AREA -->
  <div  #offersTreeHolderContainer class="product_catalog_designer__design_area w-full h-full">

    <!-- START OF TREE ROOT -->
    <div class="flex flex-row gap-5" [ngClass]="{'h-full items-center justify-center': !checkIfContainersMatched(offersTreeHolderContainer,offersTreeHolder)}">
      <div #offersTreeHolder>
        <div *ngFor="let item of nestedItems">
          <product-catalog-tree (removeNode)="removeNode($event)" [catalogTree]="nestedItems" [item]="item" [productOffers]="productOffers"></product-catalog-tree>
        </div>
      </div>
    </div>
    <!-- END OF TREE ROOT -->

  </div>
  <!-- END OF DRAWING AREA -->

  <!-- START OF COMPONENT PANEL -->
  <aside class="product_catalog_designer__components_panel absolute top-2 right-0 h-auto" cdkDrag cdkDragRootElement=".cdk-overlay-pane">

    <!-- START OF COMPONENT PANEL HEADER -->
    <div class="product_catalog_designer__components_panel__header flex flex-row items-center justify-between absolute top-0 left-0 w-full">
      <!-- START OF PANEL TITLE -->
      <div class="product_catalog_designer__components_panel__header__title font-semibold flex flex-row gap-2">
        <span><i class="pi pi-sitemap"></i></span>
        <span>Components</span>
      </div>
      <!-- END OF PANEL TITLE -->

      <!-- START OF PANEL CONTROLs -->
      <div class="product_catalog_designer__components_panel__header__controls">

        <!-- START OF DRAGGING HANDLE -->
        <div class="product_catalog_designer__components_panel__header__controls__drag_handle" cdkDragHandle>
          <i class="pi pi-arrows-alt"></i>
        </div>
        <!-- END OF DRAGGING HANDLE -->

      </div>
      <!-- END OF PANEL CONTROLs -->

    </div>
    <!-- END OF COMPONENT PANEL HEADER -->

    <!-- START OF PANEL CONTAINER -->
    <p-accordion class="w-full" [multiple]="true" [activeIndex]="[0]">

      <!-- START OF PRODUCT OFFERS PANEL [productOffersList] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Product Offers</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF OFFERS HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-2" [sortablejs]="productOffersList" [sortablejsOptions]="productsOffersCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF PRODUCT OFFER ITEM -->
              <div *ngFor="let offer of productOffersList" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF OFFER ICON -->
                <div class="product_offer_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + offer?.icon"></span>
                </div>
                <!-- END OF OFFER ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{offer?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF PRODUCT OFFER ITEM -->

            </div>
            <!-- END OF OFFERS HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF PRODUCT OFFERS PANEL [productOffersList] -->

      <!-- START OF PRODUCT SPECIFICATIONS PANEL [productOffersSpecifications] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Product Specifications</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF SPECIFICATIONS HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersSpecifications" [sortablejsOptions]="productsSpecsCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF PRODUCT SPECIFICATIONS ITEM -->
              <div *ngFor="let spec of productOffersSpecifications" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF SPECIFICATIONS ICON -->
                <div class="product_offer_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + spec?.icon"></span>
                </div>
                <!-- END OF SPECIFICATIONS ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{spec?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF PRODUCT SPECIFICATIONS ITEM -->

            </div>
            <!-- END OF SPECIFICATIONS HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF PRODUCT SPECIFICATIONS PANEL [productOffersSpecifications] -->

      <!-- START OF PRODUCT SPECIFICATIONS PANEL [productOfferingRelationship] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Product Relationships</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF ITEM HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOfferingRelationship" [sortablejsOptions]="productsRelationCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF PRODUCT  ITEM -->
              <div *ngFor="let spec of productOfferingRelationship" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF ITEM ICON -->
                <div class="product_offer_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + spec?.icon"></span>
                </div>
                <!-- END OF ITEM ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{spec?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF PRODUCT ITEM -->

            </div>
            <!-- END OF SPECIFICATIONS HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF PRODUCT SPECIFICATIONS PANEL [productOfferingRelationship] -->


      <!-- START OF PRODUCT PRICES PANEL [productOffersPrices] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Product Prices</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
        <ng-template pTemplate="content">

          <!-- START OF SECTIONS CONTAINER -->
          <div class="flex flex-col w-full gap-4">

            <!-- START OF BASIE PRICES COMPONENTS -->
            <div class="flex flex-col w-full">
              <!-- START OF HEADER -->
              <div class="pb-2 border-b-2 border-slate-600">
                <span class="font-semibold capitalize">base price</span>
              </div>
              <!-- END OF HEADER -->

              <!-- START OF HOLDER -->
              <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersPrices" [sortablejsOptions]="productsPricesCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

                <!-- START OF ITEM -->
                <div *ngFor="let item of productOffersPrices" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                  <!-- START OF PRICES ICON -->
                  <div class="product_offer_item__icon">
                    <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                  </div>
                  <!-- END OF PRICES ICON -->

                  <!-- START OF NAME -->
                  <div class="text-center">
                    <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                  </div>
                  <!-- END OF NAME -->

                </div>
                <!-- END OF ITEM -->

              </div>
              <!-- END OF HOLDER -->

            </div>
            <!-- END OF BASIE PRICES COMPONENTS -->

            <!-- START OF ALTERATIONS COMPONENTS -->
            <div class="flex flex-col w-full">
              <!-- START OF HEADER -->
              <div class="pb-2 border-b-2 border-slate-600">
                <span class="font-semibold capitalize">alteration prices</span>
              </div>
              <!-- END OF HEADER -->

              <!-- START OF HOLDER -->
              <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersPriceAlterations" [sortablejsOptions]="productOffersPriceAlterationsCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

                <!-- START OF ITEM -->
                <div *ngFor="let item of productOffersPriceAlterations" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                  <!-- START OF PRICES ICON -->
                  <div class="product_offer_item__icon">
                    <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                  </div>
                  <!-- END OF PRICES ICON -->

                  <!-- START OF NAME -->
                  <div class="text-center">
                    <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                  </div>
                  <!-- END OF NAME -->

                </div>
                <!-- END OF ITEM -->

              </div>
              <!-- END OF HOLDER -->

            </div>
            <!-- END OF ALTERATIONS COMPONENTS -->

          </div>
          <!-- END OF SECTIONS CONTAINER -->

        </ng-template>
        <!-- END OF PANEL CONTENT -->

      </p-accordionTab>
      <!-- END OF PRODUCT PRICES PANEL [productOffersPrices] -->

      <!-- START OF PRODUCT PANEL [productOffersSpecsResourceSpec] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Resource Specifications</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersSpecsResourceSpec" [sortablejsOptions]="productOffersSpecsResourceSpecCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF ITEM -->
              <div *ngFor="let item of productOffersSpecsResourceSpec" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF PRICES ICON -->
                <div class="product_offer_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                </div>
                <!-- END OF PRICES ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF ITEM -->

            </div>
            <!-- END OF HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF PRODUCT PANEL [productOffersSpecsResourceSpec] -->

      <!-- START OF PRODUCT PANEL [productOffersSpecsAtomicServiceSpecs, productOffersSpecsCompositeServiceSpecs] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
        <ng-template pTemplate="header">
            <div class="flex align-items-center">
                <i class="pi pi-calendar mr-2"></i>
                <span class="vertical-align-middle">Service Specifications</span>
            </div>
        </ng-template>
        <!-- END OF PANEL HEADER -->

        <!-- START OF PANEL CONTENT -->
        <ng-template pTemplate="content">

          <!-- START OF SECTIONS CONTAINER -->
          <div class="flex flex-col w-full gap-4">

            <!-- START OF ATOMIC COMPONENTS -->
            <div class="flex flex-col w-full">
              <!-- START OF HEADER -->
              <div class="pb-2 border-b-2 border-slate-600">
                <span class="font-semibold">Atomic Specifications</span>
              </div>
              <!-- END OF HEADER -->

              <!-- START OF HOLDER -->
              <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersSpecsAtomicServiceSpecs" [sortablejsOptions]="productOffersSpecsAtomicServiceSpecsCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

                <!-- START OF ITEM -->
                <div *ngFor="let item of productOffersSpecsAtomicServiceSpecs" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                  <!-- START OF PRICES ICON -->
                  <div class="product_offer_item__icon">
                    <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                  </div>
                  <!-- END OF PRICES ICON -->

                  <!-- START OF NAME -->
                  <div class="text-center">
                    <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                  </div>
                  <!-- END OF NAME -->

                </div>
                <!-- END OF ITEM -->

              </div>
              <!-- END OF HOLDER -->

            </div>
            <!-- END OF ATOMIC COMPONENTS -->

            <!-- START OF COMPOSITE COMPONENTS -->
            <div class="flex flex-col w-full">
              <!-- START OF HEADER -->
              <div class="pb-2 border-b-2 border-slate-600">
                <span class="font-semibold">Composite Specifications</span>
              </div>
              <!-- END OF HEADER -->

              <!-- START OF HOLDER -->
              <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersSpecsCompositeServiceSpecs" [sortablejsOptions]="productOffersSpecsCompositeServiceSpecsCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

                <!-- START OF ITEM -->
                <div *ngFor="let item of productOffersSpecsCompositeServiceSpecs" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                  <!-- START OF PRICES ICON -->
                  <div class="product_offer_item__icon">
                    <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                  </div>
                  <!-- END OF PRICES ICON -->

                  <!-- START OF NAME -->
                  <div class="text-center">
                    <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                  </div>
                  <!-- END OF NAME -->

                </div>
                <!-- END OF ITEM -->

              </div>
              <!-- END OF HOLDER -->

            </div>
            <!-- END OF COMPOSITE COMPONENTS -->

          </div>
          <!-- END OF SECTIONS CONTAINER -->

        </ng-template>
        <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF PRODUCT PANEL [productOffersSpecsAtomicServiceSpecs, productOffersSpecsCompositeServiceSpecs] -->

      <!-- START OF PRODUCT PANEL [productOffersSpecsTypesProductLines, productOffersSpecsTypesProductCategories] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
        <ng-template pTemplate="header">
            <div class="flex align-items-center">
                <i class="pi pi-calendar mr-2"></i>
                <span class="vertical-align-middle">Specifications Types</span>
            </div>
        </ng-template>
        <!-- END OF PANEL HEADER -->

        <!-- START OF PANEL CONTENT -->
        <ng-template pTemplate="content">

          <!-- START OF SECTIONS CONTAINER -->
          <div class="flex flex-col w-full gap-4">

            <!-- START OF ATOMIC COMPONENTS -->
            <div class="flex flex-col w-full">
              <!-- START OF HEADER -->
              <div class="pb-2 border-b-2 border-slate-600">
                <span class="font-semibold">Product Line</span>
              </div>
              <!-- END OF HEADER -->

              <!-- START OF HOLDER -->
              <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersSpecsTypesProductLinesCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

                <!-- START OF ITEM -->
                <div *ngFor="let item of productOffersSpecsTypesProductLines" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                  <!-- START OF PRICES ICON -->
                  <div class="product_offer_item__icon">
                    <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                  </div>
                  <!-- END OF PRICES ICON -->

                  <!-- START OF NAME -->
                  <div class="text-center">
                    <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                  </div>
                  <!-- END OF NAME -->

                </div>
                <!-- END OF ITEM -->

              </div>
              <!-- END OF HOLDER -->

            </div>
            <!-- END OF ATOMIC COMPONENTS -->

            <!-- START OF COMPOSITE COMPONENTS -->
            <div class="flex flex-col w-full">
              <!-- START OF HEADER -->
              <div class="pb-2 border-b-2 border-slate-600">
                <span class="font-semibold">Product Catalog</span>
              </div>
              <!-- END OF HEADER -->

              <!-- START OF HOLDER -->
              <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersSpecsTypesProductCategories" [sortablejsOptions]="productOffersSpecsTypesProductCategoriesCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

                <!-- START OF ITEM -->
                <div *ngFor="let item of productOffersSpecsTypesProductCategories" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                  <!-- START OF PRICES ICON -->
                  <div class="product_offer_item__icon">
                    <span [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
                  </div>
                  <!-- END OF PRICES ICON -->

                  <!-- START OF NAME -->
                  <div class="text-center">
                    <span class="font-semibold break-all text-xs block text-slate-900">{{item?.name}}</span>
                  </div>
                  <!-- END OF NAME -->

                </div>
                <!-- END OF ITEM -->

              </div>
              <!-- END OF HOLDER -->

            </div>
            <!-- END OF COMPOSITE COMPONENTS -->

          </div>
          <!-- END OF SECTIONS CONTAINER -->

        </ng-template>
        <!-- END OF PANEL CONTENT -->

      </p-accordionTab>
      <!-- END OF PRODUCT PANEL [productOffersSpecsTypesProductLines, productOffersSpecsTypesProductCategories] -->

      <!-- START OF PRODUCT PRICES PANEL [productOffersSalesChannels] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Sales Channels</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF PRICES HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersSalesChannels" [sortablejsOptions]="productOffersSalesChannelsCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF PRODUCT PRICES ITEM -->
              <div *ngFor="let price of productOffersSalesChannels" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF PRICES ICON -->
                <div class="product_offer_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + price?.icon"></span>
                </div>
                <!-- END OF PRICES ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{price?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF PRODUCT PRICES ITEM -->

            </div>
            <!-- END OF PRICES HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF PRODUCT PRICES PANEL [productOffersSalesChannels] -->

      <!-- START OF PRODUCT PRICES PANEL [productOffersProductCatalog] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Product Catalog</span>
              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <!-- START OF PRICES HOLDER -->
            <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-3" [sortablejs]="productOffersProductCatalog" [sortablejsOptions]="productOffersProductCatalogCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

              <!-- START OF PRODUCT PRICES ITEM -->
              <div *ngFor="let price of productOffersProductCatalog" class="product_offer_item flex flex-col gap-2 items-center justify-center mt-3 p-2 md:col-span-1">

                <!-- START OF PRICES ICON -->
                <div class="product_offer_item__icon">
                  <span [inlineSVG]="'./assets/icons/catalogs/' + price?.icon"></span>
                </div>
                <!-- END OF PRICES ICON -->

                <!-- START OF NAME -->
                <div class="text-center">
                  <span class="font-semibold break-all text-xs block text-slate-900">{{price?.name}}</span>
                </div>
                <!-- END OF NAME -->

              </div>
              <!-- END OF PRODUCT PRICES ITEM -->

            </div>
            <!-- END OF PRICES HOLDER -->


          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF PRODUCT PRICES PANEL [productOffersProductCatalog] -->

      <!-- START OF PRODUCT OFFERS PANEL [preDefinedProductOffersList] -->
      <p-accordionTab>
        <!-- START OF PANEL HEADER -->
          <ng-template pTemplate="header">
              <div class="flex flex-row justify-between align-items-center w-full">

                <!-- START OF MENU TITLE -->
                <div class="flex align-items-center">
                  <i class="pi pi-calendar mr-2"></i>
                  <span class="vertical-align-middle">Pre-Defined Offers</span>
                </div>
                <!-- END OF MENU TITLE -->

                <!-- START OF CONTROLS -->
                <div>
                  <span (click)="loadPreDefinedProductOffers($event)">
                    <i class="pi pi-refresh" [ngClass]="{'pi-spin': isLoading}"></i>
                  </span>
                </div>
                <!-- END OF CONTROLS -->

              </div>
          </ng-template>
          <!-- END OF PANEL HEADER -->

          <!-- START OF PANEL CONTENT -->
          <ng-template pTemplate="content">

            <div class="h-80 overflow-y-scroll absolute top-1 left-1 right-1 py-0 px-1 pb-2" (scroll)="onScroll($event)" >

              <!-- START OF OFFERS HOLDER -->
              <div class="grid gap-x-2 text-sm grid-cols-1 md:grid-cols-2" [sortablejs]="preDefinedProductOffersList" [sortablejsOptions]="productsOffersCloneOptions" [sortablejsCloneFunction]="cloneCustomization">

                <!-- START OF PRODUCT OFFER ITEM -->
                <div *ngFor="let offer of preDefinedProductOffersList; let i = index" class="product_offer_item relative flex flex-col gap-2 items-center justify-center mt-2 p-2 md:col-span-1">

                  <!-- START OF DOWNLOAD OFFER OVERLAY -->
                  <div *ngIf="!offer?.data?.downloaded" class="product_offer_item__overlay">
                    <div class="product_offer_item__overlay__btn">
                      <p-button (click)="getPreDefinedProductOffer(offer, i)" icon="pi pi-arrow-circle-down" styleClass="p-button-rounded p-button-lg dot_btn_primary"></p-button>
                    </div>
                  </div>
                  <!-- END OF DOWNLOAD OFFER OVERLAY -->

                  <!-- START OF OFFER ICON -->
                  <div class="product_offer_item__icon">
                    <span [inlineSVG]="'./assets/icons/catalogs/' + offer?.icon"></span>
                  </div>
                  <!-- END OF OFFER ICON -->

                  <!-- START OF NAME -->
                  <div class="text-center">
                    <span class="font-semibold break-all text-xs block text-slate-900">{{offer?.name}}</span>
                  </div>
                  <!-- END OF NAME -->

                </div>
                <!-- END OF PRODUCT OFFER ITEM -->

              </div>
              <!-- END OF OFFERS HOLDER -->

              <!-- START OF LOADER CONTAINER -->
              <div *ngIf="IS_LOADING_OFFERS" class="relative w-full h-12 bg-white">
                <span class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                  Loading
                </span>
              </div>
              <!-- END OF LOADER CONTAINER -->

            </div>

          </ng-template>
          <!-- END OF PANEL CONTENT -->
      </p-accordionTab>
      <!-- END OF PRODUCT OFFERS PANEL [preDefinedProductOffersList] -->


    </p-accordion>
    <!-- END OF PANEL CONTAINER -->

  </aside>
  <!-- END OF COMPONENT PANEL -->


  <!-- START OF CONTROLS -->
  <div class="product_catalog_designer__controls flex flex-row gap-6">

    <!-- START OF CHOOSE [NEW/OPEN] -->
    <div class="flex flex-row gap-3">

      <!-- NEW -->
      <p-button (click)="clearProductCatalog()" icon="pi pi-trash" styleClass="p-button-rounded dot_btn_primary"></p-button>

      <!-- Product Catalog Settings -->
      <p-button (click)="createProductCatalogDiagram(productCatalogName)" icon="pi pi-cog" styleClass="p-button-rounded dot_btn_primary"></p-button>

      <!-- OPEN -->
      <p-button (click)="openProductCatalogDialog(productCatalogFileInput)" icon="pi pi-folder-open" styleClass="p-button-rounded dot_btn_primary"></p-button>

      <!-- START OF FILE INPUT [Hidden element] -->
      <div>
        <input hidden #productCatalogFileInput type="file" (change)="handleFileInput($event)" accept=".catalog">
      </div>
      <!-- END OF FILE INPUT -->

    </div>
    <!-- END OF CHOOSE [NEW/OPEN] -->

    <!-- START OF DOWNLOADS -->
    <div class="product_catalog_designer__controls__download flex flex-row gap-3">

      <!-- DOWNLOAD AS JSON -->
      <button (click)="downloadProductCatalog()" class="dot_btn dot_btn_primary">
        <span><i class="pi pi-download"></i></span>
        <span>{{'btn.download' | translate}}</span>
      </button>

      <!-- SUBMIT TREE -->
      <button (click)="onSubmit()" class="dot_btn dot_btn_primary">
        <span><i class="pi pi-send"></i></span>
        <span>{{'btn.submit' | translate}}</span>
      </button>

    </div>
    <!-- END OF DOWNLOADS -->

  </div>
  <!-- END OF CONTROLS -->

  <!-- START OF OVERLAY -->
  <div *ngIf="!hideOverlay" class="product_catalog_designer__overlay">

    <!-- START OF CONTAINER -->
    <div class="product_catalog_designer__overlay__container">

      <!-- START OF LOGO -->
      <div class="product_catalog_designer__overlay__container__logo">
        <span [inlineSVG]="'./assets/images/navbar_logo.svg'"></span>
      </div>
      <!-- END OF LOGO -->

      <!-- START OF CONTENT -->
      <div class="text-center">
        <span class="text-slate-950 text-2xl font-semibold">{{parentTransName + 'title' | translate}}</span>
        <p class="text-slate-800 text-lg font-medium">{{parentTransName + 'subTitle' | translate}}</p>
      </div>
      <!-- END OF CONTENT -->

      <!-- START OF CHOOSE -->
      <div class="flex flex-row gap-3">
        <!-- NEW -->
        <p-button (click)="createProductCatalogDiagram()" icon="pi pi-plus" styleClass="p-button-rounded p-button-lg dot_btn_primary"></p-button>

        <!-- OPEN -->
        <p-button (click)="openProductCatalogDialog(productCatalogFileInput)" icon="pi pi-folder-open" styleClass="p-button-rounded p-button-lg dot_btn_primary"></p-button>

      </div>
      <!-- END OF CHOOSE -->

    </div>
    <!-- END OF CONTAINER -->

  </div>
  <!-- END OF OVERLAY -->

</section>
<!-- END OF PRODUCT CATALOG DESIGNER -->

