import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';

@Component({
  selector: 'app-update-characteristic-specification-value-data',
  templateUrl:
    './update-characteristic-specification-value-data.component.html',
  styleUrls: [
    './update-characteristic-specification-value-data.component.scss',
  ],
})
export class UpdateCharacteristicSpecificationValueDataComponent extends AppBaseComponent {

  // Translation Object [Parent Node]
  parentTransName = 'provision.serviceCatalogDesigner.';

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1);

  constructor(
    private dialogRef: MatDialogRef<UpdateCharacteristicSpecificationValueDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.loadForm({
      valueType: [null],
      isDefault: [null],
      value: [null],
      unitOfMeasure: [null],
      valueFrom: [null],
      valueTo: [null],
      rangeInterval: [null],
      rangeStep: [null],
      startDateTime: [null],
      endDateTime: [null],
    });
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit() {
    // Return with name
    this.closeDialog(this.form.value);
  }

  ngOnInit(): void {
    // PATCH FORM
    if (this.data.isUpdateForm) this.patchForm();
  }

  patchForm() {
    this.form.patchValue({
      valueType: this.data?.item?.data?.valueType,
      isDefault: this.data?.item?.data?.isDefault,
      value: this.data?.item?.data?.value,
      unitOfMeasure: this.data?.item?.data?.unitOfMeasure,
      valueFrom: this.data?.item?.data?.valueFrom,
      valueTo: this.data?.item?.data?.valueTo,
      rangeInterval: this.data?.item?.data?.rangeInterval,
      rangeStep: this.data?.item?.data?.rangeStep,
      startDateTime: this.data?.item?.data?.validFor?.startDateTime,
      endDateTime: this.data?.item?.data?.validFor?.endDateTime,
    });
  }
}
