
<!-- START OF HEADER -->
<header  class="h-14 fixed z-1 flex items-center top_header" isRtl>

  <!-- START OF HEADER ITEMS CONTAINER -->
  <div class="top_header__container flex flex-row items-center justify-between gap-x-3.5 mx-3">

    <!-- START OF LOGO -->
    <a [routerLink]="'/home'" class="top_header__container__logo desktop:mr-15" >
      <span [inlineSVG]="'./assets/images/navbar_logo.svg'"></span>
    </a>
    <!-- END OF LOGO -->

    <!-- START OF CONTROLS -->
    <div class="flex flex-row gap-3 items-center top_header__container__controls">

      <!-- START OF LANGUAGE COMPONENT -->
      <app-lang></app-lang>
      <!-- END OF LANGUAGE COMPONENT -->

      <!-- START OF USER PROFILE COMPONENT -->
      <app-user-setting ></app-user-setting>
      <!-- END OF USER PROFILE COMPONENT -->
    </div>
    <!-- END OF CONTROLS -->

  </div>
  <!-- START OF HEADER ITEMS CONTAINER -->

</header>
<!-- END OF HEADER -->
