import { GLOBAL_DATE_TIME_FORMATE } from '../../utilities/defines';
import {
  Component,
  OnInit,
  forwardRef,
  AfterContentChecked,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormControl,
  ControlValueAccessor,
} from '@angular/forms';
import * as  moment from 'moment';
import {
  GLOBAL_DATE_FORMATE,
  DATE_MONTH_YEAR_FORMATE,
  DATE_NAME_FORMATE,
} from '../../utilities/defines';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

/**
 * create dropdown validator for the form control
 * @param {required: boolean} indicates the required is (true = required) or not (false = not required)
 */
export function createDatePickerValidator(required: boolean) {
  return (c: FormControl) => {
    if (required) {
      return c.value === '' ? null : true;
    } else {
      return null;
    }
  };
}

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }
}

export const MY_FORMATS = {
  parse: {
    dateInput: GLOBAL_DATE_FORMATE,
  },
  display: {
    dateInput: GLOBAL_DATE_FORMATE,
    monthYearLabel: DATE_MONTH_YEAR_FORMATE,
    dateA11yLabel: DATE_MONTH_YEAR_FORMATE,
    monthYearA11yLabel: DATE_NAME_FORMATE,
  },
};
@Component({
  selector: 'dot-date-picker',
  templateUrl: './dot-date-picker.component.html',
  styleUrls: ['./dot-date-picker.component.scss', '../common/fields.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DotDatePickerComponent),
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DotDatePickerComponent),
      multi: true,
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DotDatePickerComponent
  implements OnInit, ControlValueAccessor, AfterContentChecked
{
  /** element id attribute */
  @Input() id: string;
  /** element name attribute */
  @Input() name: string;
  /** The flag which indicates if the dropdown required or not. */
  @Input() required: boolean;
  /** the default selected value item may be recieved from user */
  @Input() selectedValue: string;
  /** the min date the user can select */
  @Input() minDate: Date;
  /** the max date the user can select */
  @Input() maxDate: Date;
  /** the date element o set its value as max */
  @Input() notAfter: any;
  /** required error message */
  @Input() requiredErrorMessage = 'validation.required';
  /** date picker label */
  @Input() label: string;
  /** The flag which indicates if the dropdown disabled or not. */
  @Input() isDisabled: boolean;
  /** to display additional error message or not based on this flag */
  @Input() displayAdditionalErrorMessage = false;
  /** the content of the additional error message */
  @Input() additionalErrorMessage = '';
  /** input appearance type */
  @Input() isLegacy = false;
  /** to readonly the input or not */
  @Input() isReadOnly = false;
  /** to show edit controls in the input or not */
  @Input() canEditInline = false;
  /** to hold the input value before change in edit mode  */
  private holdInputValue = '';
  /** init value */
  @Input() set initValue(v: string) {
    if (v) {
      this.dpValue = moment(v, GLOBAL_DATE_TIME_FORMATE).toDate();
    }
  }
  @Output() dateChanged: EventEmitter<any> = new EventEmitter<any>();
  /** on input toggled */
  @Output() toggleElement = new EventEmitter<string>();
  /** on edit value changed */
  @Output() onEditChanged = new EventEmitter<any>();
  private validateFn: any;
  private onTouchedCallback: () => {};
  private onChangeCallback = (_: any) => {};

  ngOnInit() {
    this.validateFn = createDatePickerValidator(this.required);
  }

  get dpValue(): any {
    return this.selectedValue;
  }
  set dpValue(v: any) {
    if (v !== this.selectedValue) {
      this.selectedValue = v;
      this.onChangeCallback(this.selectedValue);
    }
  }
  dateChange(v: any) {
    this.dateChanged.emit(v);
  }

  // inline edit
  closeEdit() {
    this.isReadOnly = !this.isReadOnly;
    this.selectedValue = this.holdInputValue;
  }
  toggleReadOnly(dp: any) {
    dp.open();
    this.holdInputValue = this.selectedValue;
    this.isReadOnly = !this.isReadOnly;
    this.toggleElement.emit(this.name);
  }

  submitEdit(v: any) {
    this.onEditChanged.emit(v);
  }

  isEditMode() {
    return !this.isReadOnly && this.canEditInline;
  }

  @Input() set resetInput(v: any) {
    if (v) {
      this.selectedValue = this.holdInputValue;
    }
  }
  @Input() set onCompleteEdit(v: any) {
    if (v) {
      this.isReadOnly = true;
    }
  }

  @Input() set clearInput(v: boolean) {
    if (v) {
      this.selectedValue = '';
    }
  }
  validate(c: FormControl) {
    return this.validateFn(c);
  }
  ngAfterContentChecked(): void {
    this.onChangeCallback(this.selectedValue);
  }
  writeValue(obj: any): void {
    if (obj) {
      this.dpValue = new Date(obj);
      this.onChangeCallback(this.dpValue);
    } else {
      this.dpValue = obj;
      this.onChangeCallback(this.dpValue);
    }
  }
  registerOnTouched(fn: any): void {}
  setDisabledState(isDisabled: boolean): void {}
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
}
