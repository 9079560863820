<div class="error_section
            flex
            items-center
            justify-center
            gap-6 absolute
            w-10/12">

  <!-- START OF ERROR IMAGE SECTION -->
  <div class="error_section__image">
    <img class="w-full h-full object-cover" [src]="errorImageSrc" >
  </div>
  <!-- END OF ERROR IMAGE SECTION -->

  <!-- START OF ERROR BODY -->
  <div class="error_section__body">

    <!-- START OF ERROR TITLE -->
    <div class="error_section__body__title mb-8">
      <span class="mb-2 lg:text-xl md:text-lg sm:text-base text-gray-400 font-medium block">ERROR {{errorNumber}}</span>
      <span class="block sm:text-2xl md:text-3xl lg:text-4xl text-red-500 font-bold uppercase">{{errorTitle}}</span>
    </div>
    <!-- END OF ERROR TITLE -->

    <!-- START OF ERROR DESCRIPTION -->
    <div *ngIf="hasDescription" class="mb-6 text-gray-700 font-light lg:text-lg md:text-md sm:text-sm">
      <ng-content select=".error-description"></ng-content>
    </div>
    <!-- END OF ERROR DESCRIPTION -->

    <!-- START OF LOGOUT  -->
    <div *ngIf="logoutBtn">
      <button class="dot_btn dot_btn_danger dot_btn_md"
              (click)="logOut()">Logout</button>
    </div>
    <!-- END OF LOGOUT  -->

    <!-- START OF ADDITIONAL CONTENT -->
    <div class="page_additional_content">

      <!-- EXTERNAL CONTENT -->
      <ng-content select=".page-additional-content"></ng-content>

    </div>
    <!-- END OF ADDITIONAL CONTENT -->

  </div>
  <!-- END OF ERROR BODY -->

</div>
