/** default date and time format for end points */
export const GLOBAL_DATE_TIME_FORMATE = 'YYYY-MM-dd hh:mm:ss';
/** default date format for end points */
export const GLOBAL_DATE_FORMATE = 'DD/MM/YYYY';

/** date name format */
export const DATE_NAME_FORMATE = 'MMMM YYYY';

export const DATE_MONTH_YEAR_FORMATE = 'MMM YYYY';

/** default time format for end points */
export const GLOBAL_TIME_FORMATE = 'HH:mm';

/** default time format for end points with seconds */
export const GLOBAL_TIME_FORMATE_SEC = 'HH:mm:ss';

/** default time format for end points */
export const MERIDIAN_TIME_FORMATE = 'hh:mm a';

/** default Date Month format */
export const GLOBAL_DAY_MONTH_FORMATE = 'DD MMM';

/** Date format 18 Nov 2020 */
export const GLOBAL_DATE_FORMAT_DD_MMM = 'DD MMM YYYY';

/** Date format 18 Nov 20 */
export const GLOBAL_DATE_FORMAT_DD_MMM_YY = 'DD MMM YY';

/** request data format */
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';
/** Friday, Jul 17, 2020 */
export const FULL_DATE = 'dddd, ll';

// Table Datee Transform
export const GLOBAL_TABLE_TIME_FORMATE = 'dd-MM-yyyy h:mm a';

// On Update Form Formate
export const UPDATE_FORM_DATE_FORMATE_ORIGINAL = 'DD-MM-YYYY h:mm A'
export const UPDATE_FORM_DATE_FORMATE = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const UPDATE_FORM_DATE_WOTIME_FORMATE = 'YYYY-MM-DD';

export const UNIX_MS_TIMESTAMP = 'x';

