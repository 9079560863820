import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { StorageService } from './storage';

@Injectable({
  providedIn: 'root'
})
export class AccessPermissionsService {

  constructor(private httpClient: HttpClient,
              private router: Router,
              private storageService: StorageService) {}

  // AUTHORIZATION END POINT
  AUTHORIZATION_URL = environment.authorities

  // CONSTANTS
  permissionsLocalStorageKey: string = 'PERMISSIONS'

  // GET USER PERMISSIONS
  getUsersPermissions(){
    return this.httpClient.get(this.AUTHORIZATION_URL);
  }

  // CHECK REQUESTED PERMISSION
  checkPermission(requiredPermissions): boolean{

    // LOAD PERMISSIONS FROM LOCAL STORAGE
    const permissions = this.loadPermissionsFromLocalStorage();

    // VALIDATE IF THE
    const access = permissions?.filter((permission) => ((permission.key === requiredPermissions.permission) && (permission.access === requiredPermissions.access)));
    return access.length > 0
    // return true
  }


  // SAVE USER PERMISSIONS IN LOCAL DB
  savePermissionsToLocalStorage(permissions){

    // SAVE PERMISSIONS TO LOCAL STORAGE
    this.storageService.setStorage(this.permissionsLocalStorageKey, permissions)

  }

  // LOAD USER PERMISSIONS FROM LOCAL DB
  loadPermissionsFromLocalStorage(){

    // LOAD PERMISSIONS TO LOCAL STORAGE
    const permissionsString = this.storageService.getStorage(this.permissionsLocalStorageKey)

    // CHECK IF PERMISSIONS EXISTS
    if (permissionsString) {

      // RETURN PERMISSIONS
      return permissionsString;
    }

    return [];
  }

  // CHECK IS USER PERMISSIONS CACHED IN LOCAL DB
  isPermissionsCachedInLocalStorage(){

    // LOAD PERMISSIONS TO LOCAL STORAGE
    const permissions = this.storageService.getStorage(this.permissionsLocalStorageKey)

    // CHECK IF PERMISSIONS EXISTS
    return permissions? true: false

  }
}
