import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

const REGEX_CONFIG_URL = 'config/regex-config.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {
  private regex: any;
  constructor(
    private httpClient: HttpClient,
  ) { }

  /** get all regex after application initialized */
  public getRegex(): Observable<any> {
    if (this.regex) {
      return of(this.regex);
    } else {
      return this.httpClient.get(REGEX_CONFIG_URL).pipe(tap(response => {
        this.regex = response;
      }));
    }
  }
}
