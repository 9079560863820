import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ModalComponent } from './components/modal/modal.component';
import { NgChartsModule } from 'ng2-charts';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RedStarDirective } from './components/dot-red-star-directive/dot-red-star-directive.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TranslateModule } from '@ngx-translate/core';
import { DotPageTreeCardLayoutComponent } from './layout/dot-page-tree-card-layout/dot-page-tree-card-layout.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchBoxPipe } from './pipes/search-box.pipe';
import { IsMatchDimensionsDirective } from './directives/is-match-dimensions.directive';
import {
  BpmnModelerSettingsComponent,
  ConfirmationPopupComponent,
  DotBpmnModelerComponent,
  DotBpmnViewerComponent,
  DotDatePickerComponent,
  DotLoadingComponent,
  DotModalComponent,
  DotPrimeTableComponent,
  DotSpinnerComponent,
  DotTableGridComponent,
  DotTextareaComponent,
  DotTextboxComponent,
  DotToastrComponent,
  DotTreeComponent,
  VerticalCardItemComponent,
  DotDmnModelerComponent,
  DeleteModelComponent,
} from './components';
import { DotPageCardComponent } from './layout/dot-page-card/dot-page-card.component';
import { DmnModelerSettingsComponent } from './components/dot-dmn-modeler/dmn-modeler-settings/dmn-modeler-settings.component';
import { DotProductCatalogModelerComponent } from './components/dot-product-catalog-modeler/dot-product-catalog-modeler.component';
import { ProductCatalogTreeComponent } from './components/dot-product-catalog-modeler/product-catalog-tree/product-catalog-tree.component';
import { ProductCatalogModelerSettingsComponent } from './components/dot-product-catalog-modeler/product-catalog-modeler-settings/product-catalog-modeler-settings.component';
import { UpdateOfferDataComponent } from './components/dot-product-catalog-modeler/components/update-offer-data/update-offer-data.component';
import { UpdateSpecificationDataComponent } from './components/dot-product-catalog-modeler/components/update-specification-data/update-specification-data.component';
import { UpdatePriceDataComponent } from './components/dot-product-catalog-modeler/components/update-price-data/update-price-data.component';
import { IsRtlDirective } from './directives/is-rtl.directive';
import { DotPageErrorsComponent } from './layout/dot-page-errors/dot-page-errors.component';
import { DotPermissionsDirective } from '@core/directives/dot-permissions.directive';
import { DotImageComponent } from './components/dot-image/dot-image.component';
import { DotImageUploaderComponent } from './components/dot-image-uploader/dot-image-uploader.component';
import { DotApexChartComponent } from './components/dot-apex-chart/dot-apex-chart.component';

// SORTABLE JS
import { SortablejsModule } from '@maksim_m/ngx-sortablejs';
import { NgApexchartsModule } from 'ng-apexcharts';

// PRIME-NG MODULES
import { ButtonModule } from 'primeng/button';
import { SpeedDialModule } from 'primeng/speeddial';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { KeyFilterModule } from 'primeng/keyfilter';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';
import { ChartModule } from 'primeng/chart';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { TreeModule } from 'primeng/tree';
import { AccordionModule } from 'primeng/accordion';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { TagModule } from 'primeng/tag';
import { UpdateOfferRelationshipComponent } from './components/dot-product-catalog-modeler/components/update-offer-relationship/update-offer-relationship.component';
import { DotServiceCatalogModelerComponent } from './components/dot-service-catalog-modeler/dot-service-catalog-modeler.component';
import { ServiceCatalogTreeComponent } from './components/dot-service-catalog-modeler/service-catalog-tree/service-catalog-tree.component';
import { ServiceCatalogModelerSettingsComponent } from './components/dot-service-catalog-modeler/service-catalog-modeler-settings/service-catalog-modeler-settings.component';
import { UpdateAtomicServiceDataComponent } from './components/dot-service-catalog-modeler/components/update-atomic-service-data/update-atomic-service-data.component';
import { UpdateCompositeServiceDataComponent } from './components/dot-service-catalog-modeler/components/update-composite-service-data/update-composite-service-data.component';
import { UpdateRfsDataComponent } from './components/dot-service-catalog-modeler/components/update-rfs-data/update-rfs-data.component';
import { UpdateServiceSpecCharUseDataComponent } from './components/dot-service-catalog-modeler/components/update-service-spec-char-use-data/update-service-spec-char-use-data.component';
import { UpdateServiceSpecCharValueUseDataComponent } from './components/dot-service-catalog-modeler/components/update-service-spec-char-value-use-data/update-service-spec-char-value-use-data.component';
import { UpdateCharacteristicSpecificationDataComponent } from './components/dot-service-catalog-modeler/components/update-characteristic-specification-data/update-characteristic-specification-data.component';
import { UpdateCharacteristicSpecificationValueDataComponent } from './components/dot-service-catalog-modeler/components/update-characteristic-specification-value-data/update-characteristic-specification-value-data.component';

const components = [
  DotDatePickerComponent,
  DotSpinnerComponent,
  DotTextareaComponent,
  DotTextboxComponent,
  DotToastrComponent,
  VerticalCardItemComponent,
  DotTableGridComponent,
  ModalComponent,
  DotTreeComponent,
  RedStarDirective,
  DotPageCardComponent,
  DotPageTreeCardLayoutComponent,
  DotModalComponent,
  SearchBoxPipe,
  DotBpmnViewerComponent,
  DotBpmnModelerComponent,
  DotDmnModelerComponent,
  DmnModelerSettingsComponent,
  BpmnModelerSettingsComponent,
  DotPrimeTableComponent,
  DotLoadingComponent,
  IsMatchDimensionsDirective,
  ConfirmationPopupComponent,
  DotProductCatalogModelerComponent,
  ProductCatalogTreeComponent,
  IsMatchDimensionsDirective,
  IsRtlDirective,
  ProductCatalogModelerSettingsComponent,
  UpdateOfferDataComponent,
  UpdateSpecificationDataComponent,
  UpdatePriceDataComponent,
  DotPageErrorsComponent,
  DotPermissionsDirective,
  DotImageComponent,
  DotImageUploaderComponent,
  DotApexChartComponent,
  UpdateOfferRelationshipComponent,
  DotServiceCatalogModelerComponent,
  DeleteModelComponent,
];

@NgModule({
  declarations: [
    ...components,
    ServiceCatalogTreeComponent,
    ServiceCatalogModelerSettingsComponent,
    UpdateAtomicServiceDataComponent,
    UpdateCompositeServiceDataComponent,
    UpdateRfsDataComponent,
    UpdateServiceSpecCharUseDataComponent,
    UpdateServiceSpecCharValueUseDataComponent,
    UpdateCharacteristicSpecificationDataComponent,
    UpdateCharacteristicSpecificationValueDataComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SortablejsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    ToastrModule.forRoot({
      toastComponent: DotToastrComponent,
      maxOpened: 1,
    }),
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    NgChartsModule,
    DragDropModule,
    RouterModule,
    PdfViewerModule,
    InlineSVGModule.forRoot(),
    TranslateModule,
    NgSelectModule,
    ButtonModule,
    SpeedDialModule,
    FileUploadModule,
    DropdownModule,
    TabViewModule,
    KeyFilterModule,
    BreadcrumbModule,
    TableModule,
    InputTextModule,
    DialogModule,
    PasswordModule,
    ToastModule,
    ChartModule,
    MessageModule,
    MessagesModule,
    TreeModule,
    AccordionModule,
    InputTextareaModule,
    InputSwitchModule,
    CalendarModule,
    RadioButtonModule,
    MultiSelectModule,
    PickListModule,
    TieredMenuModule,
    AvatarModule,
    AvatarGroupModule,
    TagModule,
    NgApexchartsModule,
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    NgChartsModule,
    DragDropModule,
    PdfViewerModule,
    InlineSVGModule,
    TranslateModule,
    NgSelectModule,
    ButtonModule,
    SpeedDialModule,
    FileUploadModule,
    DropdownModule,
    TabViewModule,
    KeyFilterModule,
    BreadcrumbModule,
    TableModule,
    InputTextModule,
    DialogModule,
    PasswordModule,
    ToastModule,
    ChartModule,
    MessageModule,
    MessagesModule,
    TreeModule,
    AccordionModule,
    InputTextareaModule,
    InputSwitchModule,
    CalendarModule,
    RadioButtonModule,
    MultiSelectModule,
    PickListModule,
    TieredMenuModule,
    AvatarModule,
    AvatarGroupModule,
    TagModule,
    NgApexchartsModule,
    CommonModule,
    ...components,
  ],
})
export class SharedModule {}
