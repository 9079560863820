import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';

@Component({
  selector: 'app-update-offer-relationship',
  templateUrl: './update-offer-relationship.component.html',
  styleUrls: ['./update-offer-relationship.component.scss']
})
export class UpdateOfferRelationshipComponent extends AppBaseComponent{
  // Translation Object [Parent Node]
  parentTransName = 'productCatalogue.productCatalogModeler.offerRelationshipDetailsUpdateForm.';

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1)

  productOfferingList = []

  constructor(
    private dialogRef: MatDialogRef<UpdateOfferRelationshipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      productOffering: [null],
      startDateTime: [null],
      endDateTime: [null],
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(){
    // Return with name
    this.closeDialog(this.form.value)
  }

  ngOnInit(): void {
    if(this.data.isUpdateForm){

      // SET PRODUCT OFFERS
      this.productOfferingList = this.data?.productOffers

      // Load Data
      this.form.patchValue({
        productOffering: this.productOfferingList?.find((el) => (el?.id === this.data?.element?.data?.productOffering?.id)),
        startDateTime: this.data?.element?.data?.validFor?.startDateTime,
        endDateTime: this.data?.element?.data?.validFor?.endDateTime,
      });

    }
  }
}
