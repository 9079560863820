<!-- START OF LANGUAGE MENU -->
<div class="lang_menuss">

  <p-dropdown appendTo="body" (onChange)="setActiveLang($event?.value)" [options]="listLang" [(ngModel)]="activeLang" optionLabel="name" placeholder="Select Language">

    <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="activeLang">
            <img [src]="activeLang?.flag" style="width: 18px"/>
            <div>{{ activeLang.name }}</div>
        </div>
    </ng-template>

    <ng-template let-lang pTemplate="item">
        <div class="flex align-items-center gap-2">
            <img [src]="lang?.flag" style="width: 18px"/>
            <div>{{ lang.name }}</div>
        </div>
    </ng-template>

  </p-dropdown>

</div>
<!-- END OF LANGUAGE MENU -->
