import { Component, Input } from '@angular/core';
import { PrimeIcons, MenuItem } from 'primeng/api';

@Component({
  selector: 'dot-page-card',
  templateUrl: './dot-page-card.component.html',
  styleUrls: ['./dot-page-card.component.scss'],
})
export class DotPageCardComponent {
  // INPUTS
  @Input() title = 'page title';
  @Input() isFloatPage:boolean = false;
  @Input() footer:boolean = false;

  // BreadCrumb
  @Input() breadCrumbItems: MenuItem[] | undefined;
  @Input() isBreadCrumb: boolean = true;

  breadCrumbRoot: MenuItem | undefined;

  ngOnInit() {
    this.breadCrumbRoot = { icon: PrimeIcons.HOME, routerLink: '/home' };
  }
}
