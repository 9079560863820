import { Directive, Input } from '@angular/core';


@Directive({
  selector: '[isMatchDimensions]'
})
export class IsMatchDimensionsDirective {

  /**
   * GET THE 2 HTML ELEMENTS AND CHECK THE IF THERE ARE MATCHING
   * IN WIDTH OR HEIGHT
   * @param element1 : HTML ELEMENT
   * @param element2 : HTML ELEMENT
   * @returns : Matching Result [TRUE, FALSE]
   */
  checkMatching(element1: HTMLElement, element2: HTMLElement): boolean {
    if (element1 && element2) {
      return (this.areEqualDimensions(element1.getBoundingClientRect(), element2.getBoundingClientRect()))? true: false;
    }
  }

  /**
   *
   * @param element1 : ELEMENT 1 DETAILS [ex: Width & height]
   * @param element2 : ELEMENT 2 DETAILS [ex: Width & height]
   * @returns : Matching Result [TRUE, FALSE]
   */
  private areEqualDimensions(element1, element2): boolean {
    return element1.width <= element2.width || element1.height <= element2.height;
  }

}
