<!-- START OF CONTAINER -->
<section class="page_section" [ngClass]="{'float_page': isFloatPage}">

  <!-- START OF HEADER -->
  <div class="page_section__header">

    <!-- START OF TITLE -->
    <div class="page_section__header__title">
      {{title | translate }}
    </div>
    <!-- END OF TITLE -->

    <!-- START OF CONTROLS -->
    <div class="page_section__header__controls">

      <!-- EXTERNAL CONTENT -->
      <ng-content select=".page-header-controls"></ng-content>

    </div>
    <!-- END OF CONTROLS -->

  </div>
  <!-- END OF HEADER -->

  <!-- START OF BODY -->
  <div class="page_section__body" [ngClass]="{'footer': footer}">

    <!-- START OF BREADCRUMB -->
    <div class="mb-6" *ngIf="isBreadCrumb">
      <p-breadcrumb class="max-w-full" [model]="breadCrumbItems" [home]="breadCrumbRoot"></p-breadcrumb>
    </div>
    <!-- END OF BREADCRUMB -->

    <!-- EXTERNAL CONTENT -->
    <ng-content select=".page-body"></ng-content>

  </div>
  <!-- END OF BODY -->

  <!-- START OF FOOTER -->
  <div class="page_section__footer" *ngIf="footer">

    <!-- EXTERNAL CONTENT -->
    <ng-content select=".page-footer"></ng-content>

  </div>
  <!-- END OF FOOTER -->
</section>
<!-- END OF CONTAINER -->
