import {
  Component,
  OnInit,
  OnChanges,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  AbstractControl,
  ValidationErrors,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'dot-textarea',
  templateUrl: './dot-textarea.component.html',
  styleUrls: ['../common/fields.scss', './dot-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DotTextareaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DotTextareaComponent),
      multi: true,
    },
  ],
})
export class DotTextareaComponent
  implements OnInit, ControlValueAccessor, Validator, OnChanges
{
  /** function template */
  private onTouchedCallback: () => {};
  /** element id attribute */
  @Input() id: string;

  /** element name attribute */
  @Input() name: string;

  /** element placeholder attribute */
  @Input() placeholder = '';

  /** for check if the input is required or not  */
  @Input() required = false;

  /** required error message */
  @Input() requiredErrorMessage = 'validation.required';

  /** pattern error message */
  @Input() patternErrorMessage: string;

  /** to disable the input or not */
  @Input() isDisabled = false;

  /** to readonly the input or not */
  @Input() isReadOnly = false;

  /** to show edit controls in the input or not */
  @Input() canEditInline = false;

  /** to specify the maximum characters for the input  */
  @Input() maxLength: any;

  /** to specify the minuimum characters for the input  */
  @Input() minlength: any;

  /** to display additional error message or not based on this flag */
  @Input() displayAdditionalErrorMessage = false;

  /** the content of the additional error message */
  @Input() additionalErrorMessage = '';

  @Input() set initValue(v: any) {
    if (v) {
      this.value = v;
      this.count = this.value.length;
    }
  }

  /** input appearance type */
  @Input() isLegacy = false;

  /** this attribute for preventing user to wite any key on the keyboard does not match the pattern */
  @Input() preventWriting = false;

  @Output() lostFocus: EventEmitter<void> = new EventEmitter<void>();

  /** on value changed */
  @Output() changed = new EventEmitter<string>();

  /** on input toggled */
  @Output() toggleElement = new EventEmitter<string>();

  /** on edit value changed */
  @Output() onEditChanged = new EventEmitter<any>();

  @Output() onEnterKeyPress = new EventEmitter<any>();

  /** for hold the inner value for the input */
  private innerValue: string;

  /** to differentiate between to actions 'onblur' or 'whileWriting' */
  public validationMode = '';

  /** to hold the input value before change in edit mode  */
  private holdInputValue = '';

  /** pre define actions */
  public validationModeObj = {
    onblur: 'onblur',
    whileWriting: 'whileWriting',
  };

  public count = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.validateAfterSubmit) {
      if (changes.validateAfterSubmit.currentValue === false) {
        this.validationMode = this.validationModeObj.whileWriting;
      } else {
        this.validationMode = this.validationModeObj.onblur;
      }
    }
  }

  /** function template */
  private onChangeCallback = (_: any) => {};

  /** get accessor including */
  get value(): any {
    return this.innerValue;
  }

  /** set accessor including call the onChange callback */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.count = this.innerValue.length;
      this.onChangeCallback(v);
      this.changed.emit(v);
    }
  }

  /** function template */
  public validateFn: any = () => {};

  constructor() {}

  /**
   * for component initialization
   */
  ngOnInit() {
    this.validateFn = this.createTextAreaValidator();
  }

  /**
   * on un-focus from input
   */
  onBlur() {
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
    this.onChangeCallback(this.innerValue);
    this.validationMode = this.validationModeObj.onblur;
    this.lostFocus.emit();
  }
  // inline edit
  closeEdit() {
    this.isReadOnly = !this.isReadOnly;
    this.innerValue = this.holdInputValue;
  }
  toggleReadOnly() {
    this.holdInputValue = this.innerValue;
    this.isReadOnly = !this.isReadOnly;
    this.toggleElement.emit(this.name);
  }

  submitEdit(v: any) {
    this.onEditChanged.emit(v);
  }

  isEditMode() {
    return !this.isReadOnly && this.canEditInline;
  }

  @Input() set clearInput(v: boolean) {
    if (v) {
      this.value = '';
    }
  }
  @Input() set resetInput(v: any) {
    if (v) {
      this.innerValue = this.holdInputValue;
    }
  }
  @Input() set onCompleteEdit(v: any) {
    if (v) {
      this.isReadOnly = true;
    }
  }

  //#region implementing ControlValueAccessor

  /**
   * this function form implementing ControlValueAccessor
   * responsible for Writes a new value to the element.
   * @param obj
   */
  writeValue(obj: any): void {
    this.innerValue = obj;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  //#endregion

  //#region implementing ControlValueAccessor

  /**
   * this function form implementing Validator
   * to validate the input based on our criteria
   * @param c
   */
  validate(c: AbstractControl): ValidationErrors {
    return this.validateFn(c);
  }

  //#endregion

  /**
   * custom validation for inputs
   */
  createTextAreaValidator() {
    return (c: FormControl) => {
      if (this.required) {
        return c.value === '' ? null : true;
      } else {
        return null;
      }
    };
  }

  onKeyup() {
    this.count = this.value.length;
  }
}
