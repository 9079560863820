import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';

@Component({
  selector: 'app-update-offer-data',
  templateUrl: './update-offer-data.component.html',
  styleUrls: ['./update-offer-data.component.scss']
})
export class UpdateOfferDataComponent extends AppBaseComponent{
  // Translation Object [Parent Node]
  parentTransName = 'productCatalogue.productCatalogModeler.offerDetailsUpdateForm.';

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1)

  constructor(
    private dialogRef: MatDialogRef<UpdateOfferDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      name: [null],
      description: [null],
      startDateTime: [null],
      endDateTime: [null],
      isPrimary: [null],
      isSellableStandAlone: [null],
      isBundle: false,
      sms: false,
      version: "V.0",
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(){
    // Return with name
    this.closeDialog(this.form.value)
  }

  ngOnInit(): void {
    if(this.data.isUpdateForm){
      // Load Data
      this.form.patchValue({
        name: this.data?.offer?.data?.name,
        description: this.data?.offer?.data?.description,
        startDateTime: this.data?.offer?.data?.validFor?.startDateTime,
        endDateTime: this.data?.offer?.data?.validFor?.endDateTime,
        isPrimary: this.data?.offer?.data?.isPrimary,
        isSellableStandAlone: this.data?.offer?.data?.isSellableStandAlone,
        isBundle: this.data?.offer?.data?.isBundle,
        sms: this.data?.offer?.data?.sms,
        version: this.data?.offer?.data?.version,
      });
    }
  }
}
