<div class="custom-textarea">
  <mat-form-field
    appearance="outline"
    [ngClass]="{ 'dot-readonly': isReadOnly }"
    [floatLabel]="'always'"
  >
    <mat-label *ngIf="!isLegacy">{{ placeholder }}</mat-label>
    <textarea
      cdkTextareaAutosize
      #variable="ngModel"
      matInput
      type="text"
      id="{{ id }}"
      name="{{ name }}"
      placeholder="{{ placeholder }}"
      maxlength="{{ maxLength }}"
      minlength="{{ minlength }}"
      [(ngModel)]="value"
      [disabled]="isDisabled"
      [readonly]="isReadOnly"
      (blur)="onBlur()"
      [required]="required"
      (keyup)="onKeyup()"
      autocomplete="off"
    ></textarea>
    <mat-icon matSuffix *ngIf="isEditMode()" (click)="submitEdit(value)"
      >done</mat-icon
    >
    <mat-icon matSuffix *ngIf="isEditMode()" (click)="closeEdit()"
      >close</mat-icon
    >
    <mat-icon
      matSuffix
      (click)="toggleReadOnly()"
      *ngIf="isReadOnly && canEditInline"
      >mode_edit</mat-icon
    >
    <mat-hint *ngIf="maxLength && (!isDisabled || !isReadOnly)" align="end">{{ count }}/{{ maxLength }}</mat-hint>

    <mat-error
      *ngIf="
        (!variable.valid &&
          requiredErrorMessage &&
          !value &&
          validationMode === validationModeObj.onblur) ||
        !variable.touched
      "
    >
      {{ requiredErrorMessage | translate }}
    </mat-error>

    <mat-error *ngIf="displayAdditionalErrorMessage">
      {{ additionalErrorMessage }}
    </mat-error>
  </mat-form-field>
</div>
