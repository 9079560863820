import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  languages: string[] = ['en', 'ar'];

  constructor(
    private translate: TranslateService,
    private _storageService: StorageService,
  ) {}

  initLang() {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this._storageService.getStorage('lang')) {
      browserLang = this._storageService.getStorage('lang');
    } else {
      this.setLanguage('en');
      browserLang = this.translate.getBrowserLang();
    }
    this.translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    this.translate.setDefaultLang('en');
  }
  setLanguage(lang) {
    this.translate.use(lang);
    this._storageService.setStorage('lang', lang);
    window.location.reload();
  }

  getSelectedLanguage() {
    return (
      this._storageService.getStorage('lang') || this.translate.getBrowserLang()
    );
  }

  getTransValue(value: string) {
    //  this.translate.get('dummyTranslation').toPromise().then();
    // console.log(this.translate.instant(value));
    return this.translate.instant(value);
  }
}
