import { environment } from '../../../../environments/environment';

const inventoryManagementUrl = `${environment.InventoryManagement}`;
const domainUrl = `${environment.APIUrl}`;
const keyCloackURL = `${environment.keycloackURL}`;

export const Inventory_Management_API_URLS = {
  // [00] COMMON
  FETCH_COUNTRIES: `${domainUrl}/common/country/`,
  FETCH_CITIES: `${domainUrl}/common/city/`,
  LOGGED_USER: `${domainUrl}/omniauth/omni/auth/manager/users/user-auth`,

  // [01] WAREHOUSE
  FETCH_WAREHOUSES: `${inventoryManagementUrl}/warehouse/`,
  FETCH_ORG_UNITS: `${domainUrl}/omniauth/omni/auth/manager/organization-units?supportWarehouse=true`,
  FETCH_ORG_UNITS_USERS: (orgID) =>
    `${domainUrl}/omniauth/omni/auth/manager/users/org-unit/${orgID}`,
  LOGGED_USER_WAREHOUSES_BY_ORG_UNIT: (ouID) =>
    `${inventoryManagementUrl}/warehouse/?orgUnitId=${ouID}`,

  // [02] STOCK ITEMS
  STOCK_ITEMS: `${inventoryManagementUrl}/resourceSpec/stockItem`,
  STOCK_ITEMS_SUMMARY: `${inventoryManagementUrl}/stockItem/summaryStockItem`,
  CREATE_STOCK_IN: `${inventoryManagementUrl}/resource/stockItemRequest`,
  UPDATE_RESOURCE: (ID) => `${inventoryManagementUrl}/resource/${ID}`,

  // [03] STOCK LEVELS
  STOCK_levels: `${inventoryManagementUrl}/stockItemStockLevel/`,
  QUERY_RECEIVED_ITEMS: (warehouseId, pattern) =>
    `${inventoryManagementUrl}/stockItemRequest/warehouse/${warehouseId}/stockItemStockLevel?${pattern}`,
  RECEIVED_ITEMS_ACTION: (warehouseId, itemId) =>
    `${inventoryManagementUrl}/stockItemRequest/warehouse/${warehouseId}/stockItemStockLevel/stockItemRequest/${itemId}`,

  // [04] RESOURCES
  RESOURCES_SPECS: `${domainUrl}/resourceCatalog/resourceSpecification/`,
  RESOURCES_SPECS_CHARACTERISTIC: `${domainUrl}/resourceCatalog/characteristicSpecification/characteristicSpecificationSummary`,
  RESOURCES_SPECS_VALUE_RELATION: (id) =>
    `${domainUrl}/resourceCatalog//characteristicSpecValue/byCharacteristicSpecValueId/${id}`,
  CHANGE_RESOURCE_STATUS: `${inventoryManagementUrl}/resource/stockItemRequest`,

  //  [04] Batch Operation
  BATCH_OPERATION: `${inventoryManagementUrl}/warehouse`,
  //  [05]  Query Batch Operation
  QUERY_BATCH_OPERATION: `${inventoryManagementUrl}/stockItemBatch`,
  //  [06]  Query Batch Operation
  DOWNLOAD_BATCH_FIILE: `${inventoryManagementUrl}/stockItemBatch/file`,

  // TRANSFER ITEM
  TRANSFER_ITEM: `${inventoryManagementUrl}/stockItemRequest/transfer`,
  STOCK_LOCATION: `${inventoryManagementUrl}/stockLocation`,

  FETCH_ORG_UNITES: `${keyCloackURL}/omni/auth/manager/organization-units?supportWarehouse=true`,

  UNRESERVE: (id, identityNumber, msisdnNumber) =>
    `${inventoryManagementUrl}/msisdnReservation/warehouse/${id}/msisdnReservation?identityNumber=${identityNumber}&msisdn=${msisdnNumber}`,

  // WAREHOUSE TRANSFER ELIGIBILITY
  WAREHOUSE_TRANSFER_ELIGIBILITY: `${inventoryManagementUrl}/warehouseTransferEligibility/`,
  WAREHOUSE_CATEGORY_TRANSFER_ELIGIBILITY: `${inventoryManagementUrl}/warehouseCategoryTransferEligibility/`,
  BATCH_OPERATION_ACTIONS: (warehouseId, stockItemBatchId) =>
    `${inventoryManagementUrl}/warehouse/${warehouseId}/stockItemBatch/${stockItemBatchId}`,


  // NEW APIS ENDPOINTS
  WAREHOUSE_STOCK_ITEM: (stockLocationId) =>  `${inventoryManagementUrl}/stockItem/summaryStockItem?stockLocationId=${stockLocationId}`,
  WAREHOUSE_STOCK_LEVELS_BY_STOCK_ITEM: (stockLocationId, stockItemId) =>  `${inventoryManagementUrl}/stockItemStockLevel/stockLocation/${stockLocationId}/stockItem/${stockItemId}`,
  WAREHOUSE_RESOURCES: (stockLocationId, stockLevelId, discriminator?) =>  {
    return discriminator
    ? `${inventoryManagementUrl}/resource/summary?stockItemStockLevel=${stockLevelId}&stockLocation=${stockLocationId}&discriminator=${discriminator}`
    : `${inventoryManagementUrl}/resource/summary?stockItemStockLevel=${stockLevelId}&stockLocation=${stockLocationId}`;
  },
  GET_RESOURCE_BY_WAREHOUSE_AND_PATTERN : (stockLocationId, pattern) =>  `${inventoryManagementUrl}/stockLocation/${stockLocationId}/resource?${pattern}`,
  GET_RESOURCE_BY_ID : (id) =>  `${inventoryManagementUrl}/resource/${id}`,
};
