import { Component, Input } from '@angular/core';
import { AuthenticationService } from '@core/authentication';

@Component({
  selector: 'dot-page-errors',
  templateUrl: './dot-page-errors.component.html',
  styleUrls: ['./dot-page-errors.component.scss']
})
export class DotPageErrorsComponent {

  @Input() errorImageSrc: string;
  @Input() errorNumber: number;
  @Input() errorTitle: string;
  @Input() hasDescription: boolean = true;
  @Input() logoutBtn: boolean = true;

  constructor(private authenticationService: AuthenticationService,) {}

  logOut() {
    this.authenticationService.logout();
  }

}
