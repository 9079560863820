<div class="row">
    <div class="col-12">
      <div *ngIf="title" class="{{ options.titleClass }}" [attr.aria-label]="title">
        {{ title }}
      </div>
      <div
        *ngIf="message && options.enableHtml"
        class="{{ options.messageClass }}"
        [innerHTML]="message"
      ></div>
      <div
        *ngIf="message && !options.enableHtml"
        class="{{ options.messageClass }}"
        [attr.aria-label]="message"
      >
        {{ message }}
      </div>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width.%]="width"></div>
  </div>
  