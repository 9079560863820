import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  AfterContentInit,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import {
  MatTableDataSource,
  MatColumnDef,
  MatTable,
} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { PAGESIZE, PAGE_SIZE_OPTION } from '../../utilities/defines';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'dot-table-grid',
  templateUrl: './dot-table-grid.component.html',
  styleUrls: ['./dot-table-grid.component.scss'],
})
export class DotTableGridComponent<T> implements OnInit, AfterContentInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ViewChild(MatSort) sort: MatSort;

  @Input() dataSource: MatTableDataSource<any>;
  @Input() displayedColumns: string[];
  @Input() dataLength: number;
  @Input() hasPagination = true;
  @Input() hasBoxShadow = false;
  @Input() hasFooter = false;
  @Output() changePage: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeSort: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Input() pageSizeOption = PAGE_SIZE_OPTION;
  public pageSize = PAGESIZE;

  @Input()isFilter:boolean = false;
  @Input()searchbarFilter:boolean = true;
  @Input()additionalFilter:boolean = false;
  @Input()searchLable: string = 'General Search';
  @Input()searchPlaceholder: string = 'Search by any column in below table...';

  ngOnInit() {
    if (this.hasPagination) {
      this.dataSource.paginator = this.paginator;
    } else {
      this.dataSource.paginator = null;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  ngAfterContentInit() {
    this.columnDefs.forEach((columnDef) => this.table.addColumnDef(columnDef));
  }
  changePageEvent(event: any) {
    this.changePage.emit(event);
  }
  sortChangeEvent(event: Sort) {
    this.changeSort.emit(event);
  }

  // FILTER TABLE CONTENT
  applyFilter(event: Event) {
    // const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
  }

}
