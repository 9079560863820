/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-shadow */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpHeaders,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '@core/authentication';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private oauthService: OAuthService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<
    HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>
  > {

    const headersToAppend = {};

    const token = this.oauthService.getAccessToken();

    if (token) {
      if (!req.headers.get('Authorization')) {
        headersToAppend['Authorization'] = `Bearer ${token}`
      }
    }
    if (!Object.prototype.toString.call(req.body).indexOf('FormData')) {
      headersToAppend['Content-Type'] = 'application/json'
    }
    const newRequest = req.clone({ setHeaders:headersToAppend });
    // eslint-disable-next-line consistent-return
    return next.handle(newRequest).pipe(tap(() => {}));
  }
}
