<dot-modal
  [title]="'common.confirmation' | translate"
  (modalClosed)="closeDialog()"
>
  <ng-container class="modal-body">
    <section class="px-6">
      <h2>
        {{ data.message ? "" : ("messages.paymentConfirmatioMsg" | translate) }}
      </h2>
      <p>
        Total Amount after rounding :
        {{ data.popupData.totalAmountAfterRounding }}
        {{ data.popupData.currency.symbol }}
      </p>
      <p>
        Collection fees :
        {{ data.popupData.collectionFees }} {{ data.popupData.currency.symbol }}
      </p>
      <p>
        total amount :
        {{
          data.popupData.totalAmountAfterRounding +
            data.popupData.collectionFees
        }}
        {{ data.popupData.currency.symbol }}
      </p>
    </section>
  </ng-container>
  <ng-container class="modal-footer">
    <button (click)="onSubmit()" type="button" class="dot_btn dot_btn_primary">
      {{ "paymentManagement.customerLevelPayment.payment" | translate }}
    </button>
    <button
      (click)="closeDialog()"
      type="button"
      class="dot_btn dot_btn_danger"
    >
      {{ "btn.cancel" | translate }}
    </button>
  </ng-container>
</dot-modal>
