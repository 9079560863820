<dot-modal
  [title]="parentTransName + 'title' | translate"
  (modalClosed)="closeDialog()"
  >

  <!-- START OF MODAL BODY -->
  <ng-container class="modal-body">

    <section class="px-4 mb-4">
      <form class="md:col-span-3" [formGroup]="form">
        <div class="lg:col-span-2">
          <div class="grid gap-x-4 text-sm grid-cols-1 md:grid-cols-4">

            <!-- [name] -->
            <div class="md:col-span-4 mb-1">
              <dot-textbox
                [id]="'name'"
                [name]="'name'"
                [placeholder]="parentTransName + 'name' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.ALFA_NUM"
                formControlName="name"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- [brand] -->
            <div class="md:col-span-4 mb-1">
              <dot-textbox
                [id]="'brand'"
                [name]="'brand'"
                [placeholder]="parentTransName + 'brand' | translate"
                [required]="false"
                [pKeyFilter]="REGEX_FORMATS.ALFA_NUM"
                formControlName="brand"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

            <!-- Description -->
            <div class="md:col-span-4 mb-1">
              <label class="block">{{parentTransName + 'description' | translate}}</label>
              <textarea
                rows="5" cols="30"
                class="w-full mt-1"
                [required]="false"
                [placeholder]="parentTransName + 'description' | translate"
                pInputTextarea
                formControlName="description"
                ngDefaultControl
                >
              </textarea>
            </div>

            <!-- Is Shared -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'isShared' | translate}}</label>
              <p-inputSwitch formControlName="isShared"></p-inputSwitch>
            </div>

            <!-- Status -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'status' | translate}}</label>

              <div class="flex flex-wrap gap-3">
                <div class="flex align-items-center">
                    <p-radioButton name="status" value="ACTIVE" formControlName="status" inputId="statusActive"></p-radioButton>
                    <label for="statusActive" class="ml-2">Active</label>
                </div>

                <div class="flex align-items-center">
                    <p-radioButton name="status" value="INACTIVE" formControlName="status" inputId="statusInActive"></p-radioButton>
                    <label for="statusInActive" class="ml-2">In Active</label>
                </div>

              </div>
            </div>

            <!-- startDateTime -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'startDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                formControlName="startDateTime"
                [appendTo]="'body'"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'startDateTime' | translate"
                ></p-calendar>
            </div>

            <!-- endDateTime -->
            <div class="md:col-span-2 mb-1 flex flex-col gap-1">
              <label>{{parentTransName + 'endDateTime' | translate}}</label>
              <p-calendar
                [showTime]="true"
                [showSeconds]="true"
                formControlName="endDateTime"
                [appendTo]="'body'"
                styleClass="w-full"
                [minDate]="minDate"
                [placeholder]="parentTransName + 'endDateTime' | translate"
                ></p-calendar>
            </div>

          </div>
        </div>
      </form>
    </section>

  </ng-container>
  <!-- END OF MODAL BODY -->

  <!-- START OF FOOTER -->
  <ng-container class="modal-footer">

    <!-- START OF CREATE BTN -->
    <button
          type="button"
          (click)="onSubmit()"
          class="dot_btn dot_btn_primary"
          [disabled]="form.invalid"
        >
        {{'btn.update' | translate}}
    </button>
    <!-- END OF CREATE BTN -->

  </ng-container>
  <!-- END OF FOOTER -->

</dot-modal>
