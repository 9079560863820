import { Injectable } from '@angular/core';
import { ConfigLoaderService } from '@core/config-loader/config-loader.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public regexList: string[] = [];
  constructor(private configLoaderService: ConfigLoaderService) {
    if (this.configLoaderService.getRegex) {
      this.configLoaderService.getRegex().subscribe((res) => {
        this.regexList = res;
      });
    }
  }
  getRegexByKey(key: any): string {
    return decodeURI(this.regexList[key] || '');
  }

}
