import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationPopUp } from '@shared/interfaces/confirmation-popup-interface';
import { AppBaseComponent } from '../base/app-base-component';
import { Payment_Management_API_URLS } from '@shared/utilities/service-config/payment-management-service-config';
import { ConfirmationPopUpModelData } from './Confirmation-popup.model';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent extends AppBaseComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationPopUp<ConfirmationPopUpModelData>,
  ) {
    super();
  }
  closeDialog(data?): void {
    this.dialogRef.close(data);
  }
  onSubmit() {
    const paymentItemArray = [];
    const formData = this.data.formBody;
    this.data.popupData['selectedItem']['customerBill'].forEach((el, i) => {
      const data = {
        billAmount: el.billAmount,
        billDate: el.billDate,
        billDueDate: el.billDueDate,
      };
      if (
        +formData['customerAccount'][this.data.popupData['selectedAccindex']][
          'bill'
        ][i].bilMonetaryAmount
      ) {
        paymentItemArray.push({
          ...data,
          appliedAmount:
            +formData['customerAccount'][
              this.data.popupData['selectedAccindex']
            ]['bill'][i].bilMonetaryAmount,
          customerBillID: el.id,
        });
      }
    });
    let appliedAmount = 0;
    this.data.popupData['selectedItem']['accountBalance'].forEach((el, i) => {
      const data = {
        customerBillingAccountBalanceID: el.id,
        appliedAmount: el.monetaryAmount,
      };
      if (
        +formData['customerAccount'][this.data.popupData['selectedAccindex']][
          'accountBalance'
        ][i].monetaryAmount
      ) {
        paymentItemArray.push({
          ...data,
          appliedAmount:
            appliedAmount +
            +formData['customerAccount'][
              this.data.popupData['selectedAccindex']
            ]['accountBalance'][i].monetaryAmount,
        });
      }
    });
    console.log(paymentItemArray);

    const body = {
      customerPaymentDTO: {
        Discriminator: 'CustomerPayment',
        partyRoleID: this.data.popupData.partyRoleID,
        partyCategory: this.data.popupData.partyCategory,
        accountRatingType: this.data.popupData.accountRatingType,
        customerBillingAccountID: this.data.popupData.selectedItem['id'],
        currencyID: formData['currency'].id,
        description: 'description',
        amount:
          this.data.popupData.totalAmountAfterRounding +
          this.data.popupData.collectionFees,
        remainingAmount:
          this.data.popupData.totalAmount -
          this.data.popupData.totalAmountAfterRounding,
        direction: 'INBOUND',
        status: 'REQUESTED',
        userID: 1,
        paymentItem: paymentItemArray,
        chequeDetails: {
          chequeNo: formData['bankAccNo'],
          bankName: formData['bankName'],
          bankBranch: formData['bankBBranch'],
          chequePayor: formData['chequePayor'],
          payorBankAccountNo: formData['bankAccNo'],
          chequePayee: formData['chequePayee'],
          chequeDate: formData['chequeDate'],
        },
      },
      paymentMethodDTO: {
        id: formData['paymentMethod'].id,
      },
    };
    this.httpService
      .postData(`${Payment_Management_API_URLS.PAYMENT}`, body)
      .subscribe({
        next: (res) => {
          this.closeDialog(res);
          this.notificationService.displaySuccessMessage(
            this.languageService.getTransValue('messages.paymentSuccessfully'),
          );
        },
      });
  }
}
