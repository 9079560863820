import { MenuItem } from '@shared/interfaces';
import { config } from '@shared/utilities/page-config';
import { DOT_MENU } from 'src/config/permissions/modules/menu';

export const MainItems: MenuItem[] = [

  {
    label: 'systemManagement.title',
    icon: 'system.svg',
    permissions: DOT_MENU.SYSTEM_MANAGEMENT.MENU,
    subItems: [
      {
        id: 'systemManagement_Users',
        label: 'menu.systemManagement.users',
        url: config.systemManagement.users.route,
      },
      {
        id: 'systemManagement_Roles',
        label: 'menu.systemManagement.businessRoles',
        url: config.systemManagement.businessRoles.route,
      },
      {
        id: 'systemManagement_Roles',
        label: 'menu.systemManagement.roles',
        url: config.systemManagement.roles.route,
      },
      // {
      //   id: 'systemManagement_Departments',
      //   label: 'menu.systemManagement.departments',
      //   url: config.systemManagement.departments.route,
      // },
      {
        id: 'systemManagement_OrganizationUnits',
        label: 'menu.systemManagement.organizationUnits',
        url: config.systemManagement.organizationUnits.route,
      },
      {
        id: 'systemManagement_SystemLogs',
        label: 'menu.systemManagement.systemLogs',
        url: config.systemManagement.systemLogs.route,
      },
      {
        id: 'systemManagement_UserSessions',
        label: 'menu.systemManagement.usersSessions',
        url: config.systemManagement.usersSessions.route,
      },
      // {
      //   label: 'menu.systemManagement.frontendControls.title',
      //   icon: 'product.svg',
      //   permissions: DOT_MENU.SYSTEM_MANAGEMENT.MENU,
      //   subItems: [
      //     {
      //       id: '',
      //       label: 'menu.systemManagement.frontendControls.microservices',
      //       url: config.systemManagement.frontendControls.microservices.route,
      //     },
      //     {
      //       id: '',
      //       label: 'menu.systemManagement.frontendControls.pages',
      //       url: config.systemManagement.frontendControls.pages.route,
      //     },
      //     {
      //       id: '',
      //       label: 'menu.systemManagement.frontendControls.subPages',
      //       url: config.systemManagement.frontendControls.subPages.route,
      //     },
      //     {
      //       id: 'systemManagement_Attributes',
      //       label: 'menu.systemManagement.attributes',
      //       url: config.systemManagement.attributes.route,
      //     },
      //     // {
      //     //   id: 'testTable',
      //     //   label: 'Test Table',
      //     //   url: config.systemManagement.frontendControls.testTable.route,
      //     // },
      //   ],
      // },
    ],
  },
  ];

