import { Component, OnInit } from '@angular/core';
import { MenuItem } from '@shared/interfaces';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MainItems } from './sidebar-menu/menu-items/menu-items';
import { Router } from '@angular/router';
import { AccessPermissionsService } from '@core/services/access-permissions.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public notificationCount: number;


  public title: string;
  mainItems : MenuItem[] = MainItems?.filter(menu => this.accessPermissionsService.checkPermission(menu?.permissions));

  public menuItem: MenuItem;
  constructor(
    private _NotificationService: NotificationService,
    private router:Router,
    private accessPermissionsService: AccessPermissionsService
    ) {}

  ngOnInit(): void {
    this._NotificationService.notificationCountChange().subscribe((res) => {
      this.notificationCount = res;
    });
  }

  // Display/Hid Side Bar
  onDisplaySideBar(item:MenuItem) {
    event.stopPropagation();
    document.querySelector('body').style.overflow = 'hidden';
    this.menuItem = item
    this.title = item?.label
  }

  deSelectMenu(){
    this.menuItem = undefined
  }

  getActiveMainItem(item:MenuItem){
    if(this.router.url.includes(item?.subItems[0]?.url)){
      return true
    }
    return false
  }
}
