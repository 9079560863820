import {FlatTreeControl, NestedTreeControl} from '@angular/cdk/tree';
import {Component, Input, ViewChild, Output, EventEmitter,} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource} from '@angular/material/tree';
import { treeNode } from '@shared/interfaces/tree.interface';


/** Flat node with expandable and level information */
interface flatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'dot-tree',
  templateUrl: './dot-tree.component.html',
  styleUrls: ['./dot-tree.component.scss']
})
export class DotTreeComponent {

  // INPUTES
  @Input() dataSource: treeNode[];
  @Input() leafIcon = 'widgets';
  @Input() parentCloseIcon = 'remove_circle_outline';
  @Input() parentOpenIcon = 'add_circle_outline';


  // OUTPUTS
  @Output() leafNodeAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() parentNodeAction: EventEmitter<any> = new EventEmitter<any>();


  // Re-shaping the input data
  private _transformer = (node: treeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };


  treeControl = new FlatTreeControl<flatNode>(
    node => node.level,
    node => node.expandable,
  );


  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );


  // Tree Data Source
  dataSourceTree = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  leafNodeClickEvent(data){
    /**
     * This method to triger the leaf node action
     * method requested from the holder component
     */
    this.leafNodeAction.emit(data)
  }


  parentNodeClickEvent(data){
    /**
     * This method to triger the parent node action
     * method requested from the holder component
     */
    this.parentNodeAction.emit(data)
  }


  ngOnInit(){
    // Load DataSource
    this.dataSourceTree.data = this.dataSource;
  }


  // Manage the expansion of the tree nodes.
  hasChild = (_: number, node: flatNode) => node.expandable;

}
