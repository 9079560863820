import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';
import { Product_Catalog_API_URLS } from '@shared/utilities';


@Component({
  selector: 'app-update-price-data',
  templateUrl: './update-price-data.component.html',
  styleUrls: ['./update-price-data.component.scss']
})
export class UpdatePriceDataComponent extends AppBaseComponent{


  // Translation Object [Parent Node]
  parentTransName = 'productCatalogue.productCatalogModeler.priceDetailsUpdateForm.';

  // HOLDERS
  periods = [
    {name: 'WEEKLY'},
    {name: 'MONTHLY'},
    {name: 'QUARTERLY'},
    {name: 'SEMI_ANNUALLY'},
    {name: 'ANNUALLY'},
    {name: 'YEARLY'},
  ]

  taxDefinitions = []
  tariffProfiles = []
  priorities = [
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 4},
    {value: 5},
  ]

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1)

  constructor(
    private dialogRef: MatDialogRef<UpdatePriceDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      name: [null],
      description: [null],
      unitOfMeasure: [null],
      startDateTime: [null],
      endDateTime: [null],
      price: [null],
      period: [null],
      applicationDuration: [null],
      taxDefinition: [null],
      taxDefinitionIsRate: [null],
      tariffProfile: [null],
      billCode: [null],
      rounding: [null],
      priority: [null],
      isRefundable: [null],
      isBackward: [null],
      isProrated: [null],
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  getTaxDefinitions(){
    this.httpService
    .getData(Product_Catalog_API_URLS.taxDefinition)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {

        // GET THE TAX DEFINITIONs FILTERED BY REQUIRED Discriminator
        this.taxDefinitions = data.filter((el) => el.Discriminator === ((this.form?.value?.taxDefinitionIsRate)? 'SimpleRateTaxDefinition' : 'SimpleAmountTaxDefinition'))


        // SET THE taxDefinition value
        this.form.patchValue({
          taxDefinition: this.taxDefinitions.find((el) => (el.id === this.data?.price?.data?.taxDefinition?.id)),
        });

      }
    })
  }



  getTariffProfiles(){
    this.httpService
    .getData(Product_Catalog_API_URLS.tariffProfile)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {

        // SET TARIFF PROFILE TYPE
        const tariffProfileType =  (this.data?.parent?.data?.priceType === 'Recurring')? 'RECURRING': 'ONETIME';

        // LOAD TARIFF PROFILES DEPEND ON OFFER TYPE
        this.tariffProfiles = data?.filter((el) => (el.discountType === tariffProfileType || el.discountType === 'ALL'))

        // PATCH FORM
        this.form.patchValue({
          tariffProfile: this.data?.price?.data?.tariffProfile,
        });
      }
    })
  }

  onSubmit(){

    // RETURNED BODY
    const body = {
      ...this.form.value,
      priority: this.form.value?.priority?.value
    }

    if(this.form.value.taxDefinition){

      switch(body.taxDefinition.Discriminator){
        case "SimpleAmountTaxDefinition" : {
          body.taxDefinition = {
            ...body.taxDefinition,
            amount: this.form.value?.taxDefinitionAmount,
          }
          break;
        }
        case "SimpleRateTaxDefinition" : {
          body.taxDefinition = {
            ...body.taxDefinition,
            rate: this.form.value?.taxDefinitionRate,
          }
          break;
        }
      }

    }

    if(this.form.value.tariffProfile){
      body['tariffProfile'] = this.form.value?.tariffProfile;
    }

    // Return
    this.closeDialog(body)
  }

  ngOnInit(): void {

    // LOAD THE tariff Profiles LIST
    if(this.data?.price?.data.tariffProfile) this.getTariffProfiles();

    // SET THE TAX DEFINITIONS TYPE
    if(this.data?.price?.data?.taxDefinition?.id){
      this.form.patchValue({
        taxDefinitionIsRate: (this.data?.price?.data?.taxDefinition?.Discriminator === 'SimpleRateTaxDefinition'),
      })
    }

    // LOAD TAX DEFINITION
    if(this.data?.price?.data.taxDefinition) this.getTaxDefinitions()


    if(this.data.isUpdateForm){
      // Load Data
      this.form.patchValue({
        name: this.data?.price?.data?.name,
        description: this.data?.price?.data?.description,
        unitOfMeasure: this.data?.price?.data?.unitOfMeasure,
        startDateTime: this.data?.price?.data?.validFor?.startDateTime,
        endDateTime: this.data?.price?.data?.validFor?.endDateTime,
        price: this.data?.price?.data?.price,
        billCode: this.data?.price?.data?.billCode,
        rounding: this.data?.price?.data?.rounding,
        isRefundable: this.data?.price?.data?.isRefundable,
        isBackward: this.data?.price?.data?.isBackward,
        isProrated: this.data?.price?.data?.isProrated,
        priority: {value: this.data?.price?.data?.priority},
      });

      if(this.data?.price?.data?.period && this.data.isBase){
        this.form.patchValue({
          period: {name: this.data?.price?.data?.period},
        });
      }

      if(this.data?.price?.data?.applicationDuration){
        if(this.data.isBase){
          this.form.patchValue({
            applicationDuration: {name: this.data?.price?.data?.applicationDuration},
          });
        }else{
          this.form.patchValue({
            applicationDuration: this.data?.price?.data?.applicationDuration,
          });
        }
      }

    }
  }

}
