
<table class="tree-container">
  <tbody>
    <tr>

      <!-- START ROOT POINT DESIGN -->
      <td *ngIf="item.type === 'root'" [data]="item" [sortablejs]="item.children" [sortablejsOptions]="rootPointOptions" class="tree-parent" [ngClass]="{'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">
        <div class="tree_root">
          <!-- <img src="./assets/images/sidebar_logo.svg"> -->
          <span class="tree_root__icon" [inlineSVG]="'./assets/images/sidebar_logo.svg'"></span>
        </div>
      </td>
      <!-- END ROOT POINT DESIGN -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'atomicServiceSpecs'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="atomicServiceSpecsOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF ITEM CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF COLLAPSING CONTROLLER  -->
            <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

              <!-- START OF ICON -->
              <span>
                <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
              </span>
              <!-- END OF ICON -->

            </div>
            <!-- END OF COLLAPSING CONTROLLER  -->

            <!-- START OF ITEM DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF ITEM ICON -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF ITEM ICON -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editAtomicServiceSpecItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT ITEM -->

                <!-- START OF DELETE ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE ITEM -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">service name</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.name}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">service description</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.description}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF STATUS -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Status </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.status}}</span>
                </div>
                <!-- END OF STATUS -->

              </div>
              <!-- END OF DETAILs -->


              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF ITEM DETAILS -->

          </div>
        </ng-container>
        <!-- END OF ITEM CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'compositeServiceSpecs'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="compositeServiceSpecsOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF ITEM CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF COLLAPSING CONTROLLER  -->
            <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

              <!-- START OF ICON -->
              <span>
                <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
              </span>
              <!-- END OF ICON -->

            </div>
            <!-- END OF COLLAPSING CONTROLLER  -->

            <!-- START OF ITEM DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF ITEM ICON -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF ITEM ICON -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editCompositeServiceSpecItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT ITEM -->

                <!-- START OF DELETE ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE ITEM -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">service name</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.name}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">service description</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.description}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF STATUS -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Status </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.status}}</span>
                </div>
                <!-- END OF STATUS -->

              </div>
              <!-- END OF DETAILs -->


              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF ITEM DETAILS -->

          </div>
        </ng-container>
        <!-- END OF ITEM CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'rfs'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="rfsOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF ITEM CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF COLLAPSING CONTROLLER  -->
            <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

              <!-- START OF ICON -->
              <span>
                <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
              </span>
              <!-- END OF ICON -->

            </div>
            <!-- END OF COLLAPSING CONTROLLER  -->

            <!-- START OF ITEM DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF ITEM ICON -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF ITEM ICON -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editRFSItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT ITEM -->

                <!-- START OF DELETE ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE ITEM -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">RFS name</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.name}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">RFS description</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.description}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF STATUS -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Status </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.status}}</span>
                </div>
                <!-- END OF STATUS -->

              </div>
              <!-- END OF DETAILs -->


              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF ITEM DETAILS -->

          </div>
        </ng-container>
        <!-- END OF ITEM CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'entitySpecCharUse'" #parentNode  [sortablejs]="item.children" [sortablejsOptions]="entitySpecCharUseOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF ITEM CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF COLLAPSING CONTROLLER  -->
            <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

              <!-- START OF ICON -->
              <span>
                <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
              </span>
              <!-- END OF ICON -->

            </div>
            <!-- END OF COLLAPSING CONTROLLER  -->

            <!-- START OF ITEM DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF ITEM ICON -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF ITEM ICON -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editEntitySpecCharUseItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT ITEM -->

                <!-- START OF DELETE ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE ITEM -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">name</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.name}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">description</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.description}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">

                <!-- START OF unique -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">unique</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.unique? 'Yes': 'No'}}</span>
                </div>
                <!-- END OF unique -->

                <!-- START OF canBeOverridden -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">can Be Overridden</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.canBeOverridden? 'Yes': 'No'}}</span>
                </div>
                <!-- END OF canBeOverridden -->

              </div>
              <!-- END OF DETAILs -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">

                <!-- START OF extensible -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">extensible</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.extensible? 'Yes': 'No'}}</span>
                </div>
                <!-- END OF extensible -->

                <!-- START OF isPackage -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">is Package</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.isPackage? 'Yes': 'No'}}</span>
                </div>
                <!-- END OF isPackage -->

              </div>
              <!-- END OF DETAILs -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">

                <!-- START OF minCardinality -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">min Cardinality</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.minCardinality}}</span>
                </div>
                <!-- END OF minCardinality -->

                <!-- START OF maxCardinality -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">max Cardinality</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.maxCardinality}}</span>
                </div>
                <!-- END OF maxCardinality -->

              </div>
              <!-- END OF DETAILs -->


              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF ITEM DETAILS -->

          </div>
        </ng-container>
        <!-- END OF ITEM CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'entitySpecCharValueUse'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="entitySpecCharValueUseOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF ITEM CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF COLLAPSING CONTROLLER  -->
            <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

              <!-- START OF ICON -->
              <span>
                <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
              </span>
              <!-- END OF ICON -->

            </div>
            <!-- END OF COLLAPSING CONTROLLER  -->

            <!-- START OF ITEM DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF ITEM ICON -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF ITEM ICON -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editEntitySpecCharValueUseItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT ITEM -->

                <!-- START OF DELETE ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE ITEM -->
              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">Discriminator</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.Discriminator}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">isDefault</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.isDefault? 'Yes': 'No'}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF ITEM DETAILS -->

          </div>
        </ng-container>
        <!-- END OF ITEM CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'serviceSpecCharacteristic'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="characteristicSpecificationOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF ITEM CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF COLLAPSING CONTROLLER  -->
            <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

              <!-- START OF ICON -->
              <span>
                <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
              </span>
              <!-- END OF ICON -->

            </div>
            <!-- END OF COLLAPSING CONTROLLER  -->

            <!-- START OF ITEM DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF ITEM ICON -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF ITEM ICON -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editCharacteristicSpecificationItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT ITEM -->

              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF NAME -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">name</span>
                <span class="font-semibold text-base block text-slate-900 max-w-xs">{{item?.data?.name}}</span>
              </div>
              <!-- END OF NAME -->

              <!-- START OF DESCRIPTION -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">description</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.description}}</span>
              </div>
              <!-- END OF DESCRIPTION -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">

                <!-- START OF unique -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">unique</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.unique? 'Yes': 'No'}}</span>
                </div>
                <!-- END OF unique -->

                <!-- START OF extensible -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">extensible</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.extensible? 'Yes': 'No'}}</span>
                </div>
                <!-- END OF extensible -->

                <!-- START OF valueType -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Value Type</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.valueType}}</span>
                </div>
                <!-- END OF valueType -->

              </div>
              <!-- END OF DETAILs -->

              <!-- START OF derivationFormula -->
              <div>
                <span class="font-semibold uppercase text-xs block text-slate-400">derivation Formula</span>
                <span class="font-semibold text-sm block text-slate-900 max-w-sm h-auto">{{item?.data?.derivationFormula}}</span>
              </div>
              <!-- END OF derivationFormula -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">

                <!-- START OF minCardinality -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">min Cardinality</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.minCardinality}}</span>
                </div>
                <!-- END OF minCardinality -->

                <!-- START OF maxCardinality -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">max Cardinality</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.maxCardinality}}</span>
                </div>
                <!-- END OF maxCardinality -->

              </div>
              <!-- END OF DETAILs -->


              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF ITEM DETAILS -->

          </div>
        </ng-container>
        <!-- END OF ITEM CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE -->
      <td *ngIf="item.type === 'characteristicSpecValue'" #parentNode [sortablejs]="item.children" [sortablejsOptions]="characteristicSpecificationValueOptions" class="tree-parent" [ngClass]="{'collapsed-parent': checkCollapsing(parentNode), 'one-child': (item.children?.length === 1), 'zero-child': (!item.children || item.children.length === 0)}">

        <!-- START OF ITEM CONTAINER -->
        <ng-container>
          <div class="flex flex-row justify-center gap-3 mt-4">

            <!-- START OF COLLAPSING CONTROLLER  -->
            <div *ngIf="item?.children?.length" class="collapse-controller" (click)="collapseNodes(parentNode)">

              <!-- START OF ICON -->
              <span>
                <i class="pi" [ngClass]="checkCollapsing(parentNode)? 'pi-angle-left': 'pi-angle-right'"></i>
              </span>
              <!-- END OF ICON -->

            </div>
            <!-- END OF COLLAPSING CONTROLLER  -->

            <!-- START OF ITEM DETAILS -->
            <div class="product_offer_item min-w-max flex flex-col gap-3 border-2 bg-white rounded-md p-2 pt-5 relative">

              <!-- START OF ITEM ICON -->
              <div class="border-2 rounded-md p-1 bg-white absolute -top-6 left-4">
                <span class="product_offer_item__logo" [inlineSVG]="'./assets/icons/catalogs/' + item?.icon"></span>
              </div>
              <!-- END OF ITEM ICON -->

              <!-- START OF CONTROLS -->
              <div class="absolute -top-4 right-4 flex flex-row gap-1">

                <!-- START OF EDIT ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="editCharacteristicSpecificationValueItem(item)">
                      <i class="pi pi-pencil"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF EDIT ITEM -->

                <!-- START OF DELETE ITEM -->
                <div class="border-2 rounded-md p-1 bg-white">
                  <span class="font-semibold uppercase text-xs block text-slate-400">
                    <button (click)="removeItem(item)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </span>
                </div>
                <!-- END OF DELETE ITEM -->

              </div>
              <!-- END OF CONTROLS -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">

                <!-- START OF valueType -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Value Type</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.valueType}}</span>
                </div>
                <!-- END OF valueType -->

                <!-- START OF isDefault -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">is Default</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.isDefault? 'Yes': 'No'}}</span>
                </div>
                <!-- END OF isDefault -->

                <!-- START OF value -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">value</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.value}}</span>
                </div>
                <!-- END OF value -->

              </div>
              <!-- END OF DETAILs -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">

                <!-- START OF unitOfMeasure -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Unit Of Measure</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.unitOfMeasure}}</span>
                </div>
                <!-- END OF unitOfMeasure -->

                <!-- START OF valueFrom -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Value From</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.valueFrom}}</span>
                </div>
                <!-- END OF valueFrom -->

                <!-- START OF valueTo -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Value To</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.valueTo}}</span>
                </div>
                <!-- END OF valueTo -->

              </div>
              <!-- END OF DETAILs -->

              <!-- START OF DETAILs -->
              <div class="flex flex-row gap-6 justify-between items-center">

                <!-- START OF rangeInterval -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Range Interval</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.rangeInterval}}</span>
                </div>
                <!-- END OF rangeInterval -->

                <!-- START OF rangeStep -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">Range Step</span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.rangeStep}}</span>
                </div>
                <!-- END OF rangeStep -->

              </div>
              <!-- END OF DETAILs -->

              <!-- START OF VALID FOR -->
              <div class="flex flex-row gap-6 justify-between items-center">
                <!-- START OF START DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">start Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.startDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF START DATE -->

                <!-- START OF END DATE -->
                <div>
                  <span class="font-semibold uppercase text-xs block text-slate-400">end Date Time </span>
                  <span class="font-semibold text-xs block text-slate-900">{{item?.data?.validFor?.endDateTime | date: 'dd-MM-yyyy hh:mm a'}}</span>
                </div>
                <!-- END OF END DATE -->

              </div>
              <!-- END OF VALID FOR -->

            </div>
            <!-- END OF ITEM DETAILS -->

          </div>
        </ng-container>
        <!-- END OF ITEM CONTAINER -->

      </td>
      <!-- END OF TREE-NODE -->

      <!-- START OF TREE-NODE CHILDs -->
      <td *ngIf="item.children" class="node-container">
        <div class="tree-child" *ngFor="let child of item.children">
          <service-catalog-tree (removeNode)="removeItem($event)" *ngIf="child?.children" [catalogTree]="catalogTree" [item]="child"></service-catalog-tree>
        </div>
      </td>
      <!-- END OF TREE-NODE CHILDs -->

    </tr>
  </tbody>
</table>
