<div class="custom-textbox">
  <label [for]="name">
    {{ placeholder| translate  }} <span class="required-astric" *ngIf="required">*</span>
  </label>
  <mat-form-field
    appearance="outline"
    [ngClass]="{ 'cpt-readonly': isReadOnly }"
    [floatLabel]="'always'"
  >

    <input
    #textInput
    #variable="ngModel"
    matInput
    type="{{ type }}"
    id="{{ id }}"
    name="{{ name }}"
    placeholder="{{ placeholder | translate }}"
    maxlength="{{ maxLength }}"
    minlength="{{ minlength }}"
    [pattern]="patternValueFromConfig()"
    [(ngModel)]="value"
    [disabled]="isDisabled"
    [readonly]="isReadOnly"
    (blur)="onBlur()"
    (keypress)="preventBasedOnPattern($event)"
    [required]="required"
    autocomplete="off"
    />
    <span
      *ngIf="isPassword"
      class="material-icons show-icon"
      [ngStyle]="{ opacity: passwordMode ? 0.3 : 1 }"
      (click)="togglePasswordMode()"
    >
      remove_red_eye
    </span>
    <span matSuffix *ngIf="suffixValue">
      <span>{{ suffixValue }}</span>
    </span>
    <mat-icon matSuffix *ngIf="isEditMode()" (click)="submitEdit(value)"
      >done</mat-icon
    >
    <mat-icon matSuffix *ngIf="isEditMode()" (click)="closeEdit()"
      >close</mat-icon
    >
    <mat-icon
      matSuffix
      (click)="toggleReadOnly()"
      *ngIf="isReadOnly && canEditInline"
      >mode_edit</mat-icon
    >
    <mat-hint align="{{ hintAlignment }}">{{ hint }}</mat-hint>
    <mat-error
      *ngIf="
        (!variable.valid &&
          requiredErrorMessage &&
          !value &&
          validationMode === validationModeObj.onblur) ||
        !variable.touched
      "
    >
      {{ requiredErrorMessage | translate }}
    </mat-error>

    <mat-error
      *ngIf="
        !variable.valid &&
        patternErrorMessage &&
        variable.value &&
        validationMode === validationModeObj.onblur
      "
    >
      {{ patternErrorMessage }}
    </mat-error>

    <mat-error *ngIf="displayAdditionalErrorMessage">
      {{ additionalErrorMessage }}
    </mat-error>

  </mat-form-field>
</div>
