import { environment } from '../../../../environments/environment';

const productCatalogUrl = `${environment.productCatalog}`;
const resourceCatalogUrl = `${environment.APIUrl}/resourceCatalog`;
const serviceCatalogUrl = `${environment.APIUrl}/serviceCatalog`;
// const serviceCatalogUrl = `http://dot.api-gateway.segmatek/serviceCatalog`;

export const Product_Catalog_API_URLS = {
  entitySpecificationType: `${productCatalogUrl}/entitySpecificationType/`,
  taxDefinition: `${productCatalogUrl}/taxDefinition/`,
  tariffProfile: `${productCatalogUrl}/tariffProfile/`,
  productOffering: `${productCatalogUrl}/productOffering/`,
  CREATE_PRODUCT_OFFER : `${productCatalogUrl}/productApi/`,
  GET_RESOURCE_CATALOG_SPECS: `${resourceCatalogUrl}/entitySpecification/`,
  GET_SERVICE_CATALOG_ATOMIC_SPECS: `${serviceCatalogUrl}/entitySpecification/5`,
  GET_SERVICE_CATALOG_COMPOSITE_SPECS: `${serviceCatalogUrl}/customerFacingServiceSpecComposite/`,
  GET_PRODUCT_CATALOG_PRODUCT_LINES: `${productCatalogUrl}/productLine/`,
  GET_PRODUCT_CATALOG_PRODUCT_CATEGORIES: `${productCatalogUrl}/productCategory/`,
  GET_PRODUCT_CATALOG_SALES_CHANNELS: `${productCatalogUrl}/salesChannel/`,
  GET_PRODUCT_CATALOG_PRODUCT_CATALOG: `${productCatalogUrl}/productCatalog/`,
};

// Product Table

export const Product_Table = {
  individualPrepaid: {
    discriminator: 'ProductLine',
    id: 1,
  },
  individualPostpaid: {
    discriminator: 'ProductLine',
    id: 2,
  },
  mobileVoice: {
    discriminator: 'ProductCateggory',
    id: 3,
  },
  subscriberSuspension: {
    discriminator: 'ProductCateggory',
    id: 4,
  },
  misisdnChange: {
    discriminator: 'ProductCateggory',
    id: 5,
  },
  simSwap: {
    discriminator: 'ProductCateggory',
    id: 6,
  },
  organizationPostpaid: {
    discriminator: 'ProductLine',
    id: 7,
  },
};
