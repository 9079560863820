import { Component } from '@angular/core';

@Component({
  selector: 'dot-prime-table',
  templateUrl: './dot-prime-table.component.html',
  styleUrls: ['./dot-prime-table.component.scss']
})
export class DotPrimeTableComponent{


}
