import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dot-modal',
  templateUrl: './dot-modal.component.html',
  styleUrls: ['./dot-modal.component.scss']
})
export class DotModalComponent {

  visible: boolean = true

  // INPUTS
  @Input() title = 'Modal Title';
  @Input() footer: boolean = true;
  @Input() isDeleteModal: boolean = false;
  @Input() disableConfirmDeleteBtn: boolean = false;
  @Input() confirmDeleteBtnLabel: string = "Confirm Delete";

  // OUTPUTS
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleChange: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() confirmDeleteFn: EventEmitter<any> = new EventEmitter<boolean>();

  closeModal() {
    this.modalClosed.emit();
  }

  confirmDelete(){
    this.confirmDeleteFn.emit();
  }

}
