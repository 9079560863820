import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';

@Component({
  selector: 'app-update-characteristic-specification-data',
  templateUrl: './update-characteristic-specification-data.component.html',
  styleUrls: ['./update-characteristic-specification-data.component.scss']
})
export class UpdateCharacteristicSpecificationDataComponent  extends AppBaseComponent{
  // Translation Object [Parent Node]
  parentTransName = 'provision.serviceCatalogDesigner.';

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1)

  constructor(
    private dialogRef: MatDialogRef<UpdateCharacteristicSpecificationDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      name: [null],
      description: [null],
      valueType: [null],
      unique: [null],
      extensible: [null],
      derivationFormula: [null],
      minCardinality: [null],
      maxCardinality: [null],
      startDateTime: [null],
      endDateTime: [null],
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(){
    // Return with name
    this.closeDialog(this.form.value)
  }

  ngOnInit(): void {

    // PATCH FORM
    if(this.data.isUpdateForm) this.patchForm()
  }

  patchForm(){
    this.form.patchValue({
      name: this.data?.item?.data?.name,
      description: this.data?.item?.data?.description,
      unique: this.data?.item?.data?.unique,
      valueType: this.data?.item?.data?.valueType,
      derivationFormula: this.data?.item?.data?.derivationFormula,
      minCardinality: this.data?.item?.data?.minCardinality,
      maxCardinality: this.data?.item?.data?.maxCardinality,
      extensible: this.data?.item?.data?.extensible,
      startDateTime: this.data?.item?.data?.validFor?.startDateTime,
      endDateTime: this.data?.item?.data?.validFor?.endDateTime,
    });
  }
}
