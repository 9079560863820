<div *ngIf="isVisible" class="loadding__page">
  <div class="loadding__page__loader">
    <!-- START OF IMAGE -->
    <div class="loadding__page__loader__image">
      <img class="w-full" src="../../../assets/images/sidebar_logo.svg" >
    </div>
    <!-- END OF IMAGE -->

    <!-- START OF LOADING -->
    <div class="loadding__page__loader__section">
      <div class="loadding__page__loader__section__container">
        <div class="loadding__page__loader__section__container__ring"></div>
        <div class="loadding__page__loader__section__container__ring"></div>
        <div class="loadding__page__loader__section__container__ring"></div>
        <div class="loadding__page__loader__section__container__ring"></div>
      </div>
    </div>
    <!-- END OF LOADING -->
  </div>
</div>

