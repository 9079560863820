export const DOT_MENU = {

  CUSTOMER_MANAGEMENT: {
    MENU: {permission: 'customer-management-menu-list-items', access: 'SHOW'},
  },
  INVENTORY_MANAGEMENT: {
    MENU: {permission: 'inventory-management-menu-list-items', access: 'SHOW'},
  },
  ORDER_MANAGEMENT: {
    MENU: {permission: 'order-management-menu-list-items', access: 'SHOW'},
  },
  PRODUCT_CATALOG: {
    MENU: {permission: 'product-catalog-menu-list-items', access: 'SHOW'},
  },
  PROVISION: {
    MENU: {permission: 'provision-menu-list-items', access: 'SHOW'},
  },
  PAYMENT_MANAGEMENT: {
    MENU: {permission: 'payment-management-menu-list-items', access: 'SHOW'},
  },
  SYSTEM_MANAGEMENT: {
    MENU: {permission: 'system-management-menu-list-items', access: 'SHOW'},
  },
  BILLING_MANAGEMENT: {
    MENU: {permission: 'billing-management-menu-list-items', access: 'SHOW'},
  },
  REPORTING_MANAGEMENT: {
    MENU: {permission: 'reporting-management-menu-list-items', access: 'SHOW'},
  },
  DUNNING_MANAGEMENT: {
    MENU: {permission: 'billing-management-menu-list-items', access: 'SHOW'},
  },

}