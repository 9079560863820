import { Component, Input, Renderer2, EventEmitter, Output } from '@angular/core';
import { Options } from 'sortablejs'
import { AppBaseComponent } from '@shared/components/base/app-base-component';

// INTERFACES
import { serviceCatalogInterface } from '../interfaces';
import { UpdateAtomicServiceDataComponent } from '../components/update-atomic-service-data/update-atomic-service-data.component';
import { UpdateCompositeServiceDataComponent } from '../components/update-composite-service-data/update-composite-service-data.component';
import { UpdateRfsDataComponent } from '../components/update-rfs-data/update-rfs-data.component';
import { UpdateServiceSpecCharUseDataComponent } from '../components/update-service-spec-char-use-data/update-service-spec-char-use-data.component';
import { UpdateServiceSpecCharValueUseDataComponent } from '../components/update-service-spec-char-value-use-data/update-service-spec-char-value-use-data.component';
import { UpdateCharacteristicSpecificationDataComponent } from '../components/update-characteristic-specification-data/update-characteristic-specification-data.component';
import { UpdateCharacteristicSpecificationValueDataComponent } from '../components/update-characteristic-specification-value-data/update-characteristic-specification-value-data.component';

@Component({
  selector: 'service-catalog-tree',
  templateUrl: './service-catalog-tree.component.html',
  styleUrls: ['./service-catalog-tree.component.scss']
})
export class ServiceCatalogTreeComponent extends AppBaseComponent{

  @Input() catalogTree: serviceCatalogInterface[];
  @Input() item: serviceCatalogInterface;

  @Output() removeNode: EventEmitter<any> = new EventEmitter<any>

  constructor(private renderer: Renderer2) {
    super();
  }

  // TREE NODE OPTIONs
  rootPointOptions: Options = {
    group: {
      name: 'root-point-group',
      pull: false,
      put: ['services-specs-clone-group'],
      revertClone: true,
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',
    onChoose: (event) => console.log(event),
    onAdd   : (event) => console.log(event),
  };

  atomicServiceSpecsOptions: Options = {
    group: {
      name: 'atomic-service-specs-group',
      pull: false,
      put: ['rfs-clone-group', 'entity-spec-char-use-clone-group'],
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',

  };

  compositeServiceSpecsOptions: Options = {
    group: {
      name: 'composite-service-specs-group',
      pull: false,
      put: false,
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',

  };

  rfsOptions: Options = {
    group: {
      name: 'rfs-group',
      pull: false,
      put: false,
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',
  };

  entitySpecCharUseOptions: Options = {
    group: {
      name: 'entity-spec-char-use-group',
      pull: false,
      put: ['entity-spec-char-value-use-clone-group', 'characteristic-specification-clone-group'],
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',
  };

  entitySpecCharValueUseOptions: Options = {
    group: {
      name: 'entity-spec-char-value-use-group',
      pull: false,
      put: false,
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',
  };

  characteristicSpecificationOptions: Options = {
    group: {
      name: 'characteristic-specification-group',
      pull: false,
      put: ['characteristic-specification-value-clone-group'],
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',
  };

  characteristicSpecificationValueOptions: Options = {
    group: {
      name: 'characteristic-specification-value-group',
      pull: false,
      put: false,
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',
  };

  /**
   * REMOVE ITEM FROM THE TREE
   * @param item
   */
  removeItem(item: serviceCatalogInterface){
    this.removeNode.emit(item);
  }

  editAtomicServiceSpecItem(item: serviceCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateAtomicServiceDataComponent, {
      width: '600px',
      disableClose: true,
      data: {item: item, isUpdateForm: true}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item.data.name = res?.name
        item.data.description = res?.description
        item.data.status = res?.status.name
        item.data.validFor.startDateTime = res?.startDateTime
        item.data.validFor.endDateTime = res?.endDateTime
      }
    })
  }

  editCompositeServiceSpecItem(item: serviceCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateCompositeServiceDataComponent, {
      width: '600px',
      disableClose: true,
      data: {item: item, isUpdateForm: true}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item.data.name = res?.name
        item.data.description = res?.description
        item.data.status = res?.status.name
        item.data.customerFacingServiceSpec = res?.customerFacingServiceSpec
        item.data.validFor.startDateTime = res?.startDateTime
        item.data.validFor.endDateTime = res?.endDateTime
      }
    })
  }

  editRFSItem(item: serviceCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateRfsDataComponent, {
      width: '600px',
      disableClose: true,
      data: {item: item, isUpdateForm: true}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item.data.name = res?.name
        item.data.description = res?.description
        item.data.status = res?.status.name
        item.data.resourceSpecification = (res?.resourceSpecification)? [{id: res?.resourceSpecification?.id, Discriminator: res?.resourceSpecification?.Discriminator}] : []
        item.data.validFor.startDateTime = res?.startDateTime
        item.data.validFor.endDateTime = res?.endDateTime
      }
    })
  }

  editEntitySpecCharUseItem(item: serviceCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateServiceSpecCharUseDataComponent, {
      width: '600px',
      disableClose: true,
      data: {item: item, isUpdateForm: true}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item.data.name = res?.name
        item.data.description = res?.description
        item.data.unique = res?.unique
        item.data.isPackage = res?.isPackage
        item.data.extensible = res?.extensible
        item.data.canBeOverridden = res?.canBeOverridden
        item.data.minCardinality = res?.minCardinality
        item.data.maxCardinality = res?.maxCardinality
        item.data.validFor.startDateTime = res?.startDateTime
        item.data.validFor.endDateTime = res?.endDateTime
      }
    })
  }

  editEntitySpecCharValueUseItem(item: serviceCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateServiceSpecCharValueUseDataComponent, {
      width: '600px',
      disableClose: true,
      data: {item: item, isUpdateForm: true}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item.data.isDefault = res?.isDefault
        item.data.validFor.startDateTime = res?.startDateTime
        item.data.validFor.endDateTime = res?.endDateTime
      }
    })
  }

  editCharacteristicSpecificationItem(item: serviceCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateCharacteristicSpecificationDataComponent, {
      width: '600px',
      disableClose: true,
      data: {item: item, isUpdateForm: true}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item.data.name = res?.name
        item.data.description = res?.description
        item.data.unique = res?.unique
        item.data.valueType = res?.valueType
        item.data.derivationFormula = res?.derivationFormula
        item.data.extensible = res?.extensible
        item.data.minCardinality = res?.minCardinality
        item.data.maxCardinality = res?.maxCardinality
        item.data.validFor.startDateTime = res?.startDateTime
        item.data.validFor.endDateTime = res?.endDateTime
      }
    })
  }

  editCharacteristicSpecificationValueItem(item: serviceCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateCharacteristicSpecificationValueDataComponent, {
      width: '600px',
      disableClose: true,
      data: {item: item, isUpdateForm: true}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item.data.isDefault = res?.isDefault
        item.data.valueType = res?.valueType
        item.data.value = res?.value
        item.data.unitOfMeasure = res?.unitOfMeasure
        item.data.valueFrom = res?.valueFrom
        item.data.valueTo = res?.valueTo
        item.data.rangeInterval = res?.rangeInterval
        item.data.rangeStep = res?.rangeStep
        item.data.validFor.startDateTime = res?.startDateTime
        item.data.validFor.endDateTime = res?.endDateTime
      }
    })
  }

  getTreeContainer(node: HTMLElement){
    // PARENT NODE
    let treeContainer = node;

    while(true){
      // GET THE CURRENT NODE CHILDs CONTAINER
      treeContainer = this.renderer.nextSibling(treeContainer)

      // CHECK IF IT A TREE CONTAINER OR NOT
      if (treeContainer?.classList?.contains("node-container")){
        return treeContainer;
      }
    }
  }

  collapseNodes(node: HTMLElement){
    this.getTreeContainer(node).classList?.toggle('d-none');
  }

  checkCollapsing(node: HTMLElement){
    return this.getTreeContainer(node).classList?.contains("d-none");
  }

}
