import { Component } from '@angular/core';

@Component({
  selector: 'app-errors-layout',
  templateUrl: './errors-layout.component.html',
  styleUrls: ['./errors-layout.component.scss']
})
export class ErrorsLayoutComponent {

}
