<div class="errors-main-layout">

  <!-- START OF DOT LOGO -->
  <div class="w-24 absolute top-4 left-4">
    <img class="w-full" src="../../../assets/images/navbar_logo.svg" >
  </div>
  <!-- END OF DOT LOGO -->

  <!-- START OF PAGES -->
  <router-outlet></router-outlet>
  <!-- END OF PAGES -->
</div>

