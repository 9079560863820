import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URLS } from '@shared/utilities';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private httpClient: HttpClient) {}

  gitFile(fileName){return this.httpClient.get(`${API_URLS.GET_BATCH_FILE_DOCUMENT_SERVICE}/${fileName}`)}

}
