import { environment } from '../../../../environments/environment';

const domainUrl = `${environment.APIUrl}`;
const DOTMOCKS = `https://4129f3b3-2072-454a-9564-a800dbacb831.mock.pstmn.io`;

export const Payment_Management_API_URLS = {
  /** CASHBOX MANAGEMENT */
  CASHBOX: `${domainUrl}/cashbox`,
  CREATE_CASHBOX: `${domainUrl}/cashbox/cashBox/`,
  CASHBOX_CURRENCIES: `${domainUrl}/cashbox/lookups/currencies`,
  CASHBOX_TYPES: `${domainUrl}/cashbox/cashBox/types`,
  OU_TYPES: `${domainUrl}/cashbox/lookups/orgUnitType`,
  USER_CASHBOXES: `${domainUrl}/cashbox/cashBox/user`,
  OU_TYPE_OUs: (orgUnitTypeId) =>
    `${domainUrl}/cashbox/lookups//getOrgUnityByType/${orgUnitTypeId}`,
  OU_USERS: (ouID) =>
    `${domainUrl}/omniauth/omni/auth/manager/users/org-unit/${ouID}`,
  OPEN_CLOSE_CASHBOX: `${domainUrl}/cashbox/cashBox/toggleOpenClose`,
  ADJUST_CASHBOX: `${domainUrl}/cashbox/cashBox/adjust`,
  ARCHIVE_CASHBOX: `${domainUrl}/cashbox/cashBox/archive`,
  ALL_OUS: `${domainUrl}/cashbox/cashBoxOrgUnits/allOrgUnits`,
  CASHBOX_OU_SAFES: (ouID) =>
    `${domainUrl}/cashbox/cashBox/safesInOrgUnit?orgUnit=${ouID}`,
  OUS_CASHBOXES: `${domainUrl}/cashbox/cashBox/orgnizationUnit`,
  SAFE_CASHBOXES: (safeID) =>
    `${domainUrl}/cashbox/cashBox/safe/${safeID}/type/not_safe`,
  CUSTOMEER_LEVEL_PAYMENT: (id) =>
    `${domainUrl}/customerPayment/customerPayment/${id}/paymentAccountDetails`,
  CALCAMOUNTAFTEREROUNDING: `${domainUrl}/customerPayment/customerPayment/applyAmountRounding`,
  APPLYCOLLECTIONFEES: (amount) =>
    `${domainUrl}/customerPayment/customerPayment/applyCollectionFees/${amount}`,
  CUSTOMEER_LEVEL_PAYMENT_REVERSE: (id) =>
    `${domainUrl}/customerPayment/customerReversePayment/${id}/reversAccountDetails`,
  ISSUE_MANUAL_INVOICE_POST: `${domainUrl}/customerManager/dispute/issueDebitManualInvoice`,
  GET_MANUAL_INVOIVE_CREADIT: (msisdn) =>
    `${domainUrl}/customerManager/dispute/msisdn/${msisdn}`,
  GET_CUSTOMER_BILLS_BY_ID: (customerId) =>
    `${DOTMOCKS}/customerManager/customerBill/account/${customerId}`,   
  GET_CUSTOMER_DISPUTED_BILLS_BY_ID: (customerId) =>
    `${DOTMOCKS}/customerManager/dispute/getCustomerDisputeRequests/${customerId}`,   
  POST_REQUEST_DISPUTE_ISSUE: (customerId) =>
    `${DOTMOCKS}/customerManager/dispute/issueDisputeRequest/${customerId}`,
  PSOT_MANUAL_INVOIVE_CREADIT: (msisdn) =>
    `${domainUrl}/customerManager/dispute/issueCreditManualInvoice/${msisdn}`,
  PAYMENT: `${domainUrl}/customerPayment/customerPaymentOrder/applyPayment`,
  REVERSE_PAYMENT: `${domainUrl}/customerPayment/customerReversePayment/submitReversePayment`,
  BALANCE_TRANSFER: (id) =>
    `${domainUrl}/customerManager/partyRole/${id}/partyAccountsAndTransferBalances`,
  CALC_FEES: (id, amount) =>
    `${domainUrl}/customerManager/balanceTransfer/${id}/validateAndAddFees/${amount}`,
  GET_CUSTMOERR_ACCOUNT_BY_MSISDN: (msisdn: number) =>
    `${domainUrl}/customerManager/transfer/accountByMsisdn/${msisdn}`,
  BALANCE_TRANSFER_SUBMIT: `${domainUrl}/customerManager/transfer/balance`,

  // QUERY CASHBOX
  QUERY_CASHBOX: `${domainUrl}/cashbox/queryCashBox/`,
  OU_CASHBOXES: (ouId) => `${domainUrl}/cashbox/cashBox/orgUnit/${ouId}`,

  // CUSTOMER PAYMENTS ACCOUNT
  CUSTOMER_PAYMENTS: (customerId) =>
    `${domainUrl}/customerPayment/customerPayment/getByAccountId?accountId=${customerId}`,
  TRANSACTIONS_DETAILS: (id, fromDate, toDate) =>
    `${domainUrl}/cashbox/cashBoxRegistry/getCashBoxTransactions/${id}?fromDate=${fromDate}&toDate=${toDate}`,
};
