import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';

const selector = 'dot-spinner';
@Component({
  selector,
  templateUrl: './dot-spinner.component.html',
})
export class DotSpinnerComponent implements OnInit, OnDestroy {
  @Input() isSpinnerVisible = true;
  @Input() inlineSpinner!: boolean;
  @Input() isFullScreen!: boolean;
  @Input() isDefaultColor = true;
  whiteColor = '#ffffff';
  defaultColor = '#007DC6';
  public width = 50;
  constructor(private router: Router) {
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isSpinnerVisible = false;
        }
      },
      () => {
        this.isSpinnerVisible = false;
      }
    );
  }

  ngOnInit() {
    if (this.inlineSpinner) {
      this.width = 25;
    }
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
  }
}
