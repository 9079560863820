
export const ROUTERS_PARENTS = {

  SYSTEM_MANAGEMENT_ROUTE_PERMISSIONS: {permission: "system-management-routers-list-global-router", access: "SHOW"},
  CUSTOMER_MANAGEMENT_ROUTE_PERMISSIONS: {permission: "customer-management-routers-list-global-router", access: "SHOW"},
  PAYMENT_MANAGEMENT_ROUTE_PERMISSIONS: {permission: "payment-management-routers-list-global-router", access: "SHOW"},
  PRODUCT_CATALOG_ROUTE_PERMISSIONS: {permission: "product-catalog-routers-list-global-router", access: "SHOW"},
  BILLING_MANAGEMENT_ROUTE_PERMISSIONS: {permission: "billing-management-routers-list-global-router", access: "SHOW"},
  ORDER_MANAGEMENT_ROUTE_PERMISSIONS: {permission: "order-management-routers-list-global-router", access: "SHOW"},
  INVENTORY_MANAGEMENT_ROUTE_PERMISSIONS: {permission: "inventory-management-routers-list-global-router", access: "SHOW"},
  PROVISION_ROUTE_PERMISSIONS: {permission: "provision-routers-list-global-router", access: "SHOW"},
  REPORTING_MANAGEMENT_ROUTE_PERMISSIONS: {permission: "reporting-management-routers-list-global-router", access: "SHOW"},
  HOME: {permission: "dot-services-home-page-routers-system-user", access: "SHOW"}

}
