import { Component, Input, Renderer2, EventEmitter, Output } from '@angular/core';
import { productCatalogInterface } from '../interfaces/productCatalog';
import {Options} from 'sortablejs'
import { UpdateOfferDataComponent } from '../components/update-offer-data/update-offer-data.component';
import { AppBaseComponent } from '@shared/components/base/app-base-component';
import { UpdateSpecificationDataComponent } from '../components/update-specification-data/update-specification-data.component';
import { UpdatePriceDataComponent } from '../components/update-price-data/update-price-data.component';
import { UpdateOfferRelationshipComponent } from '../components/update-offer-relationship/update-offer-relationship.component';

@Component({
  selector: 'product-catalog-tree',
  templateUrl: './product-catalog-tree.component.html',
  styleUrls: ['./product-catalog-tree.component.scss']
})
export class ProductCatalogTreeComponent extends AppBaseComponent{

  @Input() catalogTree: productCatalogInterface[];
  @Input() item: productCatalogInterface;
  @Input() productOffers: any[];

  @Output() removeNode: EventEmitter<any> = new EventEmitter<any>

  constructor(private renderer: Renderer2) {
    super();
  }


  // TREE NODE OPTIONs
  rootPointOptions: Options = {
    group: {
      name: 'root-point-group',
      pull: false,
      put: ['products-offers-clone-group'],
      revertClone: true,
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',

    onChange: (event) => console.log("onChange ROOT Fired"),
    onUpdate: (event) => console.log('onUpdate ROOT Fired'),
    onSort  : (event) => console.log('onSort ROOT Fired'),
    onRemove: (event) => console.log('onRemove ROOT Fired'),
    onChoose: (event) => console.log('onChoose ROOT Fired'),
    onStart : (event) => console.log('onStart ROOT Fired'),
    onEnd   : (event) => console.log('onEnd ROOT Fired'),
    onClone : (event) => console.log('onClone ROOT Fired'),
    onAdd   : (event) => console.log('onAdd ROOT Fired'),
    onMove  : (evt, originalEvent) => console.log('onMove ROOT Fired'),
    setData : (dataTransfer, draggedElement) => console.log("setData ROOT Fired")
  };

  productOffersOptions: Options = {
    group: {
      name: 'product-offers-group',
      pull: false,
      put: ['products-specs-clone-group', 'products-prices-clone-group', 'product-offers-relationship-clone-group'],
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',

  };

  productSpecsOptions: Options = {
    group: {
      name: 'product-specs-group',
      pull: false,
      put: ['products-specs-clone-group'],
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',

  };

  productPriceOptions: Options = {
    group: {
      name: 'product-price-group',
      pull: false,
      put: ['products-prices-alterations-clone-group'],
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',
  };

  productPriceAlterationsOptions: Options = {
    group: {
      name: 'product-price-alterations-group',
      pull: false,
      put: false,
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',
  };

  productRelationshipOptions: Options = {
    group: {
      name: 'product-relationship-group',
      pull: false,
      put: false,
    },
    sort: false,
    animation: 150,
    fallbackOnBody: true,
    invertSwap: false,
		swapThreshold: 0.65,
    ghostClass: 'sortable-ghost',
  };

  /**
   * REMOVE ITEM FROM THE TREE
   * @param item
   */
  removeItem(item: productCatalogInterface){
    this.removeNode.emit(item);
  }

  /**
   * GET TREE ITEM PARENT
   * @param rootArray
   * @param targetObject
   * @param parent
   * @returns
   */
  getItemParent(rootArray: any[], targetObject: any, parent = null): any {
    for (const item of rootArray) {
      if (item === targetObject) {
        return parent;
      } else if (typeof item === 'object') {
        const result = this.checkParent(item, targetObject, item);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  /**
   * RECURSIVE FN TO CHECK IF THE PASSED PARENT
   * HAS THE TARGET ITEM
   * @param rootObject
   * @param targetObject
   * @param parent
   * @returns
   */
  checkParent(rootObject: any, targetObject: any, parent = null): any {
    for (const key in rootObject) {
      if (rootObject.hasOwnProperty(key)) {
        if (rootObject[key] === targetObject) {
          return parent;
        } else if (typeof rootObject[key] === 'object') {
          const result = this.checkParent(rootObject[key], targetObject, rootObject);
          if (result) {
            return result;
          }
        }
      }
    }
    return null;
  }

  editOfferItem(item: productCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateOfferDataComponent, {
      width: '600px',
      disableClose: true,
      data: {offer: item, isUpdateForm: true}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item['data']['name'] = res?.name
        item['data']['description'] = res?.description
        item['data']['isPrimary'] = res?.isPrimary
        item['data']['isSellableStandAlone'] = res?.isSellableStandAlone
        item['data']['isBundle'] = res?.isBundle
        item['data']['sms'] = res?.sms
        item['data']['version'] = res?.version
        item['data']['validFor']['startDateTime'] = res?.startDateTime
        item['data']['validFor']['endDateTime'] = res?.endDateTime
      }
    })
  }

  editSpecsItem(item: productCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateSpecificationDataComponent, {
      width: '600px',
      disableClose: true,
      data: {specification: item, isUpdateForm: true}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item['data']['name'] = res?.name
        item['data']['description'] = res?.description
        item['data']['brand'] = res?.brand
        item['data']['isShared'] = res?.isShared
        item['data']['status'] = res?.status
        item['data']['validFor']['startDateTime'] = res?.startDateTime
        item['data']['validFor']['endDateTime'] = res?.endDateTime
      }
    })
  }

  editPriceItem(item: productCatalogInterface){
    const settingsDialog = this.dialogService.open(UpdatePriceDataComponent, {
      width: '600px',
      disableClose: true,
      data: {price: item, isUpdateForm: true, isBase: true}
    });

    console.log("Base Price", item)
    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item['data']['name'] = res?.name
        item['data']['description'] = res?.description
        item['data']['unitOfMeasure'] = res?.unitOfMeasure
        item['data']['price'] = res?.price
        item['data']['billCode'] = res?.billCode
        item['data']['isRefundable'] = res?.isRefundable
        item['data']['isBackward'] = res?.isBackward
        item['data']['isProrated'] = res?.isProrated
        item['data']['validFor']['startDateTime'] = res?.startDateTime
        item['data']['validFor']['endDateTime'] = res?.endDateTime
        if (res?.period) item['data']['period'] = res?.period.name
        if (res?.applicationDuration) item['data']['applicationDuration'] = res?.applicationDuration.name
      }
    })
  }

  editPriceAlterationItem(item: productCatalogInterface){

    // GET THE CURRENT ITEM PARENT
    const parentOfTargetObject = this.getItemParent(this.catalogTree, item);

    console.log("Altration Price", item)

    // OPEN THE UPDATE MODEL
    const settingsDialog = this.dialogService.open(UpdatePriceDataComponent, {
      width: '600px',
      disableClose: true,
      data: {price: item, parent: parentOfTargetObject, isUpdateForm: true, isBase: false}
    });

    // UPDATE THE ITEM
    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item['data']['name'] = res?.name
        item['data']['description'] = res?.description
        item['data']['unitOfMeasure'] = res?.unitOfMeasure
        item['data']['price'] = res?.price
        item['data']['billCode'] = res?.billCode
        item['data']['rounding'] = res?.rounding
        item['data']['priority'] = res?.priority
        item['data']['validFor']['startDateTime'] = res?.startDateTime
        item['data']['validFor']['endDateTime'] = res?.endDateTime
        if (res?.applicationDuration) item['data']['applicationDuration'] = res?.applicationDuration
        if (res?.taxDefinition) item['data']['taxDefinition'] = res?.taxDefinition
        if (res?.tariffProfile) item['data']['tariffProfile'] = res?.tariffProfile
      }
    })
  }

  editRelationshipItem(item: productCatalogInterface){

    const settingsDialog = this.dialogService.open(UpdateOfferRelationshipComponent, {
      width: '600px',
      disableClose: true,
      data: {element: item, isUpdateForm: true, productOffers: this.productOffers}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: any)=>{
      if(res){
        item['data']['productOffering'] = {id: res?.productOffering?.id}
        item['data']['validFor']['startDateTime'] = res?.startDateTime
        item['data']['validFor']['endDateTime'] = res?.endDateTime
      }
    })
  }

  getTreeContainer(node: HTMLElement){
    // PARENT NODE
    let treeContainer = node;

    while(true){
      // GET THE CURRENT NODE CHILDs CONTAINER
      treeContainer = this.renderer.nextSibling(treeContainer)

      // CHECK IF IT A TREE CONTAINER OR NOT
      if (treeContainer?.classList?.contains("node-container")){
        return treeContainer;
      }
    }
  }

  collapseNodes(node: HTMLElement){
    this.getTreeContainer(node).classList?.toggle('d-none');
  }

  checkCollapsing(node: HTMLElement){
    return this.getTreeContainer(node).classList?.contains("d-none");
  }
}
