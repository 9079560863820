<app-header
></app-header>
<main  class="flex flex-row">
<app-sidebar></app-sidebar>

  <section
    class="main-container md:p-8 lg:p-8 desktop:p-8 p-4"
  >
    <router-outlet>
    </router-outlet>
  </section>
</main>
