import { Component } from '@angular/core';

@Component({
  selector: 'app-header-search-bar',
  templateUrl: './header-search-bar.component.html',
  styleUrls: ['./header-search-bar.component.scss']
})
export class HeaderSearchBarComponent {

  // Attributes
  searchMethod = 'user-id'
  searchValue = ''

  // Display/hide Search Method Menu
  onDisplaySearchMethods (){

    // Dispaly the menu
    document.getElementById('search-bar-options-menu').classList.toggle('active')

    // Rotate icon
    document.getElementById('search-bar-options-selected-option-icon').classList.toggle('open')

  }

  // Display/hide Search Method Menu
  onHeidSearchMethods (){

    // Hide the menu
    document.getElementById('search-bar-options-menu').classList.remove('active')

    // Rotate icon
    document.getElementById('search-bar-options-selected-option-icon').classList.remove('open')

  }

  // Set the search Method
  onChooseSearchMethod (event) {

    // Get the Search Method Text
    document.getElementById('selectd-search-option').innerText = event.target.getAttribute('data-option')

    
    // Hide the meue
    this.onHeidSearchMethods()

  }



}
