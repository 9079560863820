<!-- START OF SEARCH FORM -->
<div class="table__filters" *ngIf="isFilter">

  <!-- START OF SEARCH BAR -->
  <div class="table__filters__search_bar" *ngIf="searchbarFilter">
    <label class="mb-1">{{searchLable}}</label>
    <div class="table__filters__search_bar__container">
      <span
        [class]="dataSource.data?.length
            ? 'table__filters__search_bar__container__icon'
            : 'table__filters__search_bar__container__icon disabled'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-search"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </span>
      <input
        [disabled]="!dataSource.data?.length"
        [class]="dataSource.data?.length
            ? 'table__filters__search_bar__container__input'
            : 'table__filters__search_bar__container__input disabled'
        "
        (keyup)="applyFilter($event)"
        [placeholder]="searchPlaceholder"
        #input
      />
    </div>
  </div>
  <!-- END OF SEARCH BAR -->

  <!-- START OF ADDITIONAL FILTERS -->
  <div class="table__filters__additionalfilters" *ngIf="additionalFilter">
    <ng-content select=".additional-filters"></ng-content>
  </div>
  <!-- END OF ADDITIONAL FILTERS -->

</div>
<!-- END OF SEARCH FORM -->

<ng-scrollbar
    #scrollable
    track="all"
    pointerEventsMethod="scrollbar"
    [autoHeightDisabled]="false"
  >

  <!-- START OF TABLE SECTION -->
  <section class="table-grid-container">

      <!-- START OF TABLE -->
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChangeEvent($event)">
        <ng-content></ng-content>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <ng-container *ngIf="hasFooter">
          <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell p-2 font-bold text-center" colspan="4">No data</td>
        </tr>
      </table>
      <!-- END OF TABLE -->

  </section>
  <!-- END OF  OF TABLE SECTION -->
</ng-scrollbar>

<!-- START OF PAGINATOR -->
<mat-paginator
  [ngClass]="{'hidePagination': !hasPagination}"
  (page)="changePageEvent($event)"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOption" [length]="dataLength">
</mat-paginator>
<!-- END OF PAGINATOR -->


