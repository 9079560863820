import { Component, Input } from '@angular/core';

@Component({
  selector: 'dot-page-tree-card-layout',
  templateUrl: './dot-page-tree-card-layout.component.html',
  styleUrls: ['./dot-page-tree-card-layout.component.scss']
})
export class DotPageTreeCardLayoutComponent {

  // INPUTS
  @Input() title: string;

}
