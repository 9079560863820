import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';

@Component({
  selector: 'app-update-composite-service-data',
  templateUrl: './update-composite-service-data.component.html',
  styleUrls: ['./update-composite-service-data.component.scss']
})
export class UpdateCompositeServiceDataComponent extends AppBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'provision.serviceCatalogDesigner.';

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1)

  // HOLDERs
  customerFacingServiceSpec: any[]
  status = [
    {name: 'ACTIVE'},
    {name: 'INACTIVE'},
  ]

  constructor(
    private dialogRef: MatDialogRef<UpdateCompositeServiceDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      name: [null],
      description: [null],
      status: [null],
      customerFacingServiceSpec: [null],
      startDateTime: [null],
      endDateTime: [null],
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(){
    // Return with name
    this.closeDialog(this.form.value)
  }

  ngOnInit(): void {

    // LOAD CustomerFacingServiceSpec
    this.getCustomerFacingServiceSpec()

    // PATCH FORM
    if(this.data.isUpdateForm) this.patchForm()
  }

  getCustomerFacingServiceSpec(){
    this.customerFacingServiceSpec = [
        {
            "id": 804,
            "Discriminator": "MobileVoice"
        },
        {
            "id": 807,
            "Discriminator": "SMS"
        },
        {
            "id": 810,
            "Discriminator": "MobileData"
        }
    ]
  }

  patchForm(){
    this.form.patchValue({
      name: this.data?.item?.data?.name,
      description: this.data?.item?.data?.description,
      status: {name: this.data?.item?.data?.status},
      customerFacingServiceSpec: this.data?.item?.data?.customerFacingServiceSpec,
      startDateTime: this.data?.item?.data?.validFor?.startDateTime,
      endDateTime: this.data?.item?.data?.validFor?.endDateTime,
    });
  }
}
