import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@core/services/language.service';
import { Language } from './models/language.model';

@Component({
  selector: 'app-lang',
  templateUrl: './app-lang.component.html',
  styleUrls: ['./app-lang.component.scss']
})
export class AppLangComponent implements OnInit {

  showLangMune:boolean = false
  activeLang:Language;


  listLang = [
    { name: 'العربية', flag: 'assets/icons/flags/ar-eg.png', code: 'ar', dir:'rtl' },
    { name: 'English', flag: 'assets/icons/flags/en-lang.png', code: 'en', dir:'ltr' },
  ];

  constructor(
  public _languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.activeLang = this.listLang.find(lang => lang?.code === this._languageService.getSelectedLanguage())
    if(!this.activeLang){
      this.activeLang = this.listLang[0]
      this.setActiveLang(this.activeLang)
    }
  }


  setActiveLang(lang:Language){
    this._languageService.setLanguage(lang?.code)

  }
}
