import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';

@Component({
  selector: 'app-update-specification-data',
  templateUrl: './update-specification-data.component.html',
  styleUrls: ['./update-specification-data.component.scss']
})
export class UpdateSpecificationDataComponent extends AppBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'productCatalogue.productCatalogModeler.specificationDetailsUpdateForm.';

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1)

  constructor(
    private dialogRef: MatDialogRef<UpdateSpecificationDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      name: [null],
      description: [null],
      brand: [null],
      startDateTime: [null],
      endDateTime: [null],
      isShared: [null],
      status: [null]
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(){
    // Return with name
    this.closeDialog(this.form.value)
  }

  ngOnInit(): void {
    if(this.data.isUpdateForm){
      // Load Data
      this.form.patchValue({
        name: this.data?.specification?.data?.name,
        description: this.data?.specification?.data?.description,
        brand: this.data?.specification?.data?.brand,
        startDateTime: this.data?.specification?.data?.validFor?.startDateTime,
        endDateTime: this.data?.specification?.data?.validFor?.endDateTime,
        isShared: this.data?.specification?.data?.isShared,
        status: this.data?.specification?.data?.status,
      });
    }
  }

}
