import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppBaseComponent } from '@shared/components/base/app-base-component';

@Component({
  selector: 'app-update-service-spec-char-value-use-data',
  templateUrl: './update-service-spec-char-value-use-data.component.html',
  styleUrls: ['./update-service-spec-char-value-use-data.component.scss']
})
export class UpdateServiceSpecCharValueUseDataComponent extends AppBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'provision.serviceCatalogDesigner.';

  // DATE TIME SETTINGs
  minDate = this.getFutureDateTime(1)

  constructor(
    private dialogRef: MatDialogRef<UpdateServiceSpecCharValueUseDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    super()
    this.loadForm({
      isDefault: [null],
      startDateTime: [null],
      endDateTime: [null],
    })
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(){
    // Return with name
    this.closeDialog(this.form.value)
  }

  ngOnInit(): void {
    if(this.data.isUpdateForm){
      // Load Data
      this.form.patchValue({
        isDefault: this.data?.item?.data?.isDefault,
        startDateTime: this.data?.item?.data?.validFor?.startDateTime,
        endDateTime: this.data?.item?.data?.validFor?.endDateTime,
      });
    }
  }

}
