import {
  Component,
  OnInit,
  ElementRef,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  forwardRef,
  AfterViewInit,
  Injector,
} from '@angular/core';
import {
  ControlValueAccessor,
  Validator,
  AbstractControl,
  ValidationErrors,
  FormControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NgControl,
} from '@angular/forms';
import { HelperService } from '@core/helper/helper.service';

@Component({
  selector: 'dot-textbox',
  templateUrl: './dot-textbox.component.html',
  styleUrls: ['./dot-textbox.component.scss', '../common/fields.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DotTextboxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DotTextboxComponent),
      multi: true,
    },
  ],
})
export class DotTextboxComponent
  implements OnInit, ControlValueAccessor, Validator, OnChanges,AfterViewInit
{
  /** function template */
  private onTouchedCallback: () => {};

  @ViewChild('textInput', { read: ElementRef, static: true })
  textInput: ElementRef;

  /** ViewChild of this control*/
  @ViewChild(NgControl) innerNgControl: NgControl;

  /** element id attribute */
  @Input() id: string;

  /** element name attribute */
  @Input() name: string;

  /** element placeholder attribute */
  @Input() placeholder = '';

  /** for check if the input password or text */
  @Input() isPassword = false;
  @Input() passwordNotMatch = false;
  /** regular expression pattern */
  @Input() patternValue = '';

  /** for check if the input is required or not  */
  @Input() required = false;

  /** hold suffix value */
  @Input() suffixValue = '';

  /** required error message */
  @Input() requiredErrorMessage = 'validation.required';

  /** pattern error message */
  @Input() patternErrorMessage: string;

  /** to disable the input or not */
  @Input() isDisabled = false;

  /** to readonly the input or not */
  @Input() isReadOnly = false;

  /** to show edit controls in the input or not */
  @Input() canEditInline = false;

  /** this variable will hold key name from regex-config.json file */
  @Input() patternKey = '';

  /** to specify the maximum characters for the input  */
  @Input() maxLength: any;

  /** to specify the minuimum characters for the input  */
  @Input() minlength: any;

    /** to specify the maximum number for the input  */
    @Input() max: any;

    /** to specify the minuimum number for the input  */
    @Input() min: any;

  /** to display the validation after submit the form */
  @Input() validateAfterSubmit = false;

  /** to display additional error message or not based on this flag */
  @Input() displayAdditionalErrorMessage = false;

  /** the content of the additional error message */
  @Input() additionalErrorMessage = '';

  /** input appearance type */
  @Input() isLegacy = false;

  /** this attribute for preventing user to wite any key on the keyboard does not match the pattern */
  @Input() preventWriting = false;

  @Input() decimalPattern: boolean;

  @Output() lostFocus: EventEmitter<void> = new EventEmitter<void>();

  /** on value changed */
  @Output() changed = new EventEmitter<string>();

  /** on input toggled */
  @Output() toggleElement = new EventEmitter<string>();

  /** To add hint */
  @Input() hint = '';

  /** Hint Alignment */
  @Input() hintAlignment: 'start' | 'end' = 'end';

  /** show spinner inside input field while submitting */
  @Input() isSubmitting = false;

  /** to display eye or not */
  public passwordMode = false;

  /** this variable will hold 'password' or 'text' keyword for input type attribute */
  @Input() public type = 'text';

  /** for hold the inner value for the input */
  private innerValue: string;

  /** this variable will hold the regex pattern */
  public patternValueFromConfig: () => string;

  /** to differentiate between to actions 'onblur' or 'whileWriting' */
  public validationMode = '';

  /** to hold the input value before change in edit mode  */
  private holdInputValue = '';

  /** pre define actions */
  public validationModeObj = {
    onblur: 'onblur',
    whileWriting: 'whileWriting',
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.validateAfterSubmit) {
      if (changes.validateAfterSubmit.currentValue === false) {
        this.validationMode = this.validationModeObj.whileWriting;
      } else {
        this.validationMode = this.validationModeObj.onblur;
      }
    }
  }

  /** function template */
  private onChangeCallback = (_: any) => {};

  /** get accessor including */
  get value(): any {
    return this.innerValue;
  }

  /** set accessor including call the onChange callback */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.changed.emit(v);
    }
  }

  /** function template */
  public validateFn: any = () => {};

  /**
   * first function will fire in the component life cycle
   * @param helperService Responsible for any functions for utilities purpose
   */
  constructor(private inj: Injector,private helperService: HelperService) {}

  /**
   * for component initialization
   */
  ngOnInit() {
    this.type = this.isPassword ? 'password' : this.type;
    this.patternValueFromConfig = () =>
      this.helperService.getRegexByKey(this.patternKey) || this.patternValue;

    if (!this.preventWriting) {
      this.patternValue =
        this.patternValueFromConfig() !== ''
          ? this.patternValueFromConfig()
          : this.patternValue;
    }
    this.validateFn = this.createTextBoxValidator();
  }
  /** Set input focus */
  focus() {
    this.textInput.nativeElement.focus();
  }
  /**
   * on un-focus from input
   */
  onBlur() {
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
    this.onChangeCallback(this.innerValue);
    this.validationMode = this.validationModeObj.onblur;
    this.lostFocus.emit();
  }

  closeEdit() {
    this.isReadOnly = !this.isReadOnly;
    this.innerValue = this.holdInputValue;
  }
  toggleReadOnly() {
    this.holdInputValue = this.innerValue;
    this.isReadOnly = !this.isReadOnly;
    this.toggleElement.emit(this.name);
  }


  isEditMode() {
    return !this.isReadOnly && this.canEditInline;
  }

  @Input() set clearInput(v: boolean) {
    if (v) {
      this.value = '';
    }
  }
  @Input() set resetInput(v: any) {
    if (v) {
      this.value = this.holdInputValue;
    }
  }
  @Input() set onCompleteEdit(v: any) {
    if (v) {
      this.isReadOnly = true;
    }
  }

  @Input() set initValue(v: any) {
    if (v) {
      this.innerValue = v;
    }
  }

  @Input() set isFocused(v: any) {
    if (v) {
      this.textInput.nativeElement.focus();
    }
  }

  //#region implementing ControlValueAccessor

  /**
   * this function form implementing ControlValueAccessor
   * responsible for Writes a new value to the element.
   * @param obj
   */
  writeValue(obj: any): void {
    this.innerValue = obj;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  //#endregion

  //#region implementing ControlValueAccessor

  /**
   * this function form implementing Validator
   * to validate the input based on our criteria
   * @param c
   */
  validate(c: AbstractControl): ValidationErrors {
    return this.validateFn(c);
  }

  //#endregion

  /**
   * custom validation for inputs
   */
  createTextBoxValidator() {
    return (c: FormControl) => {
      const patt = new RegExp(this.patternValueFromConfig());
      if (patt && this.required) {
        return c.value && patt.test(c.value) ? null : { mismatch: true };
      } else if (patt && c.value) {
        return patt.test(c.value) ? null : { mismatch: true };
      } else {
        return null;
      }
    };
  }

  /**
   * toggle between input types 'text' or 'password'
   */
  togglePasswordMode() {
    this.passwordMode = !this.passwordMode;
    this.type = this.passwordMode ? 'text' : 'password';
  }

  preventBasedOnPattern(e: any) {
    if (
      this.preventWriting &&
      e.key.match(this.patternValueFromConfig()) == null
    ) {
      e.preventDefault();
    }
    if (
      this.decimalPattern &&
      !e.key.match(this.helperService.getRegexByKey('decimal'))
    ) {
      e.preventDefault();
    }
    if (
      this.preventWriting &&
      this.patternValue &&
      !e.key.match(this.patternValue)
    ) {
      e.preventDefault();
    }
  }

  // Mark control touched from outside
  markControlTouchedOutside(){
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => { 
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
  }

  ngAfterViewInit() { 
    this.markControlTouchedOutside()
  }
  
}
