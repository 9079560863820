<div
    class="image_container"
    [ngStyle]="{
                'width': styles?.width,
                'height': styles?.height,
                'padding': styles?.padding,
                'margin': styles?.margin,
                'border-radius': styles?.borderRadius
              }"
  >
  <img class="image_container__img" [src]="src" [ngStyle]="{'border-radius': styles?.borderRadius}">
</div>
