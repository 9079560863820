import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  public statusFormControl = new FormControl();

  @Input() title = '';

  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

  @Output() toggleChange: EventEmitter<any> = new EventEmitter<boolean>();

  closeModal() {
    this.modalClosed.emit();
  }
}
