<dot-page-card [title]="title">

  <!-- START OF HEADER CONTROLS -->
  <ng-container class="page-header-controls">

      <!-- EXTERNAL CONTENT -->
      <ng-content select=".page-header-controls"></ng-content>

  </ng-container>
  <!-- END OF HEADER CONTROLS -->

  <!-- START OF PAGE BODY -->
  <ng-container class="page-body">

    <!-- START OF CONTAINER -->
    <div class="tree-page-container">

      <!-- START OF TREE CONTAINER -->
      <aside class="tree-container">

        <!-- EXTERNAL CONTENT -->
        <ng-content select=".tree"></ng-content>

      </aside>
      <!-- END OF TREE CONTAINER -->

      <!-- START OF BODY -->
      <div class="body-container">

        <!-- EXTERNAL CONTENT -->
        <ng-content select=".page-body"></ng-content>

      </div>
      <!-- END OF OF BODY -->

    </div>
    <!-- START OF CONTAINER -->
  </ng-container>
  <!-- END OF PAGE BODY -->

</dot-page-card>
