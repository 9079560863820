/** Contains the key to help get and set info from local storage */
export const LOCAL_STORAGE_PREFIX = 'Segmatek-LocalStore.key';
/** contains the user info when dealing with storage */
export const USER = 'user';
/** contains the user type */
export const USER_TYPE = 'user_type';
/** contains the user types */
export const USER_TYPES = 'user_types';
// tabel listing number of items per page
export const PAGESIZE = 10;
export const PAGE_SIZE_OPTION = [10, 20, 50];
export const INTERVALS = [
  'DAY',
  'WEEK',
  'HALF_MONTH',
  'MONTH',
  'QUARTER',
  'SEMI-YEAR',
  'YEAR',
];

export const PAYMENT_TYPES = ["Individual Prepaid", "Individual Postpaid"];
export const PRICE_TYPES = {
  RecurringProdOfferPriceCharge: 'RecurringProdOfferPriceCharge',
  OneTimeProdOfferPriceCharge: 'OneTimeProdOfferPriceCharge',
  TaxProdOfferPriceAlteration: "TaxProdOfferPriceAlteration"
};
export const CONTACT_MEDUIM = [
  {
    id: 'TelephoneNumber',
    name: 'Phone Number',
    regex: '',
    type: 'number',
    key: 'number',
  },
  {
    id: 'EmailContact',
    name: 'Email',
    regex: 'email',
    type: 'text',
    key: 'eMailAddress',
  },
];

export const ITEMS_TYPES = ['SIM', 'MSISDN', 'Device'];
export const ORDER_DISCRIMINATOR = ['ServiceOrderItem', 'ResourceOrderItem'];
export const LANGUAGES_TYPES = {
  ENGLISH: 'english',
  ARABIC: 'arabic',
};
export const WAREHOUSE_TYPES = ['PhysicalStockLocation' , 'VirtualStockLocation' ]
export const executionStartTime = 30 // 30s

