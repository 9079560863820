import { Component, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { AppBaseComponent } from '@shared/components/base/app-base-component';
import {Options} from 'sortablejs'
import { IsMatchDimensionsDirective } from '@shared/directives/is-match-dimensions.directive';
import { ProductCatalogModelerSettingsComponent } from './product-catalog-modeler-settings/product-catalog-modeler-settings.component';
import { cryptoKey } from '@shared/utilities/security';
import { AES, enc } from 'crypto-js';
import { Product_Catalog_API_URLS, executionStartTime } from '@shared/utilities';

// INTERFACES
import { productCatalogInterface } from './interfaces/productCatalog';
import { entitySpecificationDTO, productCatalogDTO, productOfferingPriceDTO, productOfferingRelationshipDTO } from './interfaces';
import { Observable, finalize, map, shareReplay } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import moment from 'moment';


@Component({
  selector: 'dot-product-catalog-modeler',
  templateUrl: './dot-product-catalog-modeler.component.html',
  styleUrls: ['./dot-product-catalog-modeler.component.scss'],
  providers: [IsMatchDimensionsDirective]
})
export class DotProductCatalogModelerComponent extends AppBaseComponent{

  // Translation Object [Parent Node]
  parentTransName = 'productCatalogue.productCatalogModeler.';

  // FILE INPUT REFERENCE
  @ViewChild('productCatalogFileInput', { static: false }) productCatalogFileInput: ElementRef;


  // EVENTS
  @Output() changeTabName: EventEmitter<any> = new EventEmitter<any>()


  // CONTROLS
  hideOverlay = false
  productCatalogName: string = 'New Product Catalog'


  // HOLDERs
  productOffersDiscriminators: string[] = ['SimpleProductOffering', 'BundledProductOffering']
  specsDiscriminators: string[] = ['NetworkProductSpec', 'GoodsProductSpec', 'UsageVolumeProductSpec']
  pricesDiscriminators: string[] = ['RecurringProdOfferPriceCharge', 'OneTimeProdOfferPriceCharge', 'UsageProdOfferPriceCharge', 'DepositProdOfferPriceCharge']
  priceAlterationsDiscriminators: string[] = ['TaxProdOfferPriceAlteration', 'DiscountProdOfferPriceAlteration']
  productOffersSpecsCompositeServiceSpecsDiscriminators: string[] = ['CustomerFacingServiceSpecComposite']
  productOffersProductCatalogDiscriminators: string[] = ['ProductCatalog']
  productOfferingRelationshipTypes: string[] = ["DEPENDENCY", "EXCLUSIVITY", "SWAP"]
  productOffers: any[] = []
  /**
   * --------------- START OF SIDE MENU HOLDERs ----------------------
   * */

  // -- [01] Product Offers
  productOffersList: productCatalogInterface[] = [
    {
      name: 'Simple Offer',
      data: {
        Discriminator: "SimpleProductOffering",
        name: "Simple Offer Name",
        description: "Simple Offer Description",
        validFor: {
          startDateTime: moment()
          .add(executionStartTime, 'second')
          .toDate()
          .toISOString()
          .split('.')[0],
          endDateTime: "2050-02-01T13:19:35"
        },
        status: "DESIGN",
        isPrimary: true,
        isSellableStandAlone: true,
        isBundle: false,
        sms: false,
        version: "V.0",
      },
      type: 'offer',
      icon: 'simpleProductOffer.svg',
      children: []
    },
    {
      name: 'Bundled Offer',
      data: {
        Discriminator: "BundledProductOffering",
        name: "Bundled Offer Name",
        description: "Bundled Offer Description",
        validFor: {
          startDateTime: moment()
          .add(executionStartTime, 'second')
          .toDate()
          .toISOString()
          .split('.')[0],
          endDateTime: "2050-02-01T13:19:35"
        },
        status: "DESIGN",
        isPrimary: true,
        isSellableStandAlone: true,
        isBundle: true,
        sms: false,
        version: "V.0",
      },
      type: 'offer',
      icon: 'bundleProductOffer.svg',
      children: []
    }
  ]

  productOfferingRelationship: productCatalogInterface[] = [
    {
      name: 'DEPENDENCY',
      data: {
        type: "DEPENDENCY",
        validFor: {
          startDateTime: "2023-02-01T13:19:35+00:00",
          endDateTime: "2050-02-01T13:19:35+00:00"
        },
        productOffering : {}
      },
      type: 'offerRelationship',
      icon: 'simpleProductOffer.svg',
      children: []
    },
    {
      name: 'EXCLUSIVITY',
      data: {
        type: "EXCLUSIVITY",
        validFor: {
          startDateTime: "2023-02-01T13:19:35+00:00",
          endDateTime: "2050-02-01T13:19:35+00:00"
        },
        productOffering : {
          Discriminator : "SimpleProductOffering",
          id: 101
        }
      },
      type: 'offerRelationship',
      icon: 'simpleProductOffer.svg',
      children: []
    },
    {
      name: 'SWAP',
      data: {
        type: "SWAP",
        validFor: {
          startDateTime: "2023-02-01T13:19:35+00:00",
          endDateTime: "2050-02-01T13:19:35+00:00"
        },
        productOffering : {
          Discriminator : "SimpleProductOffering",
          id: 101
        }
      },
      type: 'offerRelationship',
      icon: 'simpleProductOffer.svg',
      children: []
    },
  ]

  // -- [02] Product Specifications
  productOffersSpecifications: productCatalogInterface[] = [
    {
      name: 'Network',
      data: {
        Discriminator: "NetworkProductSpec",
        name: "National Voice Network",
        description: "Minutes to be used for national calls",
        validFor: {
          startDateTime: moment()
          .add(executionStartTime, 'second')
          .toDate()
          .toISOString()
          .split('.')[0],
          endDateTime: "2050-02-01T13:19:35"
        },
        status: "ACTIVE",
        brand: "Syriatel",
        isShared: false,
        entitySpecificationType: [{
          Discriminator: "ProductLine",
          id: 7,
          name: "Mobile Line",
          description: "Contain product offering for mobile"
        }]
      },
      type: 'specification',
      icon: 'networkProductSpec.svg',
      children: []
    },
    {
      name: 'Goods',
      data: {
        Discriminator: "GoodsProductSpec",
        name: "National Voice Network",
        description: "Minutes to be used for national calls",
        validFor: {
          startDateTime: moment()
          .add(executionStartTime, 'second')
          .toDate()
          .toISOString()
          .split('.')[0],
          endDateTime: "2050-02-01T13:19:35"
        },
        status: "ACTIVE",
        brand: "Syriatel",
        isShared: false,
        entitySpecificationType: [{
          Discriminator: "ProductLine",
          id: 7,
          name: "Mobile Line",
          description: "Contain product offering for mobile"
        }]
      },
      type: 'specification',
      icon: 'goodsProductSpec.svg',
      children: []
    },
    {
      name: 'Usage Volume',
      data: {
        Discriminator: "UsageVolumeProductSpec",
        name: "National Voice Network",
        description: "Minutes to be used for national calls",
        validFor: {
          startDateTime: moment()
          .add(executionStartTime, 'second')
          .toDate()
          .toISOString()
          .split('.')[0],
          endDateTime: "2050-02-01T13:19:35"
        },
        status: "ACTIVE",
        brand: "Syriatel",
        isShared: false,
        entitySpecificationType: [{
          Discriminator: "ProductLine",
          id: 7,
          name: "Mobile Line",
          description: "Contain product offering for mobile"
        }]
      },
      type: 'specification',
      icon: 'usageVolumeProductSpec.svg',
      children: []
    },
  ]

  // -- [03] Product Prices
  productOffersPrices: productCatalogInterface[] = [
    {
      name: 'Recurring Charge',
      data: {
        Discriminator: "RecurringProdOfferPriceCharge",
        name: "Monthly Recurring Price for YAHALA_ACT",
        description: "Monthly Recurring Price for YAHALA_ACT",
        validFor: {
          startDateTime: moment()
          .add(executionStartTime, 'second')
          .toDate()
          .toISOString()
          .split('.')[0],
          endDateTime: "2050-02-01T13:19:35"
        },
        billCode: 0,
        rounding: 0,
        priority: 0,
        isRefundable: false,
        isBackward: false,
        isProrated: false,
        priceType: "RECURRING",
        unitOfMeasure: "price",
        price: "120",
        period: "MONTHLY",
        applicationDuration: "MONTHLY"
      },
      type: 'productPrice',
      icon: 'recurringProdOfferPriceCharge.svg',
      children: []
    },
    {
      name: 'OneTime Charge',
      data: {
        Discriminator: "OneTimeProdOfferPriceCharge",
        name: "SIM Card Price",
        description: "SIM Card Price",
        validFor: {
          startDateTime: moment()
          .add(executionStartTime, 'second')
          .toDate()
          .toISOString()
          .split('.')[0],
          endDateTime: "2050-02-01T13:19:35"
        },
        billCode: 0,
        rounding: 0,
        priority: 0,
        isRefundable: false,
        isBackward: false,
        isProrated: false,
        priceType: "ONE_TIME",
        unitOfMeasure: "price",
        price: "50"
      },
      type: 'productPrice',
      icon: 'oneTimeProdOfferPriceCharge.svg',
      children: []
    },
  ]
  productOffersPriceAlterations: productCatalogInterface[] = [
    {
      name: 'Tax',
      data: {
          Discriminator: "TaxProdOfferPriceAlteration",
          name: "Sales Tax",
          description: "Product Sales Tax",
          validFor: {
              startDateTime: "2024-06-26T13:19:35",
              endDateTime: "2030-04-26T13:19:35"
          },
          billCode: 0,
          rounding: 0,
          priority: 1,
          isRefundable: false,
          isBackward: false,
          isProrated: false,
          productOffering: [],
          productOfferingTerm: [],
          productOfferingPrice: [],
          priceType: "Tax",
          unitOfMeasure: "price",
          price: 0.0,
          applicationDuration: 1,
          percentage: null,
          taxDefinition: {}
      },
      type: 'productPriceAlteration',
      icon: 'taxProdOfferPriceAlteration.svg',
      children: []
    },
    {
      name: 'Discount',
      data: {
        Discriminator: "DiscountProdOfferPriceAlteration",
        name: "Discount",
        description: "Discount based on Tariff Profile",
        validFor: {
            startDateTime: "2024-06-26T13:19:35",
            endDateTime: "2030-04-26T13:19:35"
        },
        billCode: 0,
        rounding: 0,
        priority: 1,
        isRefundable: false,
        isBackward: false,
        isProrated: false,
        productOffering: null,
        productOfferingTerm: [],
        productOfferingPrice: [],
        priceType: "Discount",
        unitOfMeasure: "price",
        price: 0.0,
        applicationDuration: 1,
        percentage: null,
        tariffProfile: {}
      },
      type: 'productPriceAlteration',
      icon: 'discountProdOfferPriceAlteration.svg',
      children: []
    },
  ]

  // -- [04] Product Specifications: Resource Specifications
  productOffersSpecsResourceSpec : productCatalogInterface[] = []

  // -- [05] Product Specifications: Service Specifications
  productOffersSpecsAtomicServiceSpecs: productCatalogInterface[] = []
  productOffersSpecsCompositeServiceSpecs: productCatalogInterface[] = []

  // -- [06] Product Specifications: Specifications Types
  productOffersSpecsTypesProductLines: productCatalogInterface[] = []
  productOffersSpecsTypesProductCategories: productCatalogInterface[] = []

  // -- [07] Sales channels
  productOffersSalesChannels: productCatalogInterface[] = []

  // -- [08] Product Catalog
  productOffersProductCatalog : productCatalogInterface[] = []

  // --- [09] Pre-Defined Product Offers
  preDefinedProductOffersList : productCatalogInterface[] = []

  /**
   * --------------- END OF SIDE MENU HOLDERs ----------------------
   * */

  // FLAGS
  PAGE = this.preDefinedProductOffersList.length
  SIZE = 5
  IS_LOADING_OFFERS = false
  MaxPreDefinedOffersReached = false

  /**
   * -- [04]
   * LOAD RESOURCE OFFER SPECs THEN CONVERT
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  getProductOffersSpecsResourceSpec(){

    // SEND REQUEST
    this.httpService
    .getData(Product_Catalog_API_URLS.GET_RESOURCE_CATALOG_SPECS)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {
        this.productOffersSpecsResourceSpec = this.reverseTree(data)
      }
    })
  }

  /**
   * -- [05.1]
   * LOAD SERVICE OFFER ATOMIC SPECs THEN CONVERT
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  getProductOffersSpecsAtomicServiceSpecs(){

    // SEND REQUEST
    this.httpService
    .getData(Product_Catalog_API_URLS.GET_SERVICE_CATALOG_ATOMIC_SPECS)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {
        this.productOffersSpecsAtomicServiceSpecs = this.reverseTree([data])
      }
    })
  }

  /**
   * -- [05.2]
   * LOAD SERVICE OFFER COMPOSITE SPECs THEN CONVERT
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  getProductOffersSpecsCompositeServiceSpecs(){

    // SEND REQUEST
    this.httpService
    .getData(Product_Catalog_API_URLS.GET_SERVICE_CATALOG_COMPOSITE_SPECS)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {
        this.productOffersSpecsCompositeServiceSpecs = this.reverseTree(data)
      }
    })
  }

  /**
   * -- [06.1]
   * LOAD PRODUCT OFFER PRODUCT LINE THEN CONVERT
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  getProductOffersSpecsTypesProductLines(){

    // SEND REQUEST
    this.httpService
    .getData(Product_Catalog_API_URLS.GET_PRODUCT_CATALOG_PRODUCT_LINES)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {
        this.productOffersSpecsTypesProductLines = this.reverseTree(data)
      }
    })
  }

  /**
   * -- [06.2]
   * LOAD PRODUCT OFFER PRODUCT CATEGORIES THEN CONVERT
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  getProductOffersSpecsTypesProductCategories(){

    // SEND REQUEST
    this.httpService
    .getData(Product_Catalog_API_URLS.GET_PRODUCT_CATALOG_PRODUCT_CATEGORIES)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {
        this.productOffersSpecsTypesProductCategories = this.reverseTree(data)
      }
    })
  }

  /**
   * -- [07]
   * LOAD PRODUCT OFFER SALES CHANNELS THEN CONVERT
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  getProductOffersSalesChannels(){

    // SEND REQUEST
    this.httpService
    .getData(Product_Catalog_API_URLS.GET_PRODUCT_CATALOG_SALES_CHANNELS)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {
        this.productOffersSalesChannels = this.reverseTree(data)
      }
    })
  }

  /**
   * -- [08]
   * LOAD PRODUCT OFFER PRODUCT CATALOG THEN CONVERT
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  getProductOffersProductCatalog(){

    // SEND REQUEST
    this.httpService
    .getData(Product_Catalog_API_URLS.GET_PRODUCT_CATALOG_PRODUCT_CATALOG)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {
        this.productOffersProductCatalog = this.reverseTree(data)
      }
    })
  }

  /**
   * -- [09]
   * FORCE LOAD PRE-DEFINED
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  loadPreDefinedProductOffers(event?: any){

    // STOP PROPAGATION
    if(event) event?.stopPropagation()

    // CLEAR THE CURRENT LIST
    this.preDefinedProductOffersList = []

    // RESET MAX LOAD
    this.MaxPreDefinedOffersReached = false

    // LOAD OFFERS
    this.getPreDefinedProductOffers(0, this.SIZE)

  }

  /**
   * -- [09]
   * LOAD PRE-DEFINED OFFERS THEN CONVERT
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  getPreDefinedProductOffers(page: number, size: number, event?: any){

    // STOP PROPAGATION
    if(event) event?.stopPropagation()

    // SET THE LOADING FLAG
    this.IS_LOADING_OFFERS = true


    const headers = {
      'Page-Number': page.toString(),
      'Page-Size': size.toString()
    }

    // SEND REQUEST
    this.httpService
    .getDataWithHeaders(Product_Catalog_API_URLS.productOffering, headers)
    .pipe(this.takeUntilDestroy())
    .pipe(finalize(() => (this.IS_LOADING_OFFERS = false)))
    .subscribe({
      next: (data: any) => {

        const transformedData: productCatalogInterface[] = this.reverseTree(data?.map((el) => ({
          ...el,
          downloaded: false
        })))
        this.preDefinedProductOffersList.push(...transformedData)

      },
      error: (error: any) => {
        this.MaxPreDefinedOffersReached = true
      }
    })
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {

    if(event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10) && !this.IS_LOADING_OFFERS && !this.MaxPreDefinedOffersReached){
      this.getPreDefinedProductOffers(this.preDefinedProductOffersList?.length || 0, this.SIZE)
    }
  }

  /**
   * -- [09]
   * LOAD PRE-DEFINED OFFERS THEN CONVERT
   * THE LOADED DATA TO MATCH THE (productCatalogInterface)
   * STRUCTURE
   */
  getPreDefinedProductOffer(offer, index) {

    this.httpService
    .getData(`${Product_Catalog_API_URLS.productOffering}${offer?.data?.id}`)
    .pipe(shareReplay())
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {

        // TRANSFORM THE OBJECT
        const transferredData = this.reverseTree([data])[0]

        // RESET THE DOWNLOAD FLAG
        transferredData.data.downloaded = true

        // UPDATE OFFER
        this.preDefinedProductOffersList[index] = transferredData

      }
    })
  }

  /**
   * VALIDATE IF THE OFFER IS VALID OF NOT
   * [REQUIRED ACTION FROM BACKEND]
   * @param offer
   * @returns
   */
  validateOffer(offer): boolean{
    return true
  }

  /**
   * CREATE PRODUCT OFFER
   * @param offer
   */
  createProductOffer(offer){
    this.httpService
    .postData(Product_Catalog_API_URLS.CREATE_PRODUCT_OFFER, offer)
    .pipe(this.takeUntilDestroy())
    .subscribe({
      next: (data: any) => {
        this.getPreDefinedProductOffers(this.PAGE, this.SIZE)
        this.notificationService.displaySuccessMessage("Offer Created Successfully")
      }
    })
  }

  /**
   * SUBMIT TREE AND CONVERT IT TO BE A VALID OFFER
   */
  onSubmit(){

    // GET THE CURRENT TREE OFFERs
    const offers = this.convertTree()

    for(let offer of offers[0]){
      // SEND CREATION REQUEST
      this.createProductOffer(offer)
    }
  }


  ngOnInit(): void {

    // -- [04] LOAD RESOURCE CATALOG SPECs
    this.getProductOffersSpecsResourceSpec()

    // -- [05] LOAD SERVICE CATALOG SPECs
    this.getProductOffersSpecsAtomicServiceSpecs()
    this.getProductOffersSpecsCompositeServiceSpecs()

    // -- [06] LOAD PRODUCT SPECIFICATIONS TYPES
    this.getProductOffersSpecsTypesProductLines()
    this.getProductOffersSpecsTypesProductCategories()

    // -- [07] LOAD SALES CHANNELS
    this.getProductOffersSalesChannels()

    // -- [08] LOAD PRODUCT CATALOG
    this.getProductOffersProductCatalog()

    // -- [09] LOAD PRE-DEFINED OFFERs
    this.getPreDefinedProductOffers(this.PAGE, this.SIZE)

  }

  /**
   * CONSTRUCTOR
   * @param productCatalogueIService
   * @param isMatchDimensionsDirective
   */
  constructor(
    private isMatchDimensionsDirective: IsMatchDimensionsDirective,
  )
  {
    super()
  }

  /**
   * LOAD THE UPLOADED CATALOG FILE
   * @param event
   */
  handleFileInput(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {


      try{
        // DECRYPT THE TREE
        const productCatalogFile = this.decryptObject(e.target.result);

        // SET THE LOADED TREE
        this.nestedItems = productCatalogFile

        // CLEAR THE INPUT FIELD AFTER SUCCESSFULLY READING THE FILE
        this.productCatalogFileInput.nativeElement.value = '';

      }catch (error){
        // ERROR NOTIFICATION
        this.notificationService.displayErrorToastr(`Error parsing Catalog file: ${error}`)
        return false
      }


      // SET THE FILE NAME
      this.productCatalogName = file.name.split('.')[0]

      // HIDE THE SPLASH SCREEN ON CREATE
      this.hideOverlay = true

      // Change Tab Name
      this.changeTabName.emit(this.productCatalogName)

    };
    reader.readAsText(file);
  }

  // CHOOSE PRODUCT CATALOG FILE
  openProductCatalogDialog(openProductCatalogFile: HTMLInputElement): void {
    openProductCatalogFile.click();
  }

  /**
   * DOWNLOAD THE PRODUCT CATALOG TREE
   * AS JSON FILE WITH (.catalog) EXTENSION
   */
  downloadProductCatalog(){

    // ENCRYPT THE TREE
    const json = this.encryptObject(this.nestedItems)

    // CREATE THE BLOB OF TYPE JSON
    const blob = new Blob([json], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);

    // SET THE LINK & DOWNLOAD
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.productCatalogName}.catalog`;
    a.click();

    // RELEASE THE DATA (Clear url from memory)
    window.URL.revokeObjectURL(url);
  }

  /**
   * Encrypt the object
   * @param data
   * @returns
   */
  encryptObject(data) {
    const jsonString = JSON.stringify(data);
    return AES.encrypt(jsonString, cryptoKey).toString();
  }

  /**
   * Decrypt the object
   * @param data
   * @returns
   */
  decryptObject(data) {
    const decryptedBytes = AES.decrypt(data, cryptoKey);
    const decryptedText = decryptedBytes.toString(enc.Utf8);
    return JSON.parse(decryptedText);
  }

  /**
   * RESET THE CATALOG TREE
   */
  clearProductCatalog(){

    // RESET THE PRODUCT CATALOG
    this.nestedItems = [
      {
        name: 'ROOT',
        type: 'root',
        children: [],
      },
    ];

  }

  /**
   * CREATE / UPDATE THE CURRENT CATALOG
   * DETAILS (ex: Catalog name)
   */
  createProductCatalogDiagram(data?){
    const settingsDialog = this.dialogService.open(ProductCatalogModelerSettingsComponent, {
      width: '600px',
      disableClose: true,
      data: data? {name: data, isUpdateForm: true} : {isUpdateForm: false}
    });

    settingsDialog.afterClosed().pipe(this.takeUntilDestroy()).subscribe((res: string)=>{
      if(res){

        // Change BPMN Name
        this.productCatalogName = res

        // HIDE THE SPLASH SCREEN ON CREATE
        if(!data) this.hideOverlay = true;

        // Change Tab Name
        this.changeTabName.emit(res)

      }
    })
  }

  /**
   * CHECK IF THE TREE IS MATCH THE CONTAINER
   * NOTE: FOR DESIGN PURPOSE
   * @param element1
   * @param element2
   * @returns
   */
  checkIfContainersMatched(element1: HTMLElement,element2: HTMLElement){
    return this.isMatchDimensionsDirective?.checkMatching(element1,element2)
  }


  // PRODUCT CATALOG TREE
  nestedItems: productCatalogInterface[] = [
    {
      name: 'ROOT',
      type: 'root',
      children: [],
    },
  ];

  /**
   * TO CREATE NEW REFERENCE FROM THE CLONED OBJECT
   * @param obj
   * @returns JSON
   */
  private deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  // CLONING FUNCTION
  cloneCustomization = (item) => {
    return this.deepClone(item)
  }



  // SIDE MENU NODEs CLONE OPTIONS
  productsOffersCloneOptions: Options = {
    group: {
      name: 'products-offers-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };

  productsSpecsCloneOptions: Options = {
    group: {
      name: 'products-specs-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };

  productsPricesCloneOptions: Options = {
    group: {
      name: 'products-prices-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  productOffersPriceAlterationsCloneOptions: Options = {
    group: {
      name: 'products-prices-alterations-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };

  productOffersSpecsResourceSpecCloneOptions: Options = {
    group: {
      name: 'product-offers-specs-resource-spec-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  productOffersSpecsAtomicServiceSpecsCloneOptions: Options = {
    group: {
      name: 'product-offers-specs-atomic-service-specs-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  productOffersSpecsCompositeServiceSpecsCloneOptions: Options = {
    group: {
      name: 'product-offers-specs-composite-service-specs-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  productOffersSpecsTypesProductLinesCloneOptions: Options = {
    group: {
      name: 'product-offers-specs-types-product-lines-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  productOffersSpecsTypesProductCategoriesCloneOptions: Options = {
    group: {
      name: 'product-offers-specs-types-product-categories-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  productOffersSalesChannelsCloneOptions: Options = {
    group: {
      name: 'product-offers-sales-channels-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  productOffersProductCatalogCloneOptions: Options = {
    group: {
      name: 'product-offers-product-catalog-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };
  productsRelationCloneOptions: Options = {
    group: {
      name: 'product-offers-relationship-clone-group',
      pull: 'clone',
      put: false,
    },
    sort: false
  };

  /**
   * REMOVE NODE TRIGGER
   * @param itemToRemove
   */
  removeNode(itemToRemove: productCatalogInterface) {
    this.nestedItems = this.removeNestedItem(this.nestedItems, itemToRemove);
  }

  /**
   * REMOVE NODE EXECUTER
   * @param items
   * @param itemToRemove
   * @returns
   */
  removeNestedItem(items: productCatalogInterface[], itemToRemove: productCatalogInterface): productCatalogInterface[] {
    return items.filter((item) => {
      if (item === itemToRemove) {
        return false;
      } else if (item.children) {
        item.children = this.removeNestedItem(item.children, itemToRemove);
        return true;
      }
      return true;
    });
  }


  // --------------- EXPORT TREE
  /**
   * TRANSFORM CATALOG TREE NODEs TO BE A VALID
   * REQUEST OBJECT
   * @param node
   * @returns
   */
  transformTree(node: any): (productCatalogDTO | entitySpecificationDTO | productOfferingPriceDTO | productOfferingRelationshipDTO) {

    switch (node.type){
      case "offer":{

        // SET THE OFFER ATTRIBUTES
        const transformedNode: productCatalogDTO = {
          Discriminator : node.data?.Discriminator,
          name : node.data?.name,
          description : node.data?.description,
          status : node.data?.status,
          validFor : {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
          isPrimary : node.data?.isPrimary,
          isSellableStandAlone : node.data?.isSellableStandAlone,
          isBundle : node.data?.isBundle,
          sms : node.data?.sms,
          version : node.data?.version,
        };

        // GET THE OFFER SPECs
        const entitySpecification = node.children
        ?.filter((child: any) => child.type === 'specification')
        .map((child: any) => this.transformTree(child));

        // GET THE OFFER PRICEs
        const productOfferingPrice = node.children
        ?.filter((child: any) => child.type === 'productPrice')
        .map((child: any) => this.transformTree(child));

        // GET THE OFFER RELATIONSHIPS
        const productOfferingRelationship = node.children
        ?.filter((child: any) => child.type === 'offerRelationship')
        .map((child: any) => this.transformTree(child));

        // SET THE OFFER SPECs
        if (entitySpecification && entitySpecification.length > 0) {
          transformedNode.entitySpecification = entitySpecification;
        }

        // SET THE OFFER PRICEs
        if (productOfferingPrice && productOfferingPrice.length > 0) {
          transformedNode.productOfferingPrice = productOfferingPrice;
        }

        // SET THE OFFER RELATIONSHIPS
        if (productOfferingRelationship && productOfferingRelationship.length > 0) {
          transformedNode.productOfferingRelationship = productOfferingRelationship;
        }

        // RETURN THE OFFER
        return transformedNode;
      }

      case "specification":{

        // SET THE SPEC ATTRIBUTES
        const transformedNode: entitySpecificationDTO = {
          Discriminator: node.data?.Discriminator,
          name: node.data?.name,
          description: node.data?.description,
          status: node.data?.status,
          validFor: {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
          brand: node.data?.brand,
          isShared: node.data?.isShared,
          entitySpecificationType: node.data?.entitySpecificationType,
        };

        // GET THE SPEC SPECs
        const specChildren = node.children
        ?.map((child: any) => this.transformTree(child));

        // SET THE SPEC SPECs
        if (specChildren && specChildren.length > 0) {
          transformedNode.entitySpecification = specChildren
        }

        // RETURN THE SPEC
        return transformedNode;
      }

      case "productPrice": {

        // SET THE PRICE ATTRIBUTES
        const transformedNode: productOfferingPriceDTO = {
          Discriminator: node.data?.Discriminator,
          name: node.data?.name,
          description: node.data?.description,
          validFor: {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
          priceType: node.data?.priceType,
          unitOfMeasure: node.data?.unitOfMeasure,
          price: node.data?.price,
          period: node.data?.period,
          applicationDuration: node.data?.applicationDuration,
          billCode: node.data?.billCode,
          rounding: node.data?.rounding,
          priority: node.data?.priority,
          isRefundable: node.data?.isRefundable,
          isBackward: node.data?.isBackward,
          isProrated: node.data?.isProrated,
        };

        // GET THE PRICE PRICEs
        const priceChildren = node.children
        ?.map((child: any) => this.transformTree(child));

        // SET THE PRICE PRICEs
        if (priceChildren && priceChildren.length > 0) {
          transformedNode.productOfferingPrice = priceChildren;
        }

        // RETURN THE PRICE
        return transformedNode;
      }

      case "productPriceAlteration": {

        // SET THE PRICE ATTRIBUTES
        const transformedNode: productOfferingPriceDTO = {
          Discriminator: node.data?.Discriminator,
          name: node.data?.name,
          description: node.data?.description,
          validFor: {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
          priceType: node.data?.priceType,
          unitOfMeasure: node.data?.unitOfMeasure,
          price: node.data?.price,
          applicationDuration: node.data?.applicationDuration,
          percentage: node.data?.percentage,
          productOfferingTerm: node.data?.productOfferingTerm,
          productOffering: node.data?.productOffering,
          billCode: node.data?.billCode,
          rounding: node.data?.rounding,
          priority: node.data?.priority,
          isRefundable: node.data?.isRefundable,
          isBackward: node.data?.isBackward,
          isProrated: node.data?.isProrated,
        };

        // GET THE PRICE PRICEs
        const priceChildren = node.children
        ?.map((child: any) => this.transformTree(child));

        // SET THE PRICE PRICEs
        if (priceChildren && priceChildren.length > 0) {
          transformedNode.productOfferingPrice = priceChildren;
        }

        switch(node.data?.Discriminator){
          case "TaxProdOfferPriceAlteration": {
            transformedNode.taxDefinition = node.data?.taxDefinition
            break;
          }
          case "DiscountProdOfferPriceAlteration":{
            transformedNode.tariffProfile = node.data?.tariffProfile
            break;
          }
        }

        // RETURN THE PRICE
        return transformedNode;
      }

      case "offerRelationship":{
        // SET THE OFFER ATTRIBUTES
        const transformedNode: productOfferingRelationshipDTO = {
          type : node.data?.type,
          productOffering : node.data?.productOffering,
          validFor : {
            startDateTime: node.data?.validFor?.startDateTime,
            endDateTime: node.data?.validFor?.endDateTime,
          },
        };

        // RETURN THE PRICE
        return transformedNode;
      }
    }

  }

  /**
   * CONVERT THE TREE TO BE A VALID REQUEST OBJECT
   */
  convertTree(): any {

    const transformedObject = this.nestedItems.map((rootNode) => {
      if (rootNode.type === 'root' && rootNode.children) {
        return rootNode.children.map((offerNode) => this.transformTree(offerNode));
      } else {
        return this.transformTree(rootNode);
      }
    });

    return transformedObject
  }


  /**
   * REVERSE TRANSFORMATION OF THE CATALOG OBJECT TO
   * BE A VALID NODE
   * @param transformedNode
   * @returns
   */
  reverseTransform(transformedNode: any): productCatalogInterface {

    // EMPTY VALIDATION
    if (!transformedNode) {
      return null;
    }

    // SET THE MANDATORY DATA
    const node: any = {
      name: transformedNode.name,
      children: [],
      data: {},
    };

    // SET THE NODE TYPE
    if(this.productOffersDiscriminators.includes(transformedNode?.Discriminator)) node.type = 'offer';
    if(this.specsDiscriminators.includes(transformedNode?.Discriminator)) node.type = 'specification';
    if(this.pricesDiscriminators.includes(transformedNode?.Discriminator)) node.type = 'productPrice';
    if(this.priceAlterationsDiscriminators.includes(transformedNode?.Discriminator)) node.type = 'productPriceAlteration';
    if(this.productOffersProductCatalogDiscriminators.includes(transformedNode?.Discriminator)) node.type = 'productOffersProductCatalog';
    if(this.productOffersSpecsCompositeServiceSpecsDiscriminators.includes(transformedNode?.Discriminator)) node.type = 'productOffersSpecsCompositeServiceSpecs';
    if(this.productOfferingRelationshipTypes.includes(transformedNode?.type)) node.type = 'offerRelationship';
    if (!node.type) node.type = 'offer';


    switch(node.type){
      case "offer" : {
        // SET THE OFFER ATTRIBUTES
        node.data.id = transformedNode?.id;
        node.data.downloaded = transformedNode?.downloaded;
        node.data.Discriminator = transformedNode?.Discriminator;
        node.data.name = transformedNode?.name;
        node.data.description = transformedNode?.description;
        node.data.validFor = transformedNode?.validFor;
        node.data.status = transformedNode?.status;
        node.data.isPrimary = transformedNode?.isPrimary;
        node.data.isSellableStandAlone = transformedNode?.isSellableStandAlone;
        node.data.isBundle = transformedNode?.isBundle;
        node.data.sms = transformedNode?.sms;
        node.data.version = transformedNode?.version;

        // SET THE OFFER ENTITY SPECs
        if (transformedNode.entitySpecification) {
          node.children = transformedNode.entitySpecification.map((spec) => this.reverseTransform(spec));
        }

        // SET THE OFFER ENTITY PRICEs
        if (transformedNode.productOfferingPrice) {
          node.children.push(...transformedNode.productOfferingPrice.map((price) => this.reverseTransform(price)));
        }

        // SET THE OFFER RELATIONSHIPS
        if (transformedNode.productOfferingRelationship) {
          // const tempProductOfferingRelationship = [transformedNode.productOfferingRelationship]
          node.children.push(...transformedNode.productOfferingRelationship.map((relation) => this.reverseTransform(relation)));
        }

        // SET OFFER ICON
        switch(node.data.Discriminator){
          case "SimpleProductOffering": {
            node.icon = 'simpleProductOffer.svg';
            break;
          }
          case "BundledProductOffering": {
            node.icon = 'bundleProductOffer.svg';
            break;
          }
          default: {
            node.icon = 'x.svg';
            break;
          }
        }

        // RETURN OFFER
        return node;
      }
      case "specification" : {

        // SET THE SPEC ATTRIBUTES
        node.data.Discriminator = transformedNode?.Discriminator;
        node.data.name = transformedNode?.name;
        node.data.description = transformedNode?.description;
        node.data.validFor = transformedNode?.validFor;
        node.data.status = transformedNode?.status;
        node.data.brand = transformedNode?.brand;
        node.data.isShared = transformedNode?.isShared;
        node.data.entitySpecificationType = transformedNode?.entitySpecificationType;

        // SET THE SPEC ENTITY SPECs
        if (transformedNode.entitySpecification) {
          node.children = transformedNode.entitySpecification.map((spec) => this.reverseTransform(spec));
        }

        // SET SPEC ICON
        switch(node.data.Discriminator){
          case "NetworkProductSpec": {
            node.icon = 'networkProductSpec.svg';
            break;
          }
          case "GoodsProductSpec": {
            node.icon = 'goodsProductSpec.svg';
            break;
          }
          case "UsageVolumeProductSpec": {
            node.icon = 'usageVolumeProductSpec.svg';
            break;
          }
          default: {
            node.icon = 'x.svg';
          }
        }

        // RETURN SPEC
        return node;
      }
      case "productPrice" : {

        // SET THE PRICE ATTRIBUTES
        node.data.Discriminator = transformedNode?.Discriminator;
        node.data.name = transformedNode?.name;
        node.data.description = transformedNode?.description;
        node.data.validFor = transformedNode?.validFor;
        node.data.priceType = transformedNode?.priceType;
        node.data.unitOfMeasure = transformedNode?.unitOfMeasure;
        node.data.price = transformedNode?.price;
        node.data.period = transformedNode?.period;
        node.data.applicationDuration = transformedNode?.applicationDuration;
        node.data.billCode = transformedNode?.billCode;
        node.data.rounding = transformedNode?.rounding;
        node.data.priority = transformedNode?.priority;
        node.data.isRefundable = transformedNode?.isRefundable;
        node.data.isBackward = transformedNode?.isBackward;
        node.data.isProrated = transformedNode?.isProrated;

        // SET THE PRICE ENTITY PRICEs
        if (transformedNode.productOfferingPrice) {
          node.children.push(...transformedNode.productOfferingPrice.map((price) => this.reverseTransform(price)));
        }

        // SET PRICE ICON
        switch(node.data.Discriminator){
          case "RecurringProdOfferPriceCharge": {
            node.icon = 'recurringProdOfferPriceCharge.svg';
            break;
          }
          case "OneTimeProdOfferPriceCharge": {
            node.icon = 'oneTimeProdOfferPriceCharge.svg';
            break;
          }
          default: {
            node.icon = 'x.svg';
          }
        }

        // RETURN PrRICE
        return node;
      }
      case "productPriceAlteration" : {

        // SET THE PRICE ATTRIBUTES
        node.data.Discriminator = transformedNode?.Discriminator;
        node.data.name = transformedNode?.name;
        node.data.description = transformedNode?.description;
        node.data.validFor = transformedNode?.validFor;
        node.data.priceType = transformedNode?.priceType;
        node.data.unitOfMeasure = transformedNode?.unitOfMeasure;
        node.data.price = transformedNode?.price;
        node.data.applicationDuration = transformedNode?.applicationDuration;
        node.data.percentage = transformedNode?.percentage;
        node.data.productOfferingTerm = transformedNode?.productOfferingTerm;
        node.data.productOffering = transformedNode?.productOffering;
        node.data.billCode = transformedNode?.billCode;
        node.data.rounding = transformedNode?.rounding;
        node.data.priority = transformedNode?.priority;
        node.data.isRefundable = transformedNode?.isRefundable;
        node.data.isBackward = transformedNode?.isBackward;
        node.data.isProrated = transformedNode?.isProrated;

        // SET THE PRICE ENTITY PRICEs
        if (transformedNode.productOfferingPrice) {
          node.children.push(...transformedNode.productOfferingPrice.map((price) => this.reverseTransform(price)));
        }

        // SET PRICE ADDITIONAL DATA
        switch(node.data.Discriminator){
          case "TaxProdOfferPriceAlteration": {
            node.data.taxDefinition = transformedNode.taxDefinition
            node.icon = 'taxProdOfferPriceAlteration.svg';
            break;
          }
          case "DiscountProdOfferPriceAlteration": {
            node.data.tariffProfile = transformedNode.tariffProfile
            node.icon = 'discountProdOfferPriceAlteration.svg';
            break;
          }
          default: {
            node.icon = 'x.svg';
          }
        }

        // RETURN PrRICE
        return node;
      }
      case "productOffersProductCatalog" : {

        // SET THE CATALOG ATTRIBUTES
        node.data.Discriminator = transformedNode?.Discriminator;
        node.data.name = transformedNode?.name;
        node.data.description = transformedNode?.description;
        node.data.validFor = transformedNode?.validFor;
        node.name = `${transformedNode?.type} Catalog`.toUpperCase()
        node.data.id = transformedNode?.id;
        node.data.type = transformedNode?.type;
        node.data.version = transformedNode?.version;
        node.data.lifecycleStatus = transformedNode?.lifecycleStatus;
        node.data.catalogSpecification = transformedNode?.catalogSpecification;


        // SET PRICE ICON
        switch(node.data.type){
          case "web": {
            node.icon = 'webProductOffersProductCatalog.svg';
            break;
          }
          default: {
            node.icon = 'productOffersProductCatalog.svg';
          }
        }

        // RETURN CATALOG
        return node;
      }
      case "productOffersSpecsCompositeServiceSpecs" : {

        // SET THE COMPOSITE SERVICE ATTRIBUTES
        node.data.Discriminator = transformedNode?.Discriminator;
        node.data.name = transformedNode?.name;
        node.data.description = transformedNode?.description;
        node.data.validFor = transformedNode?.validFor;
        node.data.id = transformedNode?.id;
        node.data.status = transformedNode?.status;
        node.data.entitySpecCharUse = transformedNode?.entitySpecCharUse;
        node.data.serviceSpecificationType = transformedNode?.serviceSpecificationType;
        node.data.serviceSpecCharUse = transformedNode?.serviceSpecCharUse;
        node.data.resourceFacingServiceSpec = transformedNode?.resourceFacingServiceSpec;
        node.data.customerFacingServiceSpec = transformedNode?.customerFacingServiceSpec;

        // SET COMPOSITE SERVICE ICON
        switch(node.data.Discriminator){
          case "CustomerFacingServiceSpecComposite": {
            node.icon = 'customerFacingServiceSpecComposite.svg';
            break;
          }
          default: {
            node.icon = 'x.svg';
          }
        }

        // RETURN COMPOSITE SERVICE
        return node;
      }
      case "offerRelationship" : {

        // SET THE RELATION ATTRIBUTES
        node.data.type = transformedNode?.type;
        node.data.productOffering = transformedNode?.productOffering;
        node.data.validFor = transformedNode?.validFor;

        // SET COMPOSITE SERVICE ICON
        switch(node.data.type){
          case "DEPENDENCY": {
            node.icon = 'simpleProductOffer.svg';
            break;
          }
          case "EXCLUSIVITY": {
            node.icon = 'simpleProductOffer.svg';
            break;
          }
          case "SWAP": {
            node.icon = 'simpleProductOffer.svg';
            break;
          }
          default: {
            node.icon = 'x.svg';
          }
        }

        // RETURN COMPOSITE SERVICE
        return node;
      }
    }

  }

  /**
   * CONVERT THE OFFER TO BE A VALID TREE NODE
   */
  reverseTree(dataList: any): productCatalogInterface[]{
    return dataList.map((rootNode) => {
      if (Array.isArray(rootNode)) {
        return rootNode.map((node) => this.reverseTransform(node));
      } else {
        return this.reverseTransform(rootNode);
      }
    });
  }

}
