import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericDeleteModel } from '@shared/interfaces/delete-model-interface';

@Component({
  selector: 'app-delete-model',
  templateUrl: './delete-model.component.html',
  styleUrls: ['./delete-model.component.scss'],
})
export class DeleteModelComponent {
  constructor(
    private dialogRef: MatDialogRef<DeleteModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericDeleteModel
  ) {}

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(): void {
    this.dialogRef.close(true); // Close with confirmation
  }
}
