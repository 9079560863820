import { Component, Input } from '@angular/core';

@Component({
  selector: 'dot-loading',
  templateUrl: './dot-loading.component.html',
  styleUrls: ['./dot-loading.component.scss']
})
export class DotLoadingComponent {

  // INPUTS
  @Input() isVisible: boolean = false

}
