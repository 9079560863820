import { Directive, ElementRef, Renderer2, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[isRtl]'
})
export class IsRtlDirective implements OnInit {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Initialize the 'rtl' class based on the initial language
    this.updateRtlClass();

    // Subscribe to language change events
    this.translateService.onLangChange.subscribe(() => {
      // Language has changed, update the 'rtl' class
      this.updateRtlClass();
      // Trigger change detection to apply the class
      this.cdRef.detectChanges();
    });
  }

  updateRtlClass() {
    const pageLanguage = this.translateService.currentLang;

    // Check if the page language is Arabic ('ar')
    if (pageLanguage.toLowerCase() === 'ar') {
      // Add 'rtl' class to the element's existing classes
      this.renderer.addClass(this.el.nativeElement, 'rtl');
    } else {
      // Remove 'rtl' class if it's not Arabic
      this.renderer.removeClass(this.el.nativeElement, 'rtl');
    }
  }

  isRtl(): boolean{
    return (this.translateService.currentLang.toLowerCase() === 'ar')? true: false
  }
}
