<dot-modal
  [title]="data.isUpdateForm?
            (parentTransName + 'updateFormTitle' | translate)
            : (parentTransName + 'createFormTitle' | translate)"
  (modalClosed)="closeDialog()"
  >

  <!-- START OF MODAL BODY -->
  <ng-container class="modal-body">

    <section class="px-6">
      <form class="md:col-span-3" [formGroup]="form">
        <div class="lg:col-span-2">
          <div class="grid gap-x-4 text-sm grid-cols-1 md:grid-cols-4">

            <!-- [name] -->
            <div class="md:col-span-4 mb-2">
              <dot-textbox
                [id]="'name'"
                [name]="'name'"
                [placeholder]="parentTransName + 'serviceCatalogName' | translate"
                [required]="true"
                [pKeyFilter]="REGEX_FORMATS.ALFA_NUM"
                formControlName="name"
                ngDefaultControl
              >
              </dot-textbox>
            </div>

          </div>
        </div>
      </form>
    </section>

  </ng-container>
  <!-- END OF MODAL BODY -->

  <!-- START OF FOOTER -->
  <ng-container class="modal-footer">

    <!-- START OF CREATE BTN -->
    <button
          type="button"
          (click)="onSubmit()"
          class="dot_btn dot_btn_primary"
          [disabled]="form.invalid || isSubmit"
        >
        {{ data.isUpdateForm? ('btn.update' | translate): (('btn.create' | translate))}}
    </button>
    <!-- END OF CREATE BTN -->

  </ng-container>
  <!-- END OF FOOTER -->

</dot-modal>
